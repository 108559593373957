export default {
    "AB1": "Aberdeen",
    "AB2": "Aberdeen",
    "AB3": "Aberdeen",
    "AB4": "Fraserburgh, Peterhead, Macduff",
    "AB5": "Buckie, Huntly, Inverurie",
    "AB9": "Aberdeen",
    "AB10": "Aberdeen city centre, Bridge of Dee, Mannofield",
    "AB11": "Aberdeen city centre, Torry",
    "AB12": "Aberdeen, Altens, Ardoe, Banchory Devenick, Blairs, Bridge of Dee, Cove Bay, Maryculter, Nigg, Portlethen",
    "AB13": "Milltimber",
    "AB14": "Peterculter, Upper Anguston",
    "AB15": "Aberdeen, Bieldside, Craigiebuckler, Cults, Hazlehead, Kingswells, Mannofield, Milltimber",
    "AB16": "Aberdeen, Mastrick, Northfield, Middlefield, Cornhill",
    "AB21": "Aberdeen, Blackburn, Bucksburn, Dyce, Fintray, Kinellar, Newmachar, Whiterashes",
    "AB22": "Aberdeen, Bridge of Don, Danestone, Grandholm, Persley",
    "AB23": "Aberdeen, Balmedie, Belhelvie, Bridge of Don, Potterton, Whitecairns",
    "AB24": "Aberdeen, Old Aberdeen, Woodside, Tillydrone, Seaton, Bedford",
    "AB25": "Aberdeen city centre, Kittybrewster, Foresterhill, Rosemount, George Street",
    "AB30": "Laurencekirk",
    "AB31": "Banchory",
    "AB32": "Westhill",
    "AB33": "Alford",
    "AB34": "Aboyne",
    "AB35": "Ballater",
    "AB36": "Strathdon",
    "AB37": "Ballindalloch",
    "AB38": "Aberlour",
    "AB39": "Stonehaven, Newtonhill",
    "AB41": "Ellon",
    "AB42": "Hatton, St. Fergus",
    "AB43": "Crimond",
    "AB44": "Macduff",
    "AB45": "Banff",
    "AB51": "Inverurie",
    "AB52": "Insch",
    "AB53": "Turriff",
    "AB54": "Aberchirder, Cabrach, Cairnie, Forgue, Gartly, Glass, Kennethmont, Lumsden, Rhynie, Rothiemay",
    "AB55": "Keith",
    "AB56": "Buckie",
    "AB99": "Non-geographic",
    "AL1": "St Albans",
    "AL2": "St Albans, Bricket Wood, Colney Street, Frogmore, London Colney, Napsbury, Park Street, Potters Crouch, Chiswell Green",
    "AL3": "St Albans, Childwickbury, Flamstead, Gorhambury, Markyate, Porters Wood, Redbourn, Sandridge",
    "AL4": "St Albans, Colney Heath, London Colney, Jersey Farm, Marshalswick, Oaklands, Sandridge, Smallford, Tyttenhanger, Wheathampstead",
    "AL5": "Harpenden",
    "AL6": "Welwyn, Ayot St Peter, Ayot St Lawrence, Digswell, Rabley Heath, Tewin",
    "AL7": "Welwyn Garden City",
    "AL8": "Welwyn Garden City, Lemsford",
    "AL9": "Hatfield, Brookmans Park, Essendon, North Mymms",
    "AL10": "Hatfield",
    "B1": "Birmingham City Centre, Ladywood",
    "B2": "Birmingham City Centre, Ladywood",
    "B3": "Birmingham City Centre, Ladywood",
    "B4": "Birmingham City Centre, Ladywood",
    "B5": "Digbeth, Highgate, Lee Bank",
    "B6": "Aston",
    "B7": "Nechells",
    "B8": "Washwood Heath, Ward End, Saltley",
    "B9": "Bordesley Green",
    "B10": "Small Heath",
    "B11": "Sparkhill, Tyseley",
    "B12": "Balsall Heath, Sparkbrook, Highgate",
    "B13": "Moseley, Billesley",
    "B14": "Kings Heath, Yardley Wood, Brandwood, Druids Heath, Warstock",
    "B15": "Edgbaston, Lee Bank",
    "B16": "Edgbaston, Ladywood",
    "B17": "Harborne",
    "B18": "Winson Green, Hockley",
    "B19": "Lozells, Newtown, Birchfield",
    "B20": "Birchfield, Handsworth Wood Perry Barr",
    "B21": "Handsworth",
    "B22": "Kings Norton",
    "B23": "Erdington, Short Heath",
    "B24": "Erdington, Tyburn",
    "B25": "Yardley",
    "B26": "Sheldon, Yardley",
    "B27": "Acocks Green",
    "B28": "Hall Green",
    "B29": "Selly Oak, Bournbrook, Selly Park, Weoley Castle, California",
    "B30": "Bournville, Cotteridge, Stirchley",
    "B31": "Northfield",
    "B32": "Woodgate, Bartley Green, Quinton, California",
    "B33": "Kitts Green, Stechford",
    "B34": "Shard End, Buckland End",
    "B35": "Castle Vale",
    "B36": "Castle Bromwich, Smith's Wood",
    "B37": "Chelmsley Wood, Marston Green, Kingshurst, Fordbridge",
    "B38": "Kings Norton",
    "B40": "National Exhibition Centre, Birmingham International Airport, Bickenhill",
    "B42": "Perry Barr, Great Barr, Hamstead",
    "B43": "Great Barr, Hamstead, Pheasey",
    "B44": "Perry Barr, Kingstanding, Great Barr",
    "B45": "Rednal, Rubery",
    "B46": "Water Orton, Coleshill",
    "B47": "Hollywood, Wythall",
    "B48": "Alvechurch",
    "B49": "Alcester",
    "B50": "Alcester",
    "B60": "Bromsgrove",
    "B61": "Bromsgrove",
    "B62": "Halesowen",
    "B63": "Halesowen",
    "B64": "Cradley Heath",
    "B65": "Rowley Regis",
    "B66": "Smethwick, Bearwood",
    "B67": "Smethwick, Bearwood",
    "B68": "Oldbury",
    "B69": "Oldbury",
    "B70": "West Bromwich",
    "B71": "West Bromwich",
    "B72": "Sutton Coldfield town centre, Maney, Wylde Green",
    "B73": "Boldmere, New Oscott, Wylde Green",
    "B74": "Four Oaks, Mere Green, Little Aston, Streetly",
    "B75": "Sutton Trinity, Falcon Lodge, Rectory",
    "B76": "Walmley",
    "B77": "Tamworth",
    "B78": "Tamworth",
    "B79": "Tamworth",
    "B80": "Studley",
    "B90": "Shirley, Wythall, Majors Green, Dickens Heath",
    "B91": "Olton, Solihull",
    "B92": "Olton, Solihull",
    "B93": "Knowle, Dorridge",
    "B94": "Olton, Solihull",
    "B95": "Henley-in-Arden",
    "B96": "Redditch",
    "B97": "Redditch",
    "B98": "Redditch",
    "B99": "Non-geographic",
    "BA1": "Bath",
    "BA2": "Bath",
    "BA3": "Radstock, Midsomer Norton, Holcombe, Coleford",
    "BA4": "Shepton Mallet",
    "BA5": "Wells",
    "BA6": "Glastonbury",
    "BA7": "Castle Cary",
    "BA8": "Templecombe",
    "BA9": "Wincanton",
    "BA10": "Bruton",
    "BA11": "Frome",
    "BA12": "Warminster",
    "BA13": "Westbury",
    "BA14": "Trowbridge",
    "BA15": "Bradford-on-Avon",
    "BA16": "Street",
    "BA20": "Yeovil",
    "BA21": "Yeovil",
    "BA22": "Yeovil",
    "BB0": "Blackburn",
    "BB1": "Blackburn, Bank Hey, Belthorn, Blackamoor, Clayton-le-Dale, Guide, Knuzden, Mellor, Ramsgreave, Rishton, Salesbury, Shadsworth, Sunnybower, Whitebirk, Wilpshire",
    "BB2": "Blackburn, Beardwood, Balderstone, Cherry Tree, Feniscowles, Mellor, Mellor Brook, Mill Hill, Pleasington, Witton, Holly Tree and Griffin",
    "BB3": "Darwen, Bank Fold, Eccleshill, Hoddlesden, Lower Darwen, Pickup Bank, Tockholes",
    "BB4": "Acre, Bent Gate, Haslingden, Helmshore, Rawtenstall",
    "BB5": "Accrington, Altham, Baxenden, Church, Clayton-le-Moors, Huncoat, Oswaldtwistle, Rising Bridge",
    "BB6": "Dinckley, Great Harwood, Langho",
    "BB7": "Clitheroe, Barrow, Chatburn, Dunsop Bridge, Gisburn, Hurst Green, Pendleton, Sabden, Slaidburn, Waddington, Whalley, Whitewell, Wiswell",
    "BB8": "Colne, Foulridge, Laneshaw Bridge, Trawden, Winewall, Wycoller",
    "BB9": "Nelson, Barrowford, Blacko, Brierfield, Higherford, Roughlee",
    "BB10": "Burnley, Cliviger, Reedley, Worsthorne",
    "BB11": "Burnley",
    "BB12": "Burnley, Barley, Fence, Higham, Padiham, Read, Simonstone, Wheatley Lane",
    "BB18": "Barnoldswick, Earby, Kelbrook, Salterforth, Sough",
    "BB94": "Non-geographic, Earby",
    "BD1": "Bradford city centre, Little Germany",
    "BD2": "Eccleshill, Fagley",
    "BD3": "Barkerend, Bradford Moor, Thornbury",
    "BD4": "Bierley, Bowling, East Bierley, Laisterdyke, Tong, Tong Street, Tyersal",
    "BD5": "Bankfoot, Little Horton, West Bowling",
    "BD6": "Buttershaw, Wibsey",
    "BD7": "Great Horton, Lidget Green",
    "BD8": "Girlington, Manningham, Lower Grange",
    "BD9": "Frizinghall, Heaton",
    "BD10": "Apperley Bridge, Eccleshill, Greengates, Idle, Thackley",
    "BD11": "Birkenshaw, Drighlington",
    "BD12": "Low Moor, Oakenshaw, Wyke",
    "BD13": "Cullingworth, Denholme, Queensbury, Thornton",
    "BD14": "Clayton",
    "BD15": "Allerton, Norr, Wilsden",
    "BD16": "Bingley, Cottingley, Eldwick, Harden",
    "BD17": "Baildon, Shipley",
    "BD18": "Saltaire, Shipley",
    "BD19": "Cleckheaton, Gomersal, Scholes",
    "BD20": "Cononley, Cross Hills, Glusburn, Kildwick, Silsden, Steeton, Sutton-in-Craven",
    "BD21": "Hainworth, Keighley",
    "BD22": "Cowling, Haworth, Oakworth, Oxenhope",
    "BD23": "Carleton-in-Craven, Embsay, Gargrave, Grassington, Hebden, Hellifield, Kettlewell, Kirkby Malham, Skipton, Threshfield",
    "BD24": "Giggleswick, Horton in Ribblesdale, Settle",
    "BD97": "Bingley",
    "BD98": "Shipley",
    "BD99": "Bradford",
    "BF1": "British Forces",
    "BH1": "Bournemouth town centre, Springbourne, East Cliff, Boscombe",
    "BH2": "Bournemouth Central, West Cliff",
    "BH3": "Talbot Woods, Winton",
    "BH4": "Westbourne, Branksome Woods",
    "BH5": "Boscombe, Pokesdown",
    "BH6": "Southbourne, Tuckton, Wick",
    "BH7": "Littledown, Iford",
    "BH8": "Malmesbury Park, Charminster, Queens Park, Strouden Park, Townsend",
    "BH9": "Winton, Moordown, Throop, Muscliff",
    "BH10": "Kinson, Northbourne, Redhill, Ensbury Park, Wallisdown",
    "BH11": "Kinson, Bear Cross, West Howe, Wallisdown",
    "BH12": "Branksome, Alderney, Upper Parkstone, Newtown",
    "BH13": "Canford Cliffs, Sandbanks, Branksome Park",
    "BH14": "Lower Parkstone, Lilliput, Penn Hill",
    "BH15": "Poole town centre, Hamworthy, Oakdale",
    "BH16": "Upton, Turlin Moor, Lytchett Minster, Lytchett Matravers",
    "BH17": "Canford Heath, Creekmoor",
    "BH18": "Broadstone",
    "BH19": "Swanage",
    "BH20": "Wareham",
    "BH21": "Wimborne",
    "BH22": "West Moors",
    "BH23": "Christchurch",
    "BH24": "Ringwood",
    "BH25": "New Milton",
    "BH31": "Verwood",
    "BL0": "Bury, Ramsbottom, Edenfield, Shuttleworth",
    "BL1": "Bolton, Smithills, Halliwell, Heaton",
    "BL2": "Bolton, Ainsworth, Bradley Fold, Bradshaw, Breightmet, Harwood, Tonge Fold, Tonge Moor",
    "BL3": "Bolton, Little Lever",
    "BL4": "Bolton, Farnworth, Kearsley",
    "BL5": "Bolton, Over Hulton, Westhoughton",
    "BL6": "Bolton, Blackrod, Horwich, Lostock, Rivington",
    "BL7": "Bolton, Belmont, Bromley Cross, Chapeltown, Edgworth, Egerton, Turton",
    "BL8": "Bury, Brandlesholme, Greenmount, Affetside, Hawkshaw, Holcombe, Ramsbottom, Tottington, Walshaw",
    "BL9": "Bury, Heap, Heap Bridge, Nangreaves, Summerseat, Unsworth, Walmersley",
    "BL11": "Bolton",
    "BL78": "Bolton",
    "BN1": "Brighton, Coldean, Falmer, Hollingbury, Patcham, Preston, Stanmer, Withdean",
    "BN2": "Brighton, Bevendean, Brighton Marina, Kemptown, Moulsecoomb, Ovingdean, Rottingdean, Saltdean, Woodingdean",
    "BN3": "Hove, Hangleton, West Blatchington",
    "BN4": "Shoreham by Sea",
    "BN5": "Henfield, Blackstone, Edburton, Fulking, Small Dole, Wineham, Woodmancote",
    "BN6": "Hassocks, Albourne, Clayton, Ditchling, Goddards Green, Hurstpierpoint, Keymer, Newtimber, Sayers Common, Streat, Westmeston",
    "BN7": "Lewes, Cooksbridge, East Chiltington, Iford, Kingston, Offham, Plumpton, Plumpton Green, Rodmell, Southease, Swanborough, Telscombe",
    "BN8": "Barcombe, Barcombe Cross, Beddingham, Chailey Green, Chiddingly, East Hoathly, Firle, Glynde, Halland, Hamsey, Holmes Hill, Laughton, Newick, North Chailey, Ringmer, Ripe, Shortgate, South Chailey, Southerham, Stoneham, Whitesmith",
    "BN9": "Newhaven, Piddinghoe, South Heighton, Tarring Neville",
    "BN10": "Peacehaven, Telscombe Cliffs",
    "BN11": "Worthing",
    "BN12": "Worthing, Ferring, Goring-by-Sea",
    "BN13": "Worthing, Clapham, Durrington, High Salvington, Patching, Tarring",
    "BN14": "Worthing, Broadwater, Findon, Northend, Tarring",
    "BN15": "Lancing, Coombes, Sompting",
    "BN16": "Angmering, East Preston, Kingston Gorse, Rustington",
    "BN17": "Littlehampton, Climping, Lyminster, Wick",
    "BN18": "Arundel, Amberley, Binsted, Burpham, Crossbush, Fontwell, Ford, Houghton, Madehurst, Poling, Slindon, Slindon Common, South Stoke, Tortington, Walberton, Warningcamp, Wepham, Yapton",
    "BN20": "Eastbourne, Beachy Head, East Dean, Friston, Old Town, Ratton, Wannock, Willingdon",
    "BN21": "Eastbourne, Old Town",
    "BN22": "Eastbourne, Hampden Park, Willingdon",
    "BN23": "Eastbourne, Friday Street, Langney, Sovereign Harbour",
    "BN24": "Pevensey, Beachlands, Hankham, Normans Bay, Pevensey Bay, Stone Cross, Westham",
    "BN25": "Seaford, Bishopstone, Cuckmere Haven, Exceat, Norton, Rookery Hill, Westdean",
    "BN26": "Polegate, Alciston, Alfriston, Arlington, Berwick, Caneheath, Filching, Folkington, Jevington, Litlington, Lullington, Milton Street, Sayerland, Selmeston, Summerhill, Wilmington",
    "BN27": "Hailsham, Amberstone, Bodle Street, Bodle Street Green, Boreham Street, Carters Corner, Chalvington, Cowbeech, Downash, Golden Cross, Hellingly, Herstmonceux, Horsebridge, Lower Dicker, Lower Horsebridge, Magham Down, Mulbrooks, Rickney, Trolliloes, Upper Dicker, Wartling, Windmill Hill",
    "BN41": "Fishersgate, Portslade",
    "BN42": "Southwick",
    "BN43": "Shoreham-by-Sea",
    "BN44": "Steyning, Ashurst, Botolphs, Bramber, Upper Beeding, Wiston",
    "BN45": "Poynings, Pyecombe, Saddlescombe",
    "BN50": "Brighton",
    "BN51": "Non-geographic, Rottingdean",
    "BN52": "Hove",
    "BN88": "Brighton",
    "BN91": "Non-geographic",
    "BN95": "Non-geographic",
    "BN99": "Worthing, Lancing",
    "BR1": "Bromley, Bickley, Downham",
    "BR2": "Bickley, Hayes, Bromley Common, Shortlands",
    "BR3": "Beckenham, Eden Park, Elmers End, Park Langley, Shortlands",
    "BR4": "West Wickham",
    "BR5": "Orpington, St Mary Cray, Petts Wood",
    "BR6": "Orpington, Farnborough, Downe, Pratt's Bottom, Chelsfield, Well Hill",
    "BR7": "Chislehurst, Elmstead",
    "BR8": "Swanley",
    "BR98": "Non-geographic",
    "BS0": "Non-geographic",
    "BS1": "Bristol city centre, Redcliffe",
    "BS2": "Kingsdown, St Pauls, St Phillips, St Agnes",
    "BS3": "Bedminster, Southville, Bower Ashton, Totterdown, Windmill Hill",
    "BS4": "Brislington, Knowle, Knowle West, St Annes, Totterdown",
    "BS5": "Easton, St George, Whitehall",
    "BS6": "Redland, Montpelier, Westbury Park",
    "BS7": "Bishopston, Horfield, Filton",
    "BS8": "Clifton, Hotwells",
    "BS9": "Coombe Dingle, Sneyd Park, Stoke Bishop, Westbury on Trym, Henleaze, Bristol",
    "BS10": "Brentry, Henbury, Southmead",
    "BS11": "Avonmouth, Shirehampton",
    "BS12": "Thornbury, Almondsbury",
    "BS13": "Bedminster Down, Bishopsworth, Hartcliffe, Withywood",
    "BS14": "Hengrove, Stockwood, Whitchurch, Withywood",
    "BS15": "Hanham, Kingswood",
    "BS16": "Downend, Fishponds, Frenchay",
    "BS17": "Chipping Sodbury",
    "BS18": "Bristol",
    "BS19": "Nailsea, Backwell",
    "BS20": "Portishead",
    "BS21": "Clevedon",
    "BS22": "Kewstoke, Weston-super-Mare, Worle",
    "BS23": "Uphill, Weston-super-Mare",
    "BS24": "Bleadon, Hutton, Locking, Lympsham, Puxton, Weston-super-Mare, Wick St. Lawrence",
    "BS25": "Churchill, Winscombe, Sandford, Shipham",
    "BS26": "Axbridge, Compton Bishop, Loxton",
    "BS27": "Cheddar, Draycott",
    "BS28": "Wedmore",
    "BS29": "Banwell",
    "BS30": "Bitton, Cadbury Heath, Warmley, Wick",
    "BS31": "Chewton Keynsham, Keynsham, Saltford",
    "BS32": "Almondsbury, Bradley Stoke",
    "BS34": "Filton, Little Stoke, Patchway, Stoke Gifford",
    "BS35": "Alveston, Rudgeway, Thornbury",
    "BS36": "Frampton Cotterell, Winterbourne",
    "BS37": "Chipping Sodbury, Yate",
    "BS38": "Non-geographic",
    "BS39": "Clutton, Temple Cloud",
    "BS40": "Chew Valley, Chew Magna, Chew Stoke, Wrington",
    "BS41": "Long Ashton",
    "BS48": "Backwell, Nailsea",
    "BS49": "Congresbury, Yatton",
    "BS77": "Bedminster",
    "BS80": "Bristol",
    "BS98": "Non-geographic",
    "BS99": "Non-geographic",
    "BT1": "Belfast",
    "BT2": "Belfast",
    "BT3": "Belfast",
    "BT4": "Belfast, Sydenham,Belmont, Stormont",
    "BT5": "Belfast, Castlereagh, Crossnacreevy, Gilnahirk, Knock",
    "BT6": "Belfast, Cregagh, Knockbreda",
    "BT7": "Belfast, Ormeau",
    "BT8": "Belfast, Carryduff, Knockbreda, Newtownbreda",
    "BT9": "Belfast, Malone, Lisburn Road, Taughmonagh, Stranmillis",
    "BT10": "Belfast, Finaghy",
    "BT11": "Belfast, Andersonstown",
    "BT12": "Belfast, Falls Road, Sandy Row, The Village",
    "BT13": "Belfast, Shankill Road, Woodvale, Ballygomartin, Springmartin, Glencairn, Highfield",
    "BT14": "Belfast, Ballysillan, Upper Ballysillan",
    "BT15": "Belfast",
    "BT16": "Dundonald",
    "BT17": "Belfast, Derriaghy, Dunmurry, Hannahstown",
    "BT18": "Holywood, Craigavad",
    "BT19": "Bangor, Crawfordsburn, Groomsport, Helens Bay",
    "BT20": "Bangor",
    "BT21": "Donaghadee",
    "BT22": "Newtownards, Ardkeen, Ballyhalbert, Ballywalter, Carrowdore, Cloughey, Greyabbey, Kircubbin, Millisle, Portaferry, Portavogie",
    "BT23": "Newtownards, Ballygowan, Comber, Conlig, Killinchy, Moneyrea",
    "BT24": "Ballynahinch, Drumaness, Saintfield",
    "BT25": "Dromore, Dromara, Finnis, Waringsford",
    "BT26": "Hillsborough, Annahilt, Culcavy",
    "BT27": "Lisburn, Cargacreevy, Drumalig, Drumbo, Hilden, Hillhall, Lambeg",
    "BT28": "Lisburn",
    "BT29": "Belfast, Crumlin, Aldergrove, Dundrod, Glenavy, Nutts Corner",
    "BT30": "Downpatrick, Ardglass, Ballyhornan, Ballykinler, Castleward, Clough, Crossgar, Kilclief, Killard, Killough, Killyleagh, Loughinisland, Seaforde, Strangford, Toye",
    "BT31": "Castlewellan, Ballyward",
    "BT32": "Banbridge, Annaclone, Ballinaskeagh, Ballyroney, Corbet, Katesbridge, Lenaderg, Loughbrickland, Seapatrick",
    "BT33": "Newcastle, Bryansford, Dundrum",
    "BT34": "Newry, Annalong, Ballymartin, Cabra, Hilltown, Kilcoo, Kilkeel, Mayobridge, Poyntzpass, Rathfriland, Rostrevor, Warrenpoint",
    "BT35": "Jerrettspass, Bessbrook, Camlough, Crossmaglen, Belleek, Newtownhamilton",
    "BT36": "Newtownabbey, Mossley, Glengormley",
    "BT37": "Newtownabbey",
    "BT38": "Carrickfergus, Ballycarry, Greenisland, Kilroot, Whitehead",
    "BT39": "Ballyclare, Ballynure, Doagh, Parkgate, Straid, Templepatrick",
    "BT40": "Larne, Ballygally, Gleno, Glynn, Islandmagee, Kilwaughter, Magheramorne, Millbrook",
    "BT41": "Antrim, Dunadry, Muckamore, Randalstown, Toomebridge",
    "BT42": "Broughshane, Cullybackey, Galgorm, Kells",
    "BT43": "Cargan, Knockanully",
    "BT44": "Portglenone, Carnlough, Glenarm, Clogh Mills, Rasharkin, Glenariff/Waterfoot, Cushendun, Cushendall, Dunloy, Loughguile, Glarryford",
    "BT45": "Magherafelt, Ballyronan, Bellaghy, Castledawson, Desertmartin, Draperstown, Knockloughrim, Moneymore, Tobermore, Fallalea, Fallaghloon",
    "BT46": "Maghera, Lisnamuck, Swatragh, Upperlands",
    "BT47": "Derry, Waterside, Claudy, Feeny, Dungiven, Eglinton, Park, New Buildings",
    "BT48": "Derry, Cityside, Ballynagard, Coshquin, Rosemount, The Collon, Culmore",
    "BT49": "Limavady, Ballykelly",
    "BT51": "Coleraine, Aghadowey, Articlave, Bellany, Blackhill, Castlerock, Castleroe, Garvagh, Kilrea, Macosquin, Ringsend",
    "BT52": "Coleraine, Ballyvelton, Cloyfin",
    "BT53": "Ballymoney, Dervock, Armoy, Ballybogy",
    "BT54": "Ballycastle, Ballintoy, Ballypatrick, Ballyvoy, Cape Castle, Glenshesk, Maghercashel, Maghernahar, Moyarget, Torr, Rathlin Island",
    "BT55": "Portstewart",
    "BT56": "Portrush, Craigahullier, Urbalreagh",
    "BT57": "Bushmills, Castlecatt, Dunseverick, Portballintrae",
    "BT58": "Non-geographic",
    "BT60": "Killylea, Tynan, Belcoo, Middletown, Keady, Darkley, Markethill, Kingsmills",
    "BT61": "Armagh, Collone, Hamiltonsbawn, Kilmore, Loughgall, Richhill",
    "BT62": "Craigavon, Portadown, Tandragee, Clare, Scotch Street",
    "BT63": "Gilford, Laurencetown, Portadown, Scarva",
    "BT64": "Craigavon, Knockmenagh, Mandeville",
    "BT65": "Craigavon, Drumgor, Legaghory, Tullygally, Brownlow.",
    "BT66": "Derryadd, Derrytrasna, Dollingstown, Donaghcloney, Lurgan, Waringstown",
    "BT67": "Aghagallon, Aghalee, Gawley's Gate, Lurgan, Magheralin, Moira",
    "BT68": "Caledon, Minterburn",
    "BT69": "Aughnacloy, Carnteel",
    "BT70": "Dungannon, Ballygawley, Cappagh, Castlecaulfield, Donaghmore, Galbally, Garvaghy, Pomeroy, Rock, Seskilgreen",
    "BT71": "Dungannon, Benburb, Blackwatertown, Bush, Coalisland, Killycolpy, Ardboe, Moygashel, Stewartstown",
    "BT74": "Enniskillen town, Boho",
    "BT75": "Fivemiletown, Clabby",
    "BT76": "Clogher",
    "BT77": "Augher",
    "BT78": "Omagh, Dromore, Drumquin, Eskra, Fintona, Killynure, Newtownstewart, Seskanore, Trillick",
    "BT79": "Omagh, Drumlea, Glengawna, Gortin, Mountfield, Plumbridge, Rosnamuck, Sheskinshule, Sixmilecross",
    "BT80": "Cookstown, Coagh, Tullyhogue",
    "BT81": "Castlederg, Aghyaran, Clare, Garvetagh, Killen, Killeter, Mournebeg, Scraghey, Spamount",
    "BT82": "Strabane, Artigarvan, Ballymagorry, Bready, Clady, Douglas Bridge, Dunamanagh, Sion Mills, Victoria Bridge",
    "BT92": "Lisnaskea, Florencecourt, Derrylin, Newtownbutler",
    "BT93": "Belleek, Belcoo, Kesh, Derrygonnelly, Garrison",
    "BT94": "Irvinestown, Ballinamallard, Brookeborough, Tempo, Maguiresbridge, Lisbellaw",
    "BT99": "Non-geographic",
    "CA1": "Carlisle East",
    "CA2": "Carlisle South West",
    "CA3": "Carlisle North",
    "CA4": "Warwick Bridge, Wetheral, Cumwhinton, Armathwaite",
    "CA5": "Dalston, Burgh by Sands",
    "CA6": "Longtown, Bewcastle",
    "CA7": "Wigton, Silloth, Aspatria, Caldbeck, Hesket Newmarket",
    "CA8": "Brampton, Gilsland, Greenhead, Slaggyford",
    "CA9": "Alston, Garrigill, Nenthead",
    "CA10": "Penrith Carleton Hall area, Shap, Tebay, Langwathby, Lazonby, Pooley Bridge",
    "CA11": "Penrith, Stainton, Mungrisdale, Glenridding, Patterdale",
    "CA12": "Keswick, Seatoller, Braithwaite, Bassenthwaite, Threlkeld",
    "CA13": "Cockermouth, Lorton, Buttermere",
    "CA14": "Workington, Distington, Seaton",
    "CA15": "Maryport, Dearham, Flimby, Allonby",
    "CA16": "Appleby-in-Westmorland",
    "CA17": "Kirkby Stephen, Brough, Ravenstonedale",
    "CA18": "Ravenglass",
    "CA19": "Holmrook, Santon Bridge, Eskdale",
    "CA20": "Seascale, Sellafield, Gosforth, Wasdale Head",
    "CA21": "Beckermet",
    "CA22": "Egremont, Thornhill",
    "CA23": "Ennerdale Bridge",
    "CA24": "Moor Row",
    "CA25": "Cleator Moor",
    "CA26": "Frizington, Croasdale",
    "CA27": "Saint Bees",
    "CA28": "Whitehaven, Sandwith",
    "CA95": "Non-geographic",
    "CA99": "Non-geographic",
    "CB1": "Cambridge (Central and South), Teversham",
    "CB2": "Cambridge (West)",
    "CB3": "Cambridge (North-West), Girton",
    "CB4": "Cambridge (North)",
    "CB5": "Cambridge (East)",
    "CB6": "Aldreth, Apes Hall, Chettisham, Coveney, Ely, Haddenham, Little Downham, Little Thetford, Littleport, Mepal, Pymore, Stretham, Sutton, Wardy Hill, Wentworth, Wilburton, Witcham, Witchford",
    "CB7": "Barway, Brandon Bank, Brandon Creek, Broad Hill, Chippenham, Down Field, Ely, Fordham, Isleham, Prickwillow, Queen Adelaide, River Bank, Soham, Stuntney, Upware, Wicken",
    "CB8": "Ashley, Brinkley, Burrough End, Burrough Green, Carlton, Cheveley, Clopton Green, Cowlinge, Dalham, Denston, Ditton Green, Dullingham, Dunstall Green, Exning, Gazeley, Great Bradley, Kennett, Kentford, Kirtling, Kirtling Green, Lady's Green, Landwade, Lidgate, Moulton, Newmarket, Ousden, Saxon Street, Six Mile Bottom, Snailwell, Stetchworth, Stradishall, Thorns, Upend, Westley Waterless, Wickhambr",
    "CB9": "Barnardiston, Great Thurlow, Great Wratting, Haverhill, Helions Bumpstead, Kedington, Little Bradley, Little Thurlow, Little Wratting, Steeple Bumpstead, Sturmer, Withersfield",
    "CB10": "Ashdon, Church End, Great Chesterford, Great Sampford, Hempstead, Hinxton, Howlett End, Ickleton, Little Chesterford, Little Sampford, Little Walden, Radwinter, Red Oaks Hill, Saffron Walden, Sewards End, Wimbish, Wimbish Green",
    "CB11": "Arkesden, Audley End, Clavering, Debden, Debden Green, Duddenhoe End, Elmdon, Langley, Littlebury, Littlebury Green, Newport, Pond Street, Quendon, Rickling, Rickling Green, Shortgrove, Starling's Green, Strethall, Upper Green, Wendens Ambo, Wicken Bonhunt, Widdington",
    "CB21": "Fulbourn, Great and Little Wilbraham, West Wratting, Weston Colville, Teversham, Abington (incl. Little), Hildersham, Barlow, Hadstock, Horseheath, Shudy Camps, West Wickham, Castle Camps, Balsham, Linton",
    "CB22": "Babraham, Sawston, Pampisford, Duxford, Whittlesford, Great and Little Shelford, Stapleford, Harston, Barrington, Hauxton, Newton, Foxton",
    "CB23": "Cambourne (incl. Great and Lower), Barton, Comberton, Harlton, Great and Little Eversden, Bourn, Highfields Caldecote, Coton, Haslingfield, Kingston, Hardwick, Toft, Longstowe, Madingley, Dry Drayton, Papworth Everard, Lolworth, Bar Hill, Elsworth, Knapwell, Conington, Boxworth, Caxton, Papworth Saint Agnes",
    "CB24": "Histon, Impington, Oakington, Longstanton, Willingham, Swavesey, Over, Fen Drayton, Milton, Rampton, Cottenham",
    "CB25": "Cottenham, Landbeach, Rampton, Histon, Impington, Burwell, Swaffham Bulbeck, Swaffham Prior, Stow-Cum-Quy, Bottisham, Lode, Waterbeach, Horningsea, Chittering",
    "CF1": "Cardiff",
    "CF2": "Cardiff",
    "CF3": "Rumney, Trowbridge, Llanrumney, St Mellons, Castleton, Marshfield",
    "CF4": "Cardiff",
    "CF5": "Ely, Caerau, St Fagans, Culverhouse Cross, Canton, Leckwith, Fairwater, Danescourt, Llandaff, Riverside, Wenvoe, Peterston Super Ely, St Georges Super Ely, Michaelston",
    "CF6": "Barry, Penarth",
    "CF7": "Llantrisant, Cowbridge",
    "CF8": "Caerphilly, Bargoed, Rhymney",
    "CF10": "Cardiff city centre, Grangetown, Cardiff Bay, Butetown",
    "CF11": "Cardiff city centre, Canton, Cardiff Bay, Grangetown",
    "CF14": "Whitchurch, Thornhill, Lisvane, Rhiwbina, Pantmawr, Gabalfa, Heath, Llandaff North",
    "CF15": "Pentyrch, Gwaelod-y-Garth, Creigiau, Radyr, Morganstown, Tongwynlais, Taffs Well, Nantgarw",
    "CF21": "Non-geographic",
    "CF23": "Llanishen, Cyncoed, Pentwyn, Penylan, Pontprennau, Old St Mellons",
    "CF24": "Cardiff city centre, Cathays, Roath, Plasnewydd, Splott, Adamsdown",
    "CF30": "Non-geographic",
    "CF31": "Bridgend, Brackla, Coity, Pen-y-Fai",
    "CF32": "Cefn Cribwr, Laleston, Merthyr Mawr, Ogmore Vale, Tondu, Sarn, Ynysawdre, St Brides Minor, Pontycymer, Llangeinor, Garw Valley, Blaengarw, Blackmill, Bettws, Aberkenfig, St Brides Major",
    "CF33": "Cornelly, Pyle",
    "CF34": "Maesteg, Llangynwyd, Caerau, Nantyffyllon",
    "CF35": "Pencoed, Coychurch, Llangan, Ewenny",
    "CF36": "Porthcawl, Nottage, Newton",
    "CF37": "Pontypridd, Cilfynydd, Glyncoch, Graig, Treforest, Hopkinstown, Trallwng, Maesycoed, Pwllgwaun, Hawthorn, Rhydfelen, Trehafod, Ynysybwl, Coed-y-Cwm",
    "CF38": "Llantwit Fardre, Church village, Tonteg, Efail Isaf, Beddau, Ty Nant",
    "CF39": "Porth, Llwyncelyn, Cymmer, Glynfach, Trebanog, Ynyshir, Wattstown, Dinas, Tonyrefail, Coed Ely, Thomastown, Gilfach Goch, Evanstown",
    "CF40": "Tonypandy, Trealaw, Penygraig, Dinas, Williamstown, Cwm Clydach, Llwynypia, Penrhiwfer",
    "CF41": "Pentre, Ton Pentre, Ystrad, Gelli",
    "CF42": "Treorchy, Cwmparc, Ynyswen, Treherbert, Blaencwm, Blaenrhondda",
    "CF43": "Ferndale, Blaenllechau, Tylorstown, Penrhys, Pontygwaith, Stanleytown, Maerdy",
    "CF44": "Aberdare, Cwmaman, Aberaman, Llwydcoed, Cwmbach, Hirwaun, Penywaun, Rhigos, Penderyn",
    "CF45": "Mountain Ash, Abercynon, Penrhiwceiber",
    "CF46": "Treharris, Quakers Yard, Bedlinog, Nelson",
    "CF47": "Merthyr Tydfil, Gurnos, Penydarren",
    "CF48": "Cyfarthfa, Pant, Merthyr Vale, Troed-y-rhiw, Vaynor, Pentrebach",
    "CF61": "Llantwit Major, Llan-maes",
    "CF62": "Barry, Rhoose, St Athan, Llancarfan, Barry Island",
    "CF63": "Barry, Cadoxton, Barry Docks",
    "CF64": "Penarth, Dinas Powys, Sully, Llandough",
    "CF71": "Cowbridge, St Brides Major, Welsh St Donats, Pendoylan, Llandow, Colwinston, Llanblethian, Penllyn, Llanfair",
    "CF72": "Pontyclun, Llantrisant, Llanharan, Talbot Green, Brynsadler, Miskin, Brynna, Llanharry",
    "CF81": "Bargoed, Aberbargoed, Darran Valley, Gilfach, Pontlottyn",
    "CF82": "Hengoed, Cefn Hengoed, Ystad Mynach, Gelligaer, Maesycwmmer",
    "CF83": "Caerphilly, Abertridwr, Senghenydd, Bedwas, Trethomas, Machen, Llanbradach, Pwllypant, Penyrheol, Energlyn, Trecenydd, Rudry",
    "CF91": "Non-geographic",
    "CF95": "Non-geographic",
    "CF99": "Non-geographic",
    "CH1": "Blacon, Chester, Higher Ferry, Capenhurst, Backford",
    "CH2": "Backford, Chester, Elton, Hoole, Ince, Mickle Trafford, Moston",
    "CH3": "Boughton, Chester, Huntington, Mouldsworth, Tarvin, Tattenhall, Farndon",
    "CH4": "Chester, Handbridge, Lache, Pulford, Penyffordd, Broughton, Saltney",
    "CH5": "Connah's Quay, Shotton, Queensferry, Sealand, Sandycroft, Dobshill, Ewloe",
    "CH6": "Flint, Oakenholt, Flint Mountain, The Nant, Bagillt",
    "CH7": "Buckley, Leeswood, Treuddyn, Llanarmon-yn-Ial, Caerwys, Mold, Northop Hall, Drury",
    "CH8": "Holywell, Milwr, Holway, Carmel, Lloc, Bryn Celyn, Greenfield, Halkyn, Lixwm, Talacre",
    "CH25": "Non-geographic, Birkenhead",
    "CH26": "Non-geographic, Prenton",
    "CH27": "Wallasey",
    "CH28": "Non-geographic, Moreton",
    "CH29": "Non-geographic, Hoylake",
    "CH30": "Non-geographic, Upton",
    "CH31": "Non-geographic, Heswall",
    "CH32": "Non-geographic, New Ferry",
    "CH33": "Non-geographic, Neston",
    "CH34": "Ellesmere Port",
    "CH41": "Birkenhead, Claughton, Seacombe, Tranmere, Woodside",
    "CH42": "Birkenhead, Oxton, Prenton, Rock Ferry",
    "CH43": "Bidston, Noctorum, Oxton, Prenton",
    "CH44": "Egremont, Liscard, Poulton, Seacombe, Wallasey",
    "CH45": "New Brighton, Wallasey, Wallasey Village",
    "CH46": "Leasowe, Moreton",
    "CH47": "Hoylake, Meols, Saughall Massie, West Kirby",
    "CH48": "Caldy, Frankby, Grange, Greasby, Hoylake, Meols, Newton, Saughall Massie, West Kirby",
    "CH49": "Greasby, Landican, Upton, Woodchurch",
    "CH60": "Gayton, Heswall",
    "CH61": "Barnston, Heswall, Irby, Pensby, Thingwall, Thurstaston",
    "CH62": "Bromborough, Eastham, New Ferry, Port Sunlight, Spital",
    "CH63": "Bebington, Brimstage, Bromborough, Clatterbridge, Higher Bebington, Raby, Raby Mere, Spital, Storeton, Thornton Hough",
    "CH64": "Little Neston, Ness, Neston, Parkgate, Puddington, Willaston",
    "CH65": "Ellesmere Port, Great Sutton, Whitby, Wolverham",
    "CH66": "Childer Thornton, Ellesmere Port, Great Sutton, Hooton, Ledsham, Little Sutton, Overpool, Whitby",
    "CH70": "Department for Work and Pensions",
    "CH88": "North West Securities Bank",
    "CH99": "Benefits Agency, St. Michaels Financial Services & Chargecard",
    "CM0": "Southminster, Bradwell, Tillingham, Asheldham, Dengie, Burnham-on-Crouch, Tillingham",
    "CM1": "Chelmsford, Writtle",
    "CM2": "Chelmsford",
    "CM3": "Hatfield Peverel, South Woodham Ferrers, Boreham, Maylandsea",
    "CM4": "Blackmore, Fryerning, Ingatestone, Stock",
    "CM5": "Chipping Ongar, High Ongar, Bobbingworth, Moreton, The Lavers, The Rodings",
    "CM6": "Great Dunmow, Felsted",
    "CM7": "Braintree",
    "CM8": "Witham",
    "CM9": "Maldon, Tollesbury, Tolleshunt D'Arcy, Tolleshunt Knights",
    "CM11": "Billericay (East), Great Burstead",
    "CM12": "Billericay (West)",
    "CM13": "Brentwood, East Horndon, Great Warley, Herongate, Hutton, Ingrave, Little Warley, West Horndon",
    "CM14": "Brentwood",
    "CM15": "Brentwood, Doddinghurst, Kelvedon Hatch, Mountnessing, Shenfield, Stondon Massey",
    "CM16": "Epping, Theydon Bois",
    "CM17": "Harlow, Old Harlow, Matching, Matching Tye, Matching Green",
    "CM18": "Harlow",
    "CM19": "Harlow, Roydon",
    "CM20": "Harlow",
    "CM21": "Sawbridgeworth",
    "CM22": "Bishop's Stortford, Sheering",
    "CM23": "Bishop's Stortford",
    "CM24": "Stansted Mountfitchet",
    "CM77": "Braintree, Great Notley, Rayne",
    "CM92": "Non-geographic",
    "CM98": "Non-geographic",
    "CM99": "Chelmsford",
    "CO1": "Colchester",
    "CO2": "Colchester",
    "CO3": "Colchester",
    "CO4": "Colchester",
    "CO5": "Colchester",
    "CO6": "Colchester",
    "CO7": "Wivenhoe, Great Bentley",
    "CO8": "Bures",
    "CO9": "Halstead",
    "CO10": "Sudbury",
    "CO11": "Manningtree",
    "CO12": "Harwich",
    "CO13": "Frinton-on-Sea",
    "CO14": "Walton On The Naze",
    "CO15": "Clacton-on-Sea",
    "CO16": "Clacton-on-Sea",
    "CR0": "Croydon, Addiscombe, Shirley, Addington, New Addington, Forestdale, Waddon",
    "CR2": "South Croydon, Sanderstead, Selsdon, Addington",
    "CR3": "Caterham, Whyteleafe, Chaldon, Woldingham",
    "CR4": "Mitcham, Beddington Corner",
    "CR5": "Coulsdon, Chipstead, Woodmansterne",
    "CR6": "Warlingham, Chelsham, Farleigh",
    "CR7": "Thornton Heath",
    "CR8": "Purley, Kenley",
    "CR9": "Non-geographic",
    "CR44": "Non-geographic",
    "CR90": "Non-geographic",
    "CT1": "Canterbury",
    "CT2": "Sturry",
    "CT3": "Canterbury",
    "CT4": "Canterbury",
    "CT5": "Whitstable",
    "CT6": "Herne Bay",
    "CT7": "Birchington",
    "CT8": "Westgate-on-sea",
    "CT9": "Margate",
    "CT10": "Pysons Road Industrial Estate, St Peters",
    "CT11": "Ramsgate",
    "CT12": "Minster",
    "CT13": "Sandwich",
    "CT14": "Deal",
    "CT15": "Dover",
    "CT16": "Dover",
    "CT17": "Dover",
    "CT18": "Hawkinge, Lyminge, Etchinghill, Capel-le-Ferne",
    "CT19": "Central Folkestone, Cheriton",
    "CT20": "Central Folkestone",
    "CT21": "Hythe",
    "CT50": "Folkestone",
    "CV1": "Coventry City Centre, Gosford Green, Hillfields, Spon End, Coventry University",
    "CV2": "Walsgrave, Wyken, Stoke, Bell Green, Wood End, Potters Green, Aldermans Green, Clifford Park, Woodway Park",
    "CV3": "Binley, Whitley, Willenhall, Cheylesmore, Styvechale, Finham, Fenside, Stoke Aldermoor, Green Lane, Ernesford Grange, Binley Woods",
    "CV4": "Tile Hill, Canley, Cannon Park, Lime Tree Park, Gibbet Hill, Westwood Heath, University of Warwick",
    "CV5": "Allesley, Allesley Park, Allesley Green, Earlsdon, Eastern Green, Whoberley, Chapelfields, Mount Nod, Brownshill Green",
    "CV6": "Holbrooks, Coundon, Radford, Longford, Rowley's Green, Whitmore Park, Hawkesbury",
    "CV7": "Exhall, Ash Green, Keresley, Meriden, Balsall Common, Berkswell, Corley, Arley, Ansty, Shilton, Fillongley",
    "CV8": "Kenilworth, Baginton, Bubbenhall, Brandon, Stoneleigh, Wolston, Ryton-On-Dunsmore, Ashow",
    "CV9": "Atherstone, Mancetter, Grendon, Baddesley Ensor, Baxterley, Hurley, Witherley, Wood End, Twycross",
    "CV10": "Weddington, Stockingford, Camp Hill, Galley Common, Grove Farm, Whittleford, Chapel End, Bermuda Village, Caldecote, Fenny Drayton, Hartshill, Ansley, Astley, Oldbury, Ridge Lane",
    "CV11": "Nuneaton town centre, Abbey Green, St Nicolas Park, Horeston Grange, Attleborough, Whitestone, Hill Top, Chilvers Coton, Caldwell, Burton Hastings, Bramcote",
    "CV12": "Bedworth, Bulkington",
    "CV13": "Barlestone, Barton in the Beans, Bilstone, Cadeby, Carlton, Congerstone, Dadlington, Fenny Drayton, Higham on the Hill, Market Bosworth, Nailstone, Odstone, Osbaston, Shackerstone, Shenton, Stoke Golding, Sutton Cheney, Upton, Wellsborough",
    "CV21": "Rugby E",
    "CV22": "Rugby SW",
    "CV23": "Brownsover, Dunchurch, Thurlaston, Princethorpe, Brinklow, Long Lawford, Stretton-under-Fosse, Monks Kirby",
    "CV31": "Leamington Spa S, Whitnash, Radford Semele",
    "CV32": "Leamington Spa N",
    "CV33": "Harbury",
    "CV34": "Warwick",
    "CV35": "Wellesbourne, Kineton",
    "CV36": "Shipston-on-Stour",
    "CV37": "Stratford-Upon-Avon",
    "CV47": "Southam",
    "CW1": "Crewe",
    "CW2": "Crewe",
    "CW3": "Crewe",
    "CW4": "Holmes Chapel, Goostrey",
    "CW5": "Nantwich, Willaston",
    "CW6": "Tarporley",
    "CW7": "Winsford",
    "CW8": "Northwich",
    "CW9": "Northwich, Wincham, Pickmere",
    "CW10": "Middlewich",
    "CW11": "Sandbach",
    "CW12": "Congleton",
    "CW98": "Non-geographic",
    "DA1": "Dartford, Crayford, Barnes Cray",
    "DA2": "Dartford, Stone, Joydens Wood, Wilmington, Bean",
    "DA3": "Longfield, Hartley, Fawkham",
    "DA4": "Dartford, South Darenth, Farningham, Eynsford",
    "DA5": "Bexley, Albany Park, Joydens Wood",
    "DA6": "Bexleyheath, Upton",
    "DA7": "Bexleyheath, Barnehurst",
    "DA8": "Erith, Northumberland Heath, Slade Green",
    "DA9": "Greenhithe, Stone",
    "DA10": "Swanscombe, Ebbsfleet",
    "DA11": "Gravesend (west), Northfleet",
    "DA12": "Gravesend (east)",
    "DA13": "Meopham",
    "DA14": "Sidcup, Foots Cray, Albany Park, Longlands",
    "DA15": "Sidcup, Blackfen, Longlands, Lamorbey, Avery Hill",
    "DA16": "Welling, Falconwood, East Wickham",
    "DA17": "Belvedere, Lessness Heath",
    "DA18": "Erith Marshes, Thamesmead",
    "DD1": "Dundee city centre",
    "DD2": "Lochee, Blackness, Ninewells, Menziehill, Charleston, Balgay, Ardler, Logie",
    "DD3": "St Marys, Downfield, Kirkton, Strathmartine, Coldside, Hilltown",
    "DD4": "Stobswell, Craigie, Pitkerro, Douglas, Fintry, Whitfield",
    "DD5": "Broughty Ferry, Barnhill, Monifieth",
    "DD6": "Newport-on-Tay, Wormit, Balmerino, Gauldry, Tayport",
    "DD7": "Carnoustie",
    "DD8": "Forfar, Glamis, Kirriemuir",
    "DD9": "Brechin",
    "DD10": "Montrose, St Cyrus, Inverbervie, Gourdon",
    "DD11": "Arbroath, Friockheim",
    "DE1": "Derby city centre",
    "DE2": "Derby",
    "DE3": "Mickleover",
    "DE4": "Darley Dale, Beeley, Rowsley, Winster, Darley Bridge, Elton, Bonsall, Matlock, Matlock Bath, Cromford, Middleton-by-Wirksworth, Wirksworth, Bolehill, Crich, Holloway, Lea, Tansley",
    "DE5": "Codnor, Denby, Ripley",
    "DE6": "Ashbourne, Hulland Ward, Weston Underwood",
    "DE7": "Horsley Woodhouse, Ilkeston, Morley, West Hallam",
    "DE11": "Church Gresley, Hartshorne, Newhall, Swadlincote",
    "DE12": "Appleby Magna, Linton, Measham, Overseal, Rosliston, Walton-on-Trent",
    "DE13": "Alrewas, Barton-under-Needwood, Rolleston on Dove, Tutbury",
    "DE14": "Branston, Burton upon Trent, Newton Solney, Winshill",
    "DE15": "Bretby, Stapenhill",
    "DE21": "Chaddesden, Oakwood, Spondon, Breadsall, Horsley, Little Eaton",
    "DE22": "Allestree, Darley Abbey, Mackworth Estate, Kedleston, Mackworth, Quarndon",
    "DE23": "Heatherton Village, Littleover, Normanton, Pear Tree",
    "DE24": "Allenton, Alvaston, Boulton, Osmaston, Shelton Lock, Sinfin, Wilmorton, Stenson Fields",
    "DE45": "Ashford-in-the-Water, Bakewell, Baslow, Chatsworth, Edensor, Hassop, Monyash, Youlgreave",
    "DE55": "Swanwick, South Normanton Alfreton, Riddings, Leabrooks, Somercotes, Tibshelf, Newton, Blackwell, Bolsover, Hilcote, Wessington, Higham, Stonebroom, Morton, Derbyshire, Shirland, Alfreton, South Wingfield",
    "DE56": "Ambergate, Belper, Duffield, Heage, Holbrook, Kilburn, Milford",
    "DE65": "Burnaston, Egginton, Etwall, Findern, Hatton, Hilton, Milton, Repton, Willington",
    "DE72": "Ambaston, Aston-on-Trent, Borrowash, Breaston, Church Wilne, Draycott, Elvaston, Ockbrook, Risley, Shardlow, Weston-on-Trent",
    "DE73": "Chellaston, Barrow upon Trent, Ingleby, Melbourne, Stanton by Bridge, Swarkestone, Ticknall",
    "DE74": "Castle Donington, Hemington, Kegworth",
    "DE75": "Heanor, Loscoe",
    "DE99": "Non-geographic",
    "DG1": "Dumfries",
    "DG2": "Dumfries",
    "DG3": "Thornhill",
    "DG4": "Sanquhar",
    "DG5": "Dalbeattie",
    "DG6": "Kirkcudbright",
    "DG7": "Castle Douglas",
    "DG8": "Newton Stewart",
    "DG9": "Stranraer",
    "DG10": "Moffat",
    "DG11": "Lockerbie",
    "DG12": "Annan",
    "DG13": "Langholm",
    "DG14": "Canonbie",
    "DG16": "Gretna",
    "DH1": "Durham",
    "DH2": "Chester-le-Street, Ouston, Pelton, Birtley",
    "DH3": "Chester-le-Street, Great Lumley, Birtley",
    "DH4": "Houghton-le-Spring",
    "DH5": "Houghton-le-Spring, Hetton-le-Hole",
    "DH6": "Thornley, Coxhoe",
    "DH7": "Brandon, Lanchester, Esh Winning, Burnhope, Langley Park, Sacriston, Ushaw Moor",
    "DH8": "Consett, Ebchester",
    "DH9": "Dipton, Stanley",
    "DH97": "Durham",
    "DH98": "Durham",
    "DH99": "Durham",
    "DL1": "Darlington East",
    "DL2": "Staindrop, Gainford, Darlington",
    "DL3": "Darlington West",
    "DL4": "Shildon",
    "DL5": "Newton Aycliffe, Heighington",
    "DL6": "Northallerton East, Ingleby Cross",
    "DL7": "Northallerton West",
    "DL8": "Wensleydale, Bedale",
    "DL9": "Catterick Garrison",
    "DL10": "Richmond, Catterick, Brompton-upon-Swale, Scotch Corner",
    "DL11": "Swaledale, Arkengarthdale",
    "DL12": "Barnard Castle, Bowes, Middleton-in-Teesdale",
    "DL13": "Stanhope, Frosterley, Wolsingham, Tow Law",
    "DL14": "Bishop Auckland, Evenwood",
    "DL15": "Crook, Willington",
    "DL16": "Spennymoor",
    "DL17": "Ferryhill, Chilton, Cornforth, Bishop Middleham",
    "DL98": "Non-geographic",
    "DN1": "Doncaster town centre",
    "DN2": "Intake, Wheatley, Wheatley Hills",
    "DN3": "Armthorpe, Barnby Dun, Branton, Edenthorpe, Kirk Sandall",
    "DN4": "Balby, Belle Vue, Bessacarr, Cantley, Hexthorpe, Hyde Park, Warmsworth",
    "DN5": "Arksey, Barnburgh, Bentley, Cusworth, Harlington, Scawsby, Scawthorpe, Sprotborough, Toll Bar",
    "DN6": "Adwick-le-Street, Askern, Campsall, Moss, Norton, Sutton, Walden Stubbs, Woodlands",
    "DN7": "Dunsville, Hatfield, Lindholme, Stainforth",
    "DN8": "Thorne",
    "DN9": "Epworth, Finningley, Haxey, Owston Ferry",
    "DN10": "Bawtry, Misson, Misterton, Scrooby",
    "DN11": "Harworth, New Rossington, Rossington, Tickhill, Wadworth",
    "DN12": "Conisbrough, Denaby Main, New Edlington, Old Denaby, Old Edlington",
    "DN14": "Goole, Howden, Rawcliffe, Carlton",
    "DN15": "Alkborough, Appleby, Burton upon Stather, Coleby, Dragonby, Flixborough, Flixborough Ind Estate, Foxhills Ind Estate, Gunness, High Risby, High Santon, Low Risby, Low Santon, Normanby, Roxby, Scunthorpe, Thealby, West Halton, Whitton, Wintringham, Winterton",
    "DN16": "Bottesford, Holme, Queensway Ind Estate, Raventhorpe, Scunthorpe, Twigmoor",
    "DN17": "Althorpe, Amcotts, Bottesford Moor, Bottesford, Crowle, Derrythorpe, Ealand, East Butterwick, Eastoft, Garthorpe, Gunness, Keadby, Luddington, Messingham, North Ewster, Scunthorpe, Susworth, West Butterwick, Yaddlethorpe",
    "DN18": "Barton-Upon-Humber",
    "DN19": "Barrow-Upon-Humber",
    "DN20": "Brigg, Broughton, Hibaldstow, Scawby, Wrawby",
    "DN21": "Gainsborough",
    "DN22": "Ranskill, Retford",
    "DN31": "Grimsby",
    "DN32": "Grimsby",
    "DN33": "Grimsby",
    "DN34": "Grimsby",
    "DN35": "Cleethorpes",
    "DN36": "Holton le Clay, Humberston, Ludborough, Marshchapel, New Waltham, North Cotes, North Thoresby, Tetney",
    "DN37": "Ashby-cum-Fenby, Barnoldby-le-Beck, Beelsby, Bradley, Brocklesby, Great Coates, Great Limber, Irby-upon-Humber, Laceby, Waltham",
    "DN38": "Barnetby, Grasby, Searby, Somerby",
    "DN39": "Croxton, Kirmington, Ulceby, Wootton",
    "DN40": "North Killingholme, South Killingholme, Habrough, Immingham, East Halton",
    "DN41": "Healing, Keelby, Stallingborough",
    "DN55": "Non-geographic, Doncaster",
    "DT1": "Dorchester",
    "DT2": "Dorchester",
    "DT3": "Weymouth",
    "DT4": "Weymouth",
    "DT5": "Portland",
    "DT6": "Bridport",
    "DT7": "Lyme Regis",
    "DT8": "Beaminster",
    "DT9": "Sherborne",
    "DT10": "Sturminster Newton",
    "DT11": "Blandford Forum",
    "DY1": "Dudley town centre, Woodsetton",
    "DY2": "Dudley, Kates Hill, Netherton",
    "DY3": "Sedgley, Lower Gornal, Upper Gornal, Gornalwood, Himley, Swindon, Woodsetton",
    "DY4": "Tipton, Tividale, Coseley",
    "DY5": "Brierley Hill, Pensnett, Quarry Bank",
    "DY6": "Kingswinford",
    "DY7": "Kinver, Enville, Stourton",
    "DY8": "Stourbridge, Wollaston, Wordsley, Amblecote",
    "DY9": "Lye, Pedmore, Hagley",
    "DY10": "Kidderminster",
    "DY11": "Kidderminster",
    "DY12": "Bewdley",
    "DY13": "Stourport-on-Severn",
    "DY14": "Cleobury Mortimer",
    "E1": "Aldgate, Bishopsgate, Whitechapel, Shoreditch, Spitalfields, Shadwell, Stepney, Mile End, Portsoken",
    "E1W": "Wapping",
    "E2": "Bethnal Green, Haggerston, Shoreditch, Cambridge Heath",
    "E3": "Bow, Bromley-by-Bow, Old Ford, Mile End, Three Mills, London Gas Museum",
    "E4": "Chingford, Sewardstone, Highams Park",
    "E5": "Upper Clapton, Lower Clapton, Leyton, Stoke Newington",
    "E6": "East Ham, Beckton, Upton Park, Barking",
    "E7": "Forest Gate, Stratford",
    "E8": "Hackney Central, Dalston, London Fields",
    "E9": "Homerton, Hackney Wick, South Hackney, Hackney Marshes, Victoria Park",
    "E10": "Leyton, Temple Mills, Hackney Marshes, Upper Clapton, Walthamstow Marshes",
    "E11": "Leytonstone, Wanstead, Aldersbrook, Snaresbrook, Cann Hall",
    "E12": "Manor Park, Little Ilford, Aldersbrook",
    "E13": "Plaistow, West Ham, Upton Park",
    "E14": "Poplar, Isle of Dogs, Limehouse, Canary Wharf, Blackwall, Cubitt Town",
    "E15": "Stratford, West Ham, Maryland, Leyton, Leytonstone, Temple Mills, Hackney Wick, Bow",
    "E16": "Silvertown, North Woolwich, Canning Town, Custom House, London City Airport",
    "E17": "Walthamstow, Upper Walthamstow",
    "E18": "Woodford, South Woodford",
    "E20": "Olympic Park, Stratford",
    "E77": "Non-geographic",
    "E98": "Non-geographic",
    "EC1A": "St Bartholomew's Hospital",
    "EC1M": "Clerkenwell, Farringdon",
    "EC1N": "Hatton Garden",
    "EC1P": "Non-geographic",
    "EC1R": "Finsbury, Finsbury Estate",
    "EC1V": "Finsbury, Moorfields Eye Hospital",
    "EC1Y": "St Luke's, Bunhill Fields",
    "EC2A": "Shoreditch",
    "EC2M": "Broadgate, Liverpool Street",
    "EC2N": "Old Broad Street, Tower 42",
    "EC2P": "Non-geographic",
    "EC2R": "Bank of England",
    "EC2V": "Guildhall",
    "EC2Y": "Barbican",
    "EC3A": "St Mary Axe, Aldgate",
    "EC3B": "City of London",
    "EC3M": "Leadenhall, Lloyd's of London, Fenchurch Street",
    "EC3N": "Tower Hill, Tower of London",
    "EC3P": "Non-geographic",
    "EC3R": "Monument, Billingsgate",
    "EC3V": "Royal Exchange, Lombard Street",
    "EC4A": "Fetter Lane",
    "EC4M": "St Paul's",
    "EC4N": "Mansion House",
    "EC4P": "Non-geographic",
    "EC4R": "Cannon Street",
    "EC4V": "Blackfriars",
    "EC4Y": "Temple",
    "EC50": "Non-geographic",
    "EC88": "Non-geographic",
    "EH1": "Old Town",
    "EH2": "New Town",
    "EH3": "New Town, West End, Tollcross, Fountainbridge",
    "EH4": "Dean Village, Comely Bank, Barnton, Cramond",
    "EH5": "Granton",
    "EH6": "Leith, Newhaven",
    "EH7": "Leith, Restalrig, Craigentinny",
    "EH8": "Southside, Newington, Canongate, Northfield, Mountcastle",
    "EH9": "Marchmont, Grange, Blackford",
    "EH10": "Bruntsfield, Morningside, Fairmilehead",
    "EH11": "Haymarket Station, Gorgie, Stenhouse, Sighthill",
    "EH12": "Haymarket, Murrayfield, Corstorphine",
    "EH13": "Colinton, Oxgangs",
    "EH14": "Slateford, Longstone, Wester Hailes, Juniper Green, Currie, Balerno",
    "EH15": "Portobello, Duddingston",
    "EH16": "Liberton, Cameron Toll, Craigmillar, Niddrie",
    "EH17": "Gilmerton, Moredun, Mortonhall",
    "EH18": "Lasswade, Polton",
    "EH19": "Bonnyrigg",
    "EH20": "Loanhead",
    "EH21": "Musselburgh, Wallyford, Whitecraig",
    "EH22": "Dalkeith, Danderhall, Mayfield, Newtongrange",
    "EH23": "Gorebridge",
    "EH24": "Rosewell",
    "EH25": "Roslin, Bilston",
    "EH26": "Penicuik",
    "EH27": "Kirknewton",
    "EH28": "Newbridge, Ratho",
    "EH29": "Kirkliston",
    "EH30": "South Queensferry",
    "EH31": "Gullane",
    "EH32": "Prestonpans, Cockenzie, Port Seton",
    "EH33": "Tranent",
    "EH34": "Pencaitland",
    "EH35": "Ormiston",
    "EH36": "Humbie",
    "EH37": "Pathhead",
    "EH38": "North Middleton, Heriot",
    "EH39": "North Berwick",
    "EH40": "East Linton",
    "EH41": "Haddington",
    "EH42": "Dunbar, Broxburn, East Lothian",
    "EH43": "Walkerburn",
    "EH44": "Innerleithen",
    "EH45": "Peebles",
    "EH46": "West Linton",
    "EH47": "Whitburn, Bathgate, Fauldhouse, Stoneyburn",
    "EH48": "Armadale, Bathgate",
    "EH49": "Linlithgow",
    "EH51": "Bo'ness",
    "EH52": "Broxburn, West Lothian, Winchburgh",
    "EH53": "Livingston, Mid Calder, Pumpherston",
    "EH54": "Livingston",
    "EH55": "West Calder",
    "EH77": "Non-geographic",
    "EH91": "Edinburgh",
    "EH95": "Scottish Gas",
    "EH99": "Scottish Parliament",
    "EN1": "Bush Hill Park, Bulls Cross, Enfield Town, Forty Hill",
    "EN2": "Botany Bay, Clay Hill, Crews Hill, Bulls Cross, Enfield Chase, Enfield Town, Forty Hill",
    "EN3": "Enfield Highway, Enfield Island Village, Enfield Lock, Enfield Wash, Ponders End",
    "EN4": "Hadley Wood, Cockfosters, East Barnet, New Barnet",
    "EN5": "High Barnet, Arkley",
    "EN6": "Potters Bar, South Mimms, Cuffley, Northaw",
    "EN7": "Cheshunt",
    "EN8": "Waltham Cross, Cheshunt",
    "EN9": "Waltham Abbey",
    "EN10": "Broxbourne",
    "EN11": "Hoddesdon",
    "EN77": "Non-geographic",
    "EX1": "Exeter city centre",
    "EX2": "St Thomas, Countess Wear , Wonford",
    "EX3": "Exeter",
    "EX4": "Exwick, St Thomas, Beacon Heath , Redhils",
    "EX5": "Clyst St. Mary, Woodbury",
    "EX6": "Haldon, Mamhead, Exminster",
    "EX7": "Dawlish",
    "EX8": "Exmouth",
    "EX9": "Budleigh Salterton",
    "EX10": "Sidmouth",
    "EX11": "Ottery Saint Mary",
    "EX12": "Seaton",
    "EX13": "Axminster",
    "EX14": "Honiton",
    "EX15": "Cullompton, Plymtree",
    "EX16": "Tiverton",
    "EX17": "Crediton",
    "EX18": "Chulmleigh",
    "EX19": "Winkleigh",
    "EX20": "Okehampton",
    "EX21": "Beaworthy",
    "EX22": "Holsworthy",
    "EX23": "Bude, Coombe, Crackington Haven, Launcells, Poundstock, Stratton",
    "EX24": "Colyton",
    "EX31": "Barnstaple",
    "EX32": "Barnstaple",
    "EX33": "Braunton",
    "EX34": "Ilfracombe, Woolacombe",
    "EX35": "Lynmouth, Lynton",
    "EX36": "South Molton",
    "EX37": "Umberleigh",
    "EX38": "Torrington",
    "EX39": "Bideford, Lundy Island",
    "FK1": "Falkirk",
    "FK2": "Bainsford, Polmont, Maddiston, Airth",
    "FK3": "Grangemouth, Glensburgh",
    "FK4": "Banknock, Bonnybridge, Haggs",
    "FK5": "Larbert, Stenhousemuir, Torwood",
    "FK6": "Denny, Fankerton, Stoneywood",
    "FK7": "Bannockburn, Plean, Cowie",
    "FK8": "Stirling, Gargunnock",
    "FK9": "Bridge of Allan, Lecropt",
    "FK10": "Alloa, Clackmannan, Sauchie, Tullibody",
    "FK11": "Menstrie",
    "FK12": "Alva",
    "FK13": "Tillicoultry, Coalsnaughton, Devonside",
    "FK14": "Dollar, Glendeven, Burnfoot",
    "FK15": "Braco",
    "FK16": "Doune, Argaty, Buchany, Deanston",
    "FK17": "Callander",
    "FK18": "Callander, Strathyre",
    "FK19": "Lochearnhead",
    "FK20": "Crianlarich, Luib, Tyndrum",
    "FK21": "Killin, Ardeonaig, Auchmore, Clachaig, Glenlochay, Kinnell",
    "FY0": "Blackpool",
    "FY1": "Blackpool",
    "FY2": "Bispham",
    "FY3": "Layton, Marton, Staining",
    "FY4": "Blackpool, Blackpool Airport, Marton, South Shore",
    "FY5": "Thornton-Cleveleys",
    "FY6": "Poulton-Le-Fylde, Hambleton, Knott End-on-Sea, Preesall, Singleton",
    "FY7": "Fleetwood",
    "FY8": "Lytham St. Annes, Moss Side",
    "G1": "Merchant City",
    "G2": "Blythswood Hill",
    "G3": "Anderston, Finnieston, Garnethill, Park, Woodlands, Yorkhill",
    "G4": "Calton, Cowcaddens, Drygate, Kelvinbridge, Townhead, Woodlands, Woodside",
    "G5": "Gorbals",
    "G9": "Non-geographic",
    "G11": "Broomhill, Partick, Partickhill",
    "G12": "West End, Cleveden, Dowanhill, Hillhead, Hyndland, Kelvindale, Botanic Gardens",
    "G13": "Anniesland, Knightswood, Yoker",
    "G14": "Whiteinch, Scotstoun",
    "G15": "Drumchapel",
    "G20": "Maryhill, North Kelvinside, Ruchill",
    "G21": "Barmulloch, Cowlairs, Royston, Springburn, Sighthill",
    "G22": "Milton, Possilpark",
    "G23": "Lambhill, Summerston",
    "G31": "Dennistoun, Haghill, Parkhead",
    "G32": "Carmyle, Tollcross, Mount Vernon, Lightburn, Sandyhills",
    "G33": "Carntyne, Craigend, Cranhill, Millerston, Provanmill, Riddrie, Robroyston, Ruchazie, Stepps",
    "G34": "Easterhouse",
    "G40": "Bridgeton, Calton",
    "G41": "Pollokshields, Shawlands",
    "G42": "Battlefield, Govanhill, Mount Florida, Strathbungo East",
    "G43": "Mansewood, Newlands, Pollokshaws",
    "G44": "Cathcart, Croftfoot, King's Park, Muirend, Netherlee",
    "G45": "Castlemilk",
    "G46": "Giffnock, Kennishead, Thornliebank, Deaconsbank",
    "G51": "Govan, Ibrox",
    "G52": "Cardonald, Hillington, Penilee",
    "G53": "Darnley, Pollok, Crookston",
    "G58": "Non-geographic",
    "G59": "Non-geographic",
    "G60": "Bowling, Old Kilpatrick",
    "G61": "Bearsden",
    "G62": "Milngavie",
    "G63": "Balfron, Blanefield, Croftamie, Drymen, Dumgoyne, Fintry, Killearn, Strathblane",
    "G64": "Bishopbriggs, Torrance",
    "G65": "Croy, Kilsyth",
    "G66": "Kirkintilloch, Lennoxtown, Lenzie, Milton of Campsie",
    "G67": "Cumbernauld",
    "G68": "Cumbernauld, Dullatur",
    "G69": "Baillieston, Garrowhill, Gartcosh, Moodiesburn, Muirhead",
    "G70": "Non-geographic",
    "G71": "Bothwell, Uddingston",
    "G72": "Blantyre, Cambuslang",
    "G73": "Rutherglen",
    "G74": "East Kilbride, Thorntonhall",
    "G75": "East Kilbride",
    "G76": "Busby, Clarkston, Eaglesham, Waterfoot",
    "G77": "Newton Mearns",
    "G78": "Barrhead, Neilston, Uplawmoor",
    "G79": "Non-geographic",
    "G80": "Non-geographic",
    "G81": "Dalmuir, Duntocher, Faifley, Hardgate",
    "G82": "Cardross, Milton",
    "G83": "Ardlui, Balloch, Bonhill, Gartocharn, Luss, Renton, Tarbet",
    "G84": "Clynder, Cove, Garelochhead, Kilcreggan, Rhu, Rosneath, Shandon",
    "G90": "Non-geographic",
    "GIR": "Non-geographic",
    "GL1": "Gloucester",
    "GL2": "Gloucester",
    "GL3": "Gloucester",
    "GL4": "Gloucester",
    "GL5": "Stroud",
    "GL6": "Nailsworth, Painswick",
    "GL7": "Cirencester, Fairford, Lechlade",
    "GL8": "Tetbury",
    "GL9": "Badminton",
    "GL10": "Stonehouse",
    "GL11": "Dursley",
    "GL12": "Wotton-under-Edge",
    "GL13": "Berkeley",
    "GL14": "Cinderford, Newnham, Westbury-on-Severn",
    "GL15": "Blakeney, Lydney",
    "GL16": "Coleford",
    "GL17": "Lydbrook, Drybrook, Longhope, Mitcheldean, Ruardean",
    "GL18": "Dymock, Newent",
    "GL19": "Gloucester",
    "GL20": "Tewkesbury, Bredon",
    "GL50": "Cheltenham",
    "GL51": "Cheltenham",
    "GL52": "Cheltenham",
    "GL53": "Cheltenham",
    "GL54": "Cheltenham",
    "GL55": "Chipping Campden",
    "GL56": "Moreton-in-Marsh",
    "GU1": "Guildford Town Centre, Slyfield, Merrow",
    "GU2": "Guildford Park, Onslow Village, Park Barn, University of Surrey",
    "GU3": "Normandy, Puttenham, Worplesdon, Christmas Pie, Flexford",
    "GU4": "Burpham, Chilworth, Jacobs Well, Shalford",
    "GU5": "Albury, Bramley, Shere",
    "GU6": "Cranleigh, Ewhurst, Alfold",
    "GU7": "Farncombe, Godalming",
    "GU8": "Chiddingfold, Dunsfold, Elstead, Hascombe, Milford, Thursley, Witley",
    "GU9": "Farnham, Badshot Lea, Hale, Heath End",
    "GU10": "Bentley, Frensham, Churt, Crondall, Tongham, Ewshot, Seale, Tilford",
    "GU11": "Aldershot",
    "GU12": "Aldershot, Ash, Ash Green, Ash Vale",
    "GU13": "Fleet",
    "GU14": "Farnborough, Cove",
    "GU15": "Camberley, Old Dean, RMAS",
    "GU16": "Frimley, Frimley Green, Deepcut, Mytchett",
    "GU17": "Blackwater, Hawley, Minley, Darby Green, Frogmore, Blackbushe",
    "GU18": "Lightwater",
    "GU19": "Bagshot",
    "GU20": "Windlesham",
    "GU21": "Woking, Knaphill, St. John's, Horsell, Goldsworth Park",
    "GU22": "Woking, Pyrford, Hook Heath, Mayford, Old Woking",
    "GU23": "Send, Ripley, Ockham, Wisley",
    "GU24": "Bisley, Pirbright, Chobham, Knaphill, Brookwood, West End",
    "GU25": "Virginia Water, Wentworth",
    "GU26": "Hindhead, Bramshott Chase, Grayshott",
    "GU27": "Haslemere, Fernhurst, Shottermill, Grayswood",
    "GU28": "Petworth, Graffham, Northchapel, Byworth, Lodsworth",
    "GU29": "Midhurst, Cocking, Easebourne",
    "GU30": "Liphook, Bramshott, Conford, Linch, Milland, Passfield",
    "GU31": "Petersfield, Buriton, East Harting, South Harting, Elsted, Rogate",
    "GU32": "East Meon, West Meon, Sheet, Steep",
    "GU33": "Liss, Greatham, Selborne, Rake",
    "GU34": "Alton, Beech, Bentworth, Medstead, Four Marks, Golden Pot, Lasham",
    "GU35": "Bordon, Headley, Headley Down Lindford, Oakhanger, Kingsley, Arford, Whitehill",
    "GU46": "Yateley",
    "GU47": "Sandhurst, College Town, Owlsmoor, Little Sandhurst",
    "GU51": "Fleet, Elvetham Heath",
    "GU52": "Church Crookham, Crookham Village",
    "GU95": "British Gas",
    "GY1": "Saint Peter Port",
    "GY2": "Saint Sampson",
    "GY3": "Vale",
    "GY4": "Saint Martin",
    "GY5": "Castel",
    "GY6": "Vale, Saint Andrew",
    "GY7": "Saint Pierre Du Bois, Saint Saviour",
    "GY8": "Forest, Torteval",
    "GY9": "Alderney",
    "GY10": "Sark",
    "HA0": "Alperton, Sudbury, Sudbury Hill, Wembley Central, North Wembley",
    "HA1": "Harrow, North Harrow, Northwick Park",
    "HA2": "North Harrow, South Harrow",
    "HA3": "Harrow Weald, Kenton, Wealdstone",
    "HA4": "Ruislip",
    "HA5": "Pinner, Eastcote, Hatch End, Carpenders Park",
    "HA6": "Northwood, Moor Park, Sandy Lodge",
    "HA7": "Stanmore",
    "HA8": "Edgware",
    "HA9": "Wembley, Wembley Park, Wembley Central, Preston, Tokyngton",
    "HD1": "Huddersfield Town Centre, Hillhouse, Lockwood, Marsh, Paddock",
    "HD2": "Birkby, Brackenhall, Bradley, Deighton, Fartown, Fixby, Sheepridge",
    "HD3": "Lindley, Milnsbridge, Oakes, Outlane, Paddock, Salendine Nook, Scammonden",
    "HD4": "Berry Brow, Crosland Moor, Farnley Tyas, Netherton, Newsome, Lowerhouses, Stocksmoor",
    "HD5": "Almondbury, Dalton, Kirkheaton, Moldgreen, Waterloo",
    "HD6": "Bailiff Bridge, Brighouse, Rastrick, Clifton",
    "HD7": "Golcar, Linthwaite, Marsden, Scapegoat Hill, Slaithwaite",
    "HD8": "Clayton West, Denby Dale, Emley, Fenay Bridge, Kirkburton, Lepton, Scissett, Shelley, Shepley, Skelmanthorpe",
    "HD9": "Birdsedge, Brockholes, Hepworth, Holme, Holmfirth, Honley, Meltham, Scholes, Upperthong, Wooldale",
    "HG1": "Harrogate",
    "HG2": "Harrogate",
    "HG3": "Pannal, High Meadows, Killinghall, Spofforth, Bishop Monkton, Glasshouses",
    "HG4": "North Stainley, High Grantley",
    "HG5": "Scotton, Knaresborough",
    "HP1": "Bourne End, Boxmoor, Fields End, Gadebridge, Great Gaddesden, Nettleden, Piccotts End, Water End, Warner's End",
    "HP2": "Gaddesden Row, Piccotts End, Grove Hill, Adeyfield, Hemel Hempstead Industrial Estate",
    "HP3": "Apsley, Bovingdon, Felden, Flaunden, Hemel Hempstead, Leverstock Green",
    "HP4": "Berkhamsted, Dagnall, Dudswell, Little Gaddesden, Northchurch, Potten End, Ringshall",
    "HP5": "Chesham, Ashley Green, Bellingdon, Botley, Chartridge, Hawridge, Latimer, Lye Green, Newtown, Waterside, Whelpley Hill",
    "HP6": "Amersham, Chesham Bois, Hyde Heath, Little Chalfont",
    "HP7": "Amersham, Coleshill, Little Chalfont, Little Missenden, Penn Street, Winchmore Hill",
    "HP8": "Chalfont Saint Giles",
    "HP9": "Beaconsfield, Forty Green, Holtspur, Jordans, Knotty Green, Seer Green",
    "HP10": "High Wycombe, Flackwell Heath, Handy Cross, Hedsor, Loudwater, Penn, Tylers Green, Wooburn, Wooburn Green, Wooburn Moor",
    "HP11": "Wycombe Marsh",
    "HP12": "High Wycombe, Booker, Sands",
    "HP13": "High Wycombe, Downley",
    "HP14": "Beacon's Bottom, Bledlow Ridge, Bolter End, Bradenham, Cadmore End, Hughenden Valley, Ibstone, Lane End, Naphill, Northend, Stokenchurch, The City, Upper North Dean, West Wycombe",
    "HP15": "Cryers Hill, Great Kingshill, Hazlemere, Holmer Green, Hughenden Valley",
    "HP16": "Great Missenden, Ballinger, The Lee, Little Hampden, Little Kingshill, Prestwood, South Heath",
    "HP17": "Aston Sandford, Bishopstone, Butler's Cross, Dinton, Dorton, Ellesborough, Ford, The Kimbles, Kingsey, Haddenham, Meadle, Nash Lee, Stone, Upton, Westlington",
    "HP18": "Ashendon, Boarstall, Brill, Chearsley, Chilton, Cuddington, Dorton, Easington, Edgcott, Grendon Underwood, Ickford, Kingswood, Long Crendon, Lower Winchendon, Ludgershall, Oakley, Shabbington, Upper Winchendon, Waddesdon, Westcott, Worminghall, Wotton Underwood",
    "HP19": "Aylesbury, Fairford Leys, Prebendal Farm, Quarrendon, Watermead",
    "HP20": "Aylesbury, Aylesbury Town Centre, Broughton, Elmhurst",
    "HP21": "Aylesbury, Bedgrove, Elm Farm, Queens Park, Southcourt, Walton, Walton Court",
    "HP22": "Aston Abbotts, Aston Clinton, Bierton, Buckland, Drayton Beauchamp, Dunsmore, Halton, Hardwick, Hulcott, North Lee, Nup End, Oving, Pitchcott, Quainton, Rowsham, Stoke Mandeville, Weedon, Wendover, Weston Turville, Whitchurch, Wingrave",
    "HP23": "Aldbury, Buckland Common, Cholesbury, Hastoe, Long Marston, Marsworth, New Mill, Puttenham, St Leonards, Tring, Wigginton, Wilstone",
    "HP27": "Askett, Bledlow, Ilmer, Lacey Green, Longwick, Loosley Row, Monks Risborough, Owlswick, Pitch Green, Princes Risborough, Saunderton",
    "HR1": "Hereford city centre",
    "HR2": "South Hereford",
    "HR3": "West Hereford, Hay on Wye",
    "HR4": "North Hereford",
    "HR5": "Kington",
    "HR6": "Leominster",
    "HR7": "Bromyard",
    "HR8": "Ledbury",
    "HR9": "Ross-on-Wye",
    "HS1": "Stornoway, Plasterfield",
    "HS2": "Isle Of Lewis",
    "HS3": "Isle Of Harris",
    "HS4": "Isle Of Scalpay",
    "HS5": "Isle Of Harris",
    "HS6": "Isle Of North Uist",
    "HS7": "Isle Of Benbecula",
    "HS8": "Isle Of South Uist",
    "HS9": "Isle Of Barra",
    "HU1": "Hull",
    "HU2": "Hull",
    "HU3": "Hull",
    "HU4": "Hull, Anlaby Common, Anlaby Park, Gipsyville",
    "HU5": "Hull",
    "HU6": "Hull, Dunswell",
    "HU7": "Hull, Bransholme, Kingswood, Sutton-on-Hull, Wawne",
    "HU8": "Hull, Longhill, Ings",
    "HU9": "Hull, Marfleet",
    "HU10": "Anlaby, Kirk Ella, West Ella, Willerby",
    "HU11": "Bilton",
    "HU12": "Hedon, Patrington",
    "HU13": "Hessle",
    "HU14": "North Ferriby, Melton, Swanland",
    "HU15": "Elloughton-cum-Brough, South Cave",
    "HU16": "Cottingham, Eppleworth, Raywell, Skidby",
    "HU17": "Beverley",
    "HU18": "Hornsea, Mappleton, Rolston",
    "HU19": "Withernsea, Hollym, Holmpton, Out Newton, Rimswell, Waxholme",
    "HU20": "High Hunsley, Little Weighton, Low Hunsley, Riplingham",
    "HU55": "Non-geographic",
    "HX1": "Halifax Town Centre, Savile Park",
    "HX2": "Highroad Well, Illingworth, Luddenden, Luddenden Foot, Midgley, Mixenden, Mount Tabor, Norton Tower, Ogden, Wainstalls, Warley Town",
    "HX3": "Boothtown, Hipperholme, Lightcliffe, Norwood Green, Northowram, Ovenden, Shelf, Skircoat Green, Southowram",
    "HX4": "Barkisland, Greetland, Holywell Green, Sowood, Stainland, West Vale",
    "HX5": "Elland, Blackley",
    "HX6": "Norland, Ripponden, Rishworth, Sowerby, Sowerby Bridge",
    "HX7": "Cragg Vale, Hebden Bridge, Heptonstall, Mytholmroyd, Old Town",
    "IG1": "Ilford",
    "IG2": "Gants Hill, Newbury Park",
    "IG3": "Seven Kings",
    "IG4": "Redbridge",
    "IG5": "Clayhall",
    "IG6": "Barkingside, Hainault",
    "IG7": "Chigwell",
    "IG8": "Woodford Green, Woodford Bridge",
    "IG9": "Buckhurst Hill",
    "IG10": "Loughton, High Beach",
    "IG11": "Barking",
    "IM1": "Douglas",
    "IM2": "Douglas",
    "IM3": "Onchan",
    "IM4": "Port Soderick, Santon, Greeba, Braddan, St John's, Foxdale, Onchan, Baldrine, Laxey, Lonan",
    "IM5": "Peel, Patrick",
    "IM6": "Kirk Michael",
    "IM7": "Maughold, Sulby, Lezayre, Jurby, Andreas, Bride, Ballaugh",
    "IM8": "Ramsey",
    "IM9": "Castletown, Ballasalla, Malew, Arbory, Port St Mary, Port Erin",
    "IP1": "North West Ipswich, Akenham",
    "IP2": "South West Ipswich, Belstead, Wherstead, Stoke Park",
    "IP3": "South East Ipswich, Ravenswood",
    "IP4": "North East Ipswich",
    "IP5": "Rushmere St Andrew, Kesgrave, Martlesham Heath",
    "IP6": "Needham Market, Creeting St. Mary, Barham, Henley, Claydon, Witnesham",
    "IP7": "Hadleigh",
    "IP8": "Copdock, Belstead",
    "IP9": "Shotley Peninsula: Capel St Mary, Chelmondiston, Shotley",
    "IP10": "Kirton, Nacton, Levington",
    "IP11": "Felixstowe, Trimley St. Martin, Trimley St. Mary",
    "IP12": "Woodbridge, Orford",
    "IP13": "Woodbridge, Easton, Framlingham, Little Bealings, Laxfield",
    "IP14": "Stowmarket, Stowupland",
    "IP15": "Aldeburgh",
    "IP16": "Leiston, Aldringham, Eastbridge, Sizewell, Theberton, Thorpeness",
    "IP17": "Saxmundham",
    "IP18": "Southwold, Easton Bavents, Reydon, Walberswick",
    "IP19": "Halesworth",
    "IP20": "Harleston",
    "IP21": "Thorpe Abbotts",
    "IP22": "Diss",
    "IP23": "Eye",
    "IP24": "Thetford",
    "IP25": "Watton, Saham Toney",
    "IP26": "Hilborough, Northwold",
    "IP27": "Brandon",
    "IP28": "Mildenhall, Culford",
    "IP29": "Barrow",
    "IP30": "Cockfield, Woolpit",
    "IP31": "Bury Saint Edmunds",
    "IP32": "Bury Saint Edmunds",
    "IP33": "Bury Saint Edmunds",
    "IP98": "Diss",
    "IV1": "Inverness centre and north, The Longman, North Kessock, Kilmuir",
    "IV2": "Inverness east, Culloden, Balloch, Ardersier",
    "IV3": "Inverness west, Dochgarroch, Abriachan, Bunchrew",
    "IV4": "Beauly, Kiltarlity, Struy, Cannich, Tomich",
    "IV5": "Kirkhill, Inchmore, Moniack, Cabrich",
    "IV6": "Muir of Ord, Marybank, Strathconon",
    "IV7": "Conon Bridge, Culbokie",
    "IV8": "Munlochy",
    "IV9": "Avoch",
    "IV10": "Fortrose",
    "IV11": "Cromarty",
    "IV12": "Auldearn",
    "IV13": "Tomatin",
    "IV14": "Strathpeffer",
    "IV15": "Dingwall",
    "IV16": "Evanton",
    "IV17": "Alness",
    "IV18": "Invergordon",
    "IV19": "Tain",
    "IV20": "Fearn",
    "IV21": "Gairloch",
    "IV22": "Achnasheen",
    "IV23": "Garve",
    "IV24": "Ardgay",
    "IV25": "Dornoch",
    "IV26": "Ullapool",
    "IV27": "Lairg, Durness, Tongue, Scourie",
    "IV28": "Rogart",
    "IV30": "Elgin",
    "IV31": "Lossiemouth",
    "IV32": "Fochabers",
    "IV33": "Rothes",
    "IV34": "Archiestown",
    "IV35": "Archiestown",
    "IV36": "Forres",
    "IV40": "Kyle",
    "IV41": "Kyleakin, Isle Of Skye",
    "IV42": "Breakish, Isle Of Skye",
    "IV43": "Isle Of Skye",
    "IV44": "Isle Of Skye",
    "IV45": "Armadale, Isle Of Skye",
    "IV46": "Isle Of Skye",
    "IV47": "Isle Of Skye",
    "IV48": "Isle Of Skye",
    "IV49": "Broadford, Isle Of Skye",
    "IV51": "Portree",
    "IV52": "Plockton",
    "IV53": "Strome Ferry",
    "IV54": "Applecross",
    "IV55": "Isle Of Skye",
    "IV56": "Isle Of Skye",
    "IV63": "Drumnadrochit, Glenurquhart, Invermoriston",
    "IV99": "Non-geographic",
    "JE2": "Saint Helier, Saint Clement, Saint Saviour",
    "JE3": "Saint Lawrence, Saint Ouen, Saint Mary, Saint John, Trinity, Saint Martin, Saint Peter, Saint Brelade, Grouville",
    "KA1": "Kilmarnock, Bonnyton, Grange, Bellfield, Caprington, Riccarton, Shortlees, Hurlford South",
    "KA2": "Knockentiber, Crosshouse, Dundonald",
    "KA3": "Kilmarnock, Altonhill, Knockinlaw, Longpark, Beansburn, Hillhead, Kilmaurs, Kirktoun, Onthank, Stewarton, Lugton, Dunlop, Fullwood, Kingsford, Southcraigs, Fenwick, Crookedholm, Hurlford, Whinpark, New Farm Loch, Beansburn",
    "KA4": "Galston",
    "KA5": "Tarbolton, Mauchline, Catrine, Sorn",
    "KA6": "Dalrymple, Hollybush, Patna, Dalmellington, Bellsbank, Waterside, Rankinston, Drongan",
    "KA7": "Ayr, Belmont, Holmston, Forehill, Castlehill, Masonhill, Kincaidston, Doonfoot, Alloway",
    "KA8": "Dalmilling, Craigie, Woodfield, Wallacetown, Heathfield, Whitletts, Newton on Ayr, Lochside, Braehead",
    "KA9": "Prestwick, Monkton",
    "KA10": "Troon, Barassie, Muirhead, Loans, Barassie",
    "KA11": "Girdle Toll, Stanecastle, Bourtreehill, Broomlands, Perceton, Sourlie, Doura, Roddinghill, Springside, Irvine, Dreghorn",
    "KA12": "Irvine, Fullarton, Irvine Centre, Stanecastle",
    "KA13": "Kilwinning",
    "KA14": "Beith, Glengarnock, Longbar",
    "KA15": "Beith, Glengarnock, Longbar",
    "KA16": "Newmilns, Greenholm",
    "KA17": "Darvel",
    "KA18": "Cumnock, Ochiltree, Auchinleck, Muirkirk, Smallburn, Lugar, Logan, New Cumnock",
    "KA19": "Maybole, Crosshill, Straiton",
    "KA20": "Stevenston, Auchenharvie",
    "KA21": "Saltcoats",
    "KA22": "Ardrossan, Whitlees, Chapelhill",
    "KA23": "West Kilbride",
    "KA24": "Dalry, Drakemyre",
    "KA25": "Kilbirnie",
    "KA26": "Girvan, Ballantrae, Colmonell, Barrhill, Old Dally, Dally, Turnberry",
    "KA27": "Holy Island, Isle of Arran",
    "KA28": "Millport",
    "KA29": "Largs, Glenside, Fairlie, Kelburn",
    "KA30": "Largs",
    "KT1": "Kingston upon Thames, Hampton Wick",
    "KT2": "Kingston upon Thames, Norbiton",
    "KT3": "New Malden",
    "KT4": "Worcester Park",
    "KT5": "Berrylands, Tolworth",
    "KT6": "Surbiton, Tolworth, Long Ditton",
    "KT7": "Thames Ditton, Weston Green",
    "KT8": "West Molesey, East Molesey, Hampton Court Palace, Bushy Park",
    "KT9": "Chessington, Hook, Malden Rushett",
    "KT10": "Esher, Claygate, Hinchley Wood",
    "KT11": "Cobham",
    "KT12": "Walton-on-Thames, Hersham",
    "KT13": "Weybridge",
    "KT14": "Byfleet, West Byfleet",
    "KT15": "Addlestone, New Haw",
    "KT16": "Chertsey, Lyne",
    "KT17": "Stoneleigh, East Ewell, Epsom",
    "KT18": "Epsom, Headley, Langley Vale, Tattenham Corner",
    "KT19": "Horton, West Ewell, Stoneleigh",
    "KT20": "Tadworth, Burgh Heath",
    "KT21": "Ashtead",
    "KT22": "Leatherhead, Oxshott, Fetcham",
    "KT23": "The Bookhams",
    "KT24": "West Horsley, East Horsley, Effingham",
    "KW1": "Wick",
    "KW2": "Lybster",
    "KW3": "Lybster",
    "KW5": "Latheron",
    "KW6": "Dunbeath",
    "KW7": "Berriedale",
    "KW8": "Helmsdale",
    "KW9": "Brora",
    "KW10": "Golspie",
    "KW11": "Kinbrace",
    "KW12": "Halkirk",
    "KW13": "Forsinard",
    "KW14": "Thurso",
    "KW15": "Kirkwall",
    "KW16": "Stromness",
    "KW17": "Orkney",
    "KY1": "Kirkcaldy",
    "KY2": "Kirkcaldy",
    "KY3": "Burntisland",
    "KY4": "Cowdenbeath",
    "KY5": "Lochgelly",
    "KY6": "Glenrothes",
    "KY7": "Glenrothes",
    "KY8": "Leven",
    "KY9": "Leven",
    "KY10": "Anstruther",
    "KY11": "Dunfermline",
    "KY12": "Dunfermline",
    "KY13": "Kinross",
    "KY14": "Cupar",
    "KY15": "Cupar",
    "KY16": "Saint Andrews",
    "KY99": "Dunfermline",
    "L1": "Liverpool city centre",
    "L2": "Liverpool city centre",
    "L3": "Liverpool City Centre, Everton, Vauxhall",
    "L4": "Anfield, Kirkdale, Walton",
    "L5": "Anfield, Everton, Kirkdale, Vauxhall",
    "L6": "Anfield, Liverpool City Centre, Everton, Fairfield, Kensington, Tuebrook",
    "L7": "Liverpool City Centre, Edge Hill, Fairfield, Kensington",
    "L8": "Liverpool City Centre, Dingle, Toxteth",
    "L9": "Aintree, Fazakerley, Orrell Park, Walton",
    "L10": "Aintree Village, Fazakerley",
    "L11": "Clubmoor, Croxteth, Gillmoss, Norris Green",
    "L12": "Croxteth Park, West Derby",
    "L13": "Clubmoor, Old Swan, Stoneycroft, Tuebrook",
    "L14": "Broadgreen, Dovecot, Knotty Ash, Page Moss",
    "L15": "Wavertree",
    "L16": "Broadgreen, Bowring Park, Childwall",
    "L17": "Aigburth, St Michael's Hamlet, Sefton Park",
    "L18": "Allerton, Mossley Hill",
    "L19": "Aigburth, Garston, Grassendale",
    "L20": "Bootle, Kirkdale, Orrell",
    "L21": "Ford, Litherland, Seaforth",
    "L22": "Waterloo",
    "L23": "Blundellsands, Brighton-le-Sands, Crosby, Little Crosby, Thornton",
    "L24": "Hale, Speke",
    "L25": "Belle Vale, Gateacre, Hunts Cross, Woolton, Halewood",
    "L26": "Halewood",
    "L27": "Netherley",
    "L28": "Stockbridge Village",
    "L29": "Lunt, Sefton Village",
    "L30": "Bootle, Netherton",
    "L31": "Maghull, Lydiate, Melling, Waddicar",
    "L32": "Kirkby",
    "L33": "Kirkby",
    "L34": "Prescot, Knowsley Village",
    "L35": "Prescot, Whiston, Rainhill",
    "L36": "Huyton, Roby, Tarbock",
    "L37": "Formby, Little Altcar, Great Altcar",
    "L38": "Ince Blundell, Hightown",
    "L39": "Ormskirk",
    "L40": "Burscough, Scarisbrick",
    "L41": "Birkenhead",
    "L42": "Birkenhead",
    "L43": "Birkenhead",
    "L44": "Wallasey",
    "L45": "Wallasey",
    "L46": "Wallasey",
    "L47": "Hoylake",
    "L48": "West Kirby",
    "L49": "Upton",
    "L60": "Heswall",
    "L61": "Thingwall, Pensby, Irby",
    "L62": "Port Sunlight, Bromborough, Eastham",
    "L63": "Bebington",
    "L64": "Neston, Burton, Willaston",
    "L65": "Ellesmere Port",
    "L66": "Great Sutton, Hooton, South Wirral",
    "L67": "Liverpool",
    "L68": "Liverpool",
    "L69": "Liverpool, Liverpool University",
    "L70": "Liverpool, Speke",
    "L71": "Liverpool",
    "L72": "Liverpool",
    "L73": "Liverpool",
    "L74": "Liverpool",
    "L75": "Liverpool",
    "L80": "Non-geographic",
    "LA1": "Lancaster, Aldcliffe, Bailrigg",
    "LA2": "Lancaster, Abbeystead, Aldcliffe, Aughton, Austwick, Bailrigg, Bay Horse, Caton, Clapham, Cockerham, Dolphinholme, Ellel, Farleton, Galgate, Glasson Dock, Halton, Hest Bank, High Bentham, Hornby, Quernmore, Tatham, Wharfe",
    "LA3": "Morecambe, Heysham, Middleton, Overton, Sunderland Point",
    "LA4": "Morecambe, Torrisholme",
    "LA5": "Carnforth, Arnside, Silverdale, Warton, Bolton-Le-Sands",
    "LA6": "Arkholme, Cantsfield, Casterton, Ingleton, Ireby, Kirkby Lonsdale, Masongill, Tunstall, Whittington",
    "LA7": "Milnthorpe, Beetham, Storth, Heversham",
    "LA8": "Kendal, Sizergh, Grayrigg",
    "LA9": "Kendal, Burneside, Sedgwick",
    "LA10": "Sedbergh, Dent",
    "LA11": "Grange-over-Sands, Allithwaite",
    "LA12": "Ulverston, Backbarrow, Leece, Newby Bridge",
    "LA13": "Barrow-in-Furness",
    "LA14": "Barrow-in-Furness, Walney Island",
    "LA15": "Dalton-in-Furness",
    "LA16": "Askam-in-furness",
    "LA17": "Kirkby-in-Furness",
    "LA18": "Millom",
    "LA19": "Millom, Waberthwaite, Bootle",
    "LA20": "Broughton-in-furness",
    "LA21": "Coniston",
    "LA22": "Ambleside, Hawkshead, Near Sawrey",
    "LA23": "Windermere, Bowness-on-Windermere, Troutbeck",
    "LD1": "Llandrindod Wells",
    "LD2": "Builth Wells",
    "LD3": "Brecon, Talgarth",
    "LD4": "Llangammarch Wells",
    "LD5": "Llanwrtyd Wells",
    "LD6": "Rhayader",
    "LD7": "Knighton",
    "LD8": "Presteigne",
    "LE1": "Leicester",
    "LE2": "Oadby, Knighton, Highfields, Aylestone, Glen Parva",
    "LE3": "Braunstone, Glenfield, New Parks, Groby Road (A50), Leicester Forest East, Westcotes",
    "LE4": "Birstall, Belgrave, Beaumont Leys, Thurmaston",
    "LE5": "Hamilton, Thurnby Lodge, Evington",
    "LE6": "Ratby, Groby, Newtown Linford",
    "LE7": "Scraptoft, Anstey, Billesdon, Gaddesby, Hungarton, Rearsby, Tilton on the Hill, Tugby, Cropston, Thurcaston, Rothley, Barkby, Syston",
    "LE8": "Great Glen, Fleckney, Kibworth, Peatling Magna, Countesthorpe",
    "LE9": "Stoney Stanton, Cosby, Huncote, Croft, Desford, Newbold Verdon, Kirkby Mallory, Earl Shilton, Barwell, Sapcote, Sutton in the Elms, Broughton Astley, Thurlaston",
    "LE10": "Hinckley",
    "LE11": "Loughborough, Charnwood",
    "LE12": "East Leake, West Leake, Sutton Bonington, Mountsorrel, Shepshed, Belton, Quorn, Sileby, Wymeswold",
    "LE13": "Melton Mowbray",
    "LE14": "Brooksby, Harby, Hoby, Ragdale, Rotherby, Scalford, Somerby, Stonesby, Waltham on the Wolds, Wymondham",
    "LE15": "Empingham, Manton, Thistleton, Uppingham, Whissendine, Langham",
    "LE16": "East Langton, Hallaton, Market Harborough, Medbourne, Braybrooke",
    "LE17": "Leire, Lutterworth, Swinford, Bitteswell, Ullesthorpe",
    "LE18": "Wigston",
    "LE19": "Narborough, Enderby",
    "LE21": "Leicester",
    "LE41": "Non-geographic",
    "LE55": "Leicester",
    "LE65": "Ashby-de-la-Zouch, Boundary, Calke, Smisby, Willesley, Worthington",
    "LE67": "Coalville, Ibstock, Markfield",
    "LE87": "Leicester",
    "LE94": "Non-geographic",
    "LE95": "Non-geographic",
    "LE99": "Non-geographic",
    "LL11": "Wrexham, Coedpoeth, Llandegla, Minera, Bradley, Gwersyllt, Rhosrobin, Stansty, Brymbo, Bwlchgwyn, Cymau, Ffrith, Gwynfryn, Tanyfron, Brynteg",
    "LL12": "Wrexham, Caergwrle, Cefn-y-Bedd, Cymau, Gresford, Hope, Llay, Marford, Rossett",
    "LL13": "Wrexham, Abenbury, Bowling Bank, Holt, Llan-y-Pwll, Pentre Maelor, Ridley Wood, Bangor-on-Dee, Gyfelia, Marchwiel, Overton, Penley, Worthenbury",
    "LL14": "Wrexham, Rhosllanerchrugog, Cefn Mawr, Ponciau, Rhostyllen, Chirk, Ruabon",
    "LL15": "Ruthin, Bontuchel, Clawddnewydd, Clocaenog, Cyffylliog, Gellifor, Graigfechan, Llanbedr Dyffryn Clwyd, Llanelidan, Llanfair Dyffryn Clwyd, Pwllglas, Rhewl",
    "LL16": "Denbigh, Bodfari, Llandyrnog, Llanrhaeadr, Bylchau, Groes, Henllan, Llannefydd, Llansannan, Nantglyn, The Green, Trefnant",
    "LL17": "St. Asaph, Allt Goch, Rhuallt, Tremeirchion, Waen",
    "LL18": "Rhyl, Bodelwyddan, Cwm Dyserth, Kinmel Bay, Dyserth, Gwaenysgor, Rhuddlan, Trelawnyd",
    "LL19": "Prestatyn, Gronant",
    "LL20": "Froncysyllte, Garth, Glyn Ceiriog, Llanarmon Dyffryn Ceiriog, Llwynmawr, Pandy, Pontcysyllte, Pontfadog, Rhewl, Tregeiriog, Trevor, Llangollen, Eglwyseg, Llantysilio",
    "LL21": "Corwen, Cynwyd, Glanrafon, Glasfryn, Llandrillo, Llangwm, Maerdy, Ty Nant, Betws Gwerfil Goch, Bryneglwys, Carrog, Cerrigydrudion, Clawdd Poncen, Derwen, Glyndyfrdwy, Gwyddelwern, Llanfihangel Glyn Myfyr, Llidiart-y-Parc, Melin-y-Wig",
    "LL22": "Abergele, Betws Yn Rhos, Gwytherin, Llanddulas, Llanfairtalhaiarn, Llangernyw, Moelfre, Pandy Tudur, Rhyd-y-Foel, Glascoed, St. George",
    "LL23": "Bala, Llandderfel, Llanfor, Llanuwchllyn, Sarnau",
    "LL24": "Betws-y-Coed, Capel Curig, Cwm Penmachno, Penmachno, Pentre Du, Pentrefoelas, Ysbyty Ifan",
    "LL25": "Dolwyddelan",
    "LL26": "Llanrwst, Capel Garmon, Llanddoged, Melin-y-Coed, Maenan",
    "LL27": "Trefriw",
    "LL28": "Colwyn Bay, Rhos on Sea, Eglwysbach, Glan Conwy, Mochdre, Tal-y-Cafn",
    "LL29": "Colwyn Bay, Llanelian, Llysfaen, Old Colwyn",
    "LL30": "Llanrhos, Llandudno, Penrhyn Bay",
    "LL31": "Deganwy, Glanwydden, Llangwstenin, Llandudno Junction",
    "LL32": "Conwy, Dolgarrog, Groesffordd, Henryd, Rowen, Tal-y-Bont, Tyn-y-Groes",
    "LL33": "Abergwyngregyn, Llanfairfechan",
    "LL34": "Penmaenmawr, Dwygyfylchi",
    "LL35": "Aberdovey",
    "LL36": "Tywyn, Abergynolwyn, Bryncrug, Llanegryn, Rhoslefain",
    "LL37": "Llwyngwril",
    "LL38": "Fairbourne, Friog",
    "LL39": "Arthog",
    "LL40": "Dolgellau, Brithdir, Drws Y Nant, Ganllwyd, Llanelltyd, Llanfachreth, Rhydymain, Tabor",
    "LL41": "Blaenau Ffestiniog, Talwaenydd, Ffestiniog, Gellilydan, Maentwrog, Manod, Trawsfynydd",
    "LL42": "Barmouth, Llanaber",
    "LL43": "Talybont",
    "LL44": "Dyffryn Ardudwy",
    "LL45": "Llanbedr",
    "LL46": "Harlech, Llanfair",
    "LL47": "Talsarnau, Soar, Ynys",
    "LL48": "Penrhyndeudraeth, Llanfrothen, Minffordd",
    "LL49": "Porthmadog, Borth-y-Gest, Morfa Bychan, Penmorfa, Prenteg, Tremadog",
    "LL51": "Garndolbenmaen",
    "LL52": "Criccieth, Llanystumdwy, Pentrefelin, Rhoslan",
    "LL53": "Pwllheli, Efailnewydd, Llannor, Abererch, Chwilog, Llithfaen, Nefyn, Pistyll, Plas Gwyn, Y Ffor, Abersoch, Bwlchtocyn, Llanbedrog, Mynytho, Penrhos, Rhydyclafdy, Aberdaron, Boduan, Botwnnog, Edern, Morfa Nefyn, Sarn, Tudweiliog",
    "LL54": "Caernarfon, Clynnogfawr, Llanaelhaearn, Llandwrog, Pontllyfni, Saron, Trefor, Llanllyfni, Nantlle, Penygroes, Rhyd Ddu, Talysarn, Bontnewydd, Carmel, Dinas, Groeslon, Rhosgadfan, Rhostryfan",
    "LL55": "Caernarfon, Bethel, Bontnewydd, Caeathro, Brynrefail, Clwt-y-Bont, Deiniolen, Penisarwaun, Beddgelert, Ceunant, Cwm-y-Glo, Llanberis, Llanrug, Nant Gwynant, Nant Peris, Waunfawr",
    "LL56": "Y Felinheli",
    "LL57": "Bangor, Llanllechid, Rachub, Talybont, Bethesda, Glasinfryn, Llandygai, Mynydd Llandygai, Rhiwlas, Tregarth",
    "LL58": "Beaumaris, Llanddona, Llanfaes, Llangoed, Penmon",
    "LL59": "Menai Bridge, Llandegfan, Llansadwrn",
    "LL60": "Gaerwen, Llanddaniel, Llangaffo, Star",
    "LL61": "Llanfairpwllgwyngyll, Brynsiencyn, Dwyran, Newborough",
    "LL62": "Bodorgan, Bethel, Hermon, Llangristiolus, Malltraeth, Trefdraeth",
    "LL63": "Ty Croes, Aberffraw, Llanfaelog",
    "LL64": "Rhosneigr",
    "LL65": "Holyhead, Trearddur Bay, Four Mile Bridge, Rhoscolyn, Bodedern, Bryngwran, Caergeiliog, Llanynghenedl, Trefor, Valley, Gwalchmai, Llanddeusant, Llanfachraeth, Llanfaethlu, Llanfairynghornwy, Llanrhyddlad, Rhydwyn",
    "LL66": "Rhosgoch",
    "LL67": "Cemaes Bay, Tregele",
    "LL68": "Amlwch, Bull Bay, Carreglefn, Llanfechell, Rhosybol",
    "LL69": "Penysarn",
    "LL70": "Dulas",
    "LL71": "Llanerchymedd",
    "LL72": "Moelfre",
    "LL73": "Marianglas",
    "LL74": "Tyn-y-Gongl, Benllech",
    "LL75": "Pentraeth, Red Wharf Bay, Rhoscefnhir",
    "LL76": "Llanbedrgoch",
    "LL77": "Bodffordd, Rhostrehwfa, Talwrn, Llangefni",
    "LL78": "Brynteg",
    "LN1": "Lincoln city centre, Stow",
    "LN2": "Nettleham, Ermine & St Giles Estates",
    "LN3": "Lincoln",
    "LN4": "Branston, Canwick, Coningsby, Dunston, Heighington, Nocton, Washingborough, Timberland, Martin",
    "LN5": "Waddon",
    "LN6": "Doddington Park, Birchwood, North/South Hykeham",
    "LN7": "Market Rasen",
    "LN8": "Market Rasen",
    "LN9": "Horncastle",
    "LN10": "Woodhall Spa",
    "LN11": "Louth",
    "LN12": "Mablethorpe, Sutton-on-Sea",
    "LN13": "Alford",
    "LS1": "Leeds city centre",
    "LS2": "Leeds city centre, Woodhouse",
    "LS3": "Woodhouse",
    "LS4": "Burley, Kirkstall, Kirkstall Valley",
    "LS5": "Hawksworth, Kirkstall",
    "LS6": "Beckett Park, Headingley, Hyde Park, Meanwood, Woodhouse",
    "LS7": "Chapel Allerton, Chapeltown, Little London, Meanwood, Potternewton, Scott Hall, Sheepscar",
    "LS8": "Fearnville, Gipton, Gledhow, Harehills, Oakwood, Roundhay",
    "LS9": "Burmantofts, Cross Green, East End Park, Gipton, Harehills, Osmondthorpe, Richmond Hill",
    "LS10": "Belle Isle, Hunslet, Middleton, Stourton",
    "LS11": "Beeston, Beeston Hill, Cottingley, Holbeck",
    "LS12": "Armley, Farnley, New Farnley, Wortley",
    "LS13": "Bramley, Rodley, Swinnow",
    "LS14": "Killingbeck, Seacroft, Scarcroft, Swarcliffe, Thorner, Whinmoor",
    "LS15": "Austhorpe, Barwick-in-Elmet, Colton, Cross Gates, Halton, Halton Moor, Scholes, Whitkirk",
    "LS16": "Adel, Bramhope, Cookridge, Eccup, Far Headingley, Holt Park, Ireland Wood, Lawnswood, Moor Grange, Tinshill, Weetwood, West Park",
    "LS17": "Alwoodley, Bardsey, East Keswick, Eccup, Harewood, Moor Allerton, Moortown, Shadwell, Weardley, Wike",
    "LS18": "Horsforth",
    "LS19": "Rawdon, Yeadon",
    "LS20": "Guiseley",
    "LS21": "Arthington, Otley, Pool",
    "LS22": "Collingham, Linton, Wetherby",
    "LS23": "Boston Spa, Bramham, Clifford, Thorp Arch, Walton",
    "LS24": "Saxton, Stutton, Ulleskelf, Church Fenton, Tadcaster, Toulston",
    "LS25": "Aberford, Ferry Fryston, Garforth, Hillam, Kippax, Ledsham, Micklefield, Monk Fryston, Sherburn-in-Elmet",
    "LS26": "Great Preston, Methley, Mickletown, Oulton, Rothwell, Swillington, Woodlesford",
    "LS27": "Churwell, Gildersome, Morley",
    "LS28": "Calverley, Farsley, Pudsey, Stanningley",
    "LS29": "Addingham, Ben Rhydding, Burley in Wharfedale, Ilkley, Menston",
    "LS88": "Non-geographic",
    "LS98": "First Direct",
    "LS99": "Leeds",
    "LU1": "Luton (South), Aley Green, Caddington, Lower Woodside, Pepperstock, Skimpot, Slip End, Woodside",
    "LU2": "Luton (East), Chiltern Green, Cockernhoe, East Hyde, Lawrence End, Lilley, New Mill End, Peters Green, Tea Green, The Hyde, Wandon End, Wandon Green, Winch Hill",
    "LU3": "Luton (North), Lower Sundon, Streatley, Sundon",
    "LU4": "Luton (West), Chalton, Leagrave",
    "LU5": "Dunstable (East including Woodside Estate), Bidwell, Chalgrave, Fancott, Harlington, Houghton Regis (including Townsend Industrial Estate), Thorn, Toddington",
    "LU6": "Dunstable (West), Eaton Bray, Edlesborough, Holywell, Kensworth, Northall, Sewell, Studham, Totternhoe, Whipsnade",
    "LU7": "Leighton Buzzard, Bragenham, Briggington, Burcott, Cheddington, Chelmscote, Clipstone, Crafton, Cublington, Eggington, Great Billington, Grove, Heath and Reach, Hockliffe, Horton, Hollingdon, Ivinghoe, Ivinghoe Aston, Ledburn, Leedon, Little Billington, Mentmore, Pitstone, Slapton, Soulbury, Stanbridge, Stewkley, Stockgrove, Tebworth, Tilsworth, Wing, Wingfield",
    "LU95": "Non-geographic",
    "M1": "Piccadilly, Northern Quarter, Manchester City Centre",
    "M2": "Deansgate, Manchester City Centre",
    "M3": "Manchester City Centre, Blackfriars, Trinity",
    "M4": "Ancoats, Northern Quarter, Strangeways",
    "M5": "Ordsall, Seedley, Weaste, University",
    "M6": "Claremont, Irlams O' Th' Height, Charlestown, Whitlane",
    "M7": "Higher Broughton, Cheetwood, Lower Broughton, Kersal",
    "M8": "Crumpsall, Cheetham Hill",
    "M9": "Harpurhey, Blackley",
    "M10": "Moston, Newton Heath, Miles Platting",
    "M11": "Clayton, Openshaw, Beswick",
    "M12": "Ardwick, Longsight, Chorlton-on-Medlock",
    "M13": "Ardwick, Longsight, Chorlton-on-Medlock",
    "M14": "Fallowfield, Moss Side, Ladybarn, Rusholme",
    "M15": "Hulme, Manchester Science Park",
    "M16": "Firswood, Old Trafford, Whalley Range",
    "M17": "Trafford Park, The Trafford Centre",
    "M18": "Abbey Hey, Gorton",
    "M19": "Levenshulme, Burnage",
    "M20": "Didsbury, Withington",
    "M21": "Chorlton-cum-Hardy, Firswood",
    "M22": "Wythenshawe, Northenden, Sharston Industrial Area",
    "M23": "Baguley, Roundthorn Industrial Estate",
    "M24": "Middleton, Alkrington",
    "M25": "Prestwich",
    "M26": "Radcliffe, Stoneclough",
    "M27": "Swinton, Clifton, Pendlebury, Wardley, Agecroft",
    "M28": "Worsley, Walkden, Boothstown, Mosley Common, Wardley Industrial Estate",
    "M29": "Tyldesley, Astley",
    "M30": "Eccles",
    "M31": "Carrington, Partington",
    "M32": "Stretford",
    "M33": "Sale, Brooklands",
    "M34": "Denton, Audenshaw",
    "M35": "Failsworth, Castle Industrial Estate",
    "M38": "Little Hulton",
    "M40": "Collyhurst, Miles Platting, Moston, New Moston, Newton Heath",
    "M41": "Urmston",
    "M43": "Droylsden",
    "M44": "Irlam, Cadishead",
    "M45": "Whitefield",
    "M46": "Atherton",
    "M50": "Salford Quays",
    "M52": "Non-geographic",
    "M60": "Large user and PO Box",
    "M61": "Non-geographic",
    "M90": "Manchester Airport",
    "M99": "Manchester X, JD Williams",
    "ME1": "Rochester, Burham, Wouldham",
    "ME2": "Strood, Halling, Cuxton, Frindsbury",
    "ME3": "Rochester",
    "ME4": "Chatham",
    "ME5": "Walderslade, Blue Bell Hill, Luton",
    "ME6": "Snodland, West Malling",
    "ME7": "Gillingham",
    "ME8": "Rainham",
    "ME9": "Sittingbourne",
    "ME10": "Sittingbourne",
    "ME11": "Queenborough, Rushenden",
    "ME12": "Isle of Sheppey",
    "ME13": "Faversham",
    "ME14": "Maidstone, Bearsted, Grove Green",
    "ME15": "Bearsted, Downswood, Shepway, Senacre, Maidstone Town Centre, Loose, Mangravet, Parkwood",
    "ME16": "Barming, West Maidstone",
    "ME17": "Hollingbourne, Hucking, Harrietsham, Lenham, Boughton Monchelsea, Linton, Coxheath, Chart Sutton, Sutton Valence",
    "ME18": "Maidstone",
    "ME19": "West Malling",
    "ME20": "Aylesford",
    "ME99": "Non-geographic",
    "MK1": "Denbigh, Mount Farm",
    "MK2": "Brickfields, Central Bletchley, Fenny Stratford, Water Eaton",
    "MK3": "Church Green, Far Bletchley, Old Bletchley, West Bletchley",
    "MK4": "Emerson Valley, Furzton, Kingsmead, Shenley Brook End, Snelshall West, Tattenhoe, Tattenhoe Park, Westcroft, Whaddon, Woodhill",
    "MK5": "Crownhill, Elfield Park, Grange Farm, Oakhill, Knowlhill, Loughton, Medbourne, Shenley Brook End, Shenley Church End, Shenley Lodge, Shenley Wood",
    "MK6": "Ashland, Beanhill, Bleak Hall, Coffee Hall, Eaglestone, Fishermead, Great Woolstone, Leadenhall, Little Woolstone, Middleton/Milton Keynes Village, Netherfield, Oldbrook, Peartree Bridge, Redmoor, Springfield, Tinkers Bridge, Woughton on the Green, Woughton Park, Simpson, Winterhill",
    "MK7": "Caldecotte, Kents Hill, Tilbrook, Walton, Walton Hall, Wavendon",
    "MK8": "Crownhill, Grange Farm, Great Holm, Hazeley, Loughton, Loughton Lodge, Two Mile Ash, Wymbush",
    "MK9": "Central Milton Keynes, Campbell Park",
    "MK10": "Brinklow, Broughton, Kingston, Middleton (Milton Keynes Village), Monkston, Oakgrove",
    "MK11": "Fullers Slade, Galley Hill, Kiln Farm, Stony Stratford",
    "MK12": "Greenleys, Hodge Lea, Old Wolverton, Stacey Bushes, Wolverton, Wolverton Mill",
    "MK13": "Bancroft/Bancroft Park, Bluebridge, Bradwell and Bradwell Abbey, Bradwell Common, New Bradwell, Heelands, Stonebridge",
    "MK14": "Blakelands, Conniburrow, Downs Barn, Giffard Park, Great Linford, Linford Wood, Stantonbury, Neath Hill",
    "MK15": "Bolbeck Park, Downhead Park, Fox Milne, Newlands, Pennyland, Pineham, Northfield, Tongwell, Willen Park, Willen, Willen Lake",
    "MK16": "Astwood, Broughton, Chicheley, Filgrave, Gayhurst, Hardmead, Lathbury, Little Crawley, Moulsoe, North Crawley, Newport Pagnell, Sherington, Stoke Goldington, Tyringham",
    "MK17": "Aspley Guise, Battlesden, Bow Brickhill, Drayton Parslow, Eversholt, Great Brickhill, Great Horwood, Hulcote, Little Brickhill, Little Horwood, Milton Bryan, Mursley, Nash, Newton Longville, Potsgrove, Salford, Stoke Hammond, Swanbourne, Thornton, Tingrith, Wavendon, Whaddon, Woburn, Woburn Sands.",
    "MK18": "Addington, Adstock, Akeley, Barton Hartshorn, Botolph Claydon, Buckingham, Calvert, Chackmore, Chetwode, Dadford, Dunton, East Claydon, Finmere, Foscott, Gawcott, Granborough, Hillesden, Hoggeston, Leckhamstead, Lillingstone Dayrell, Lillingstone Lovell, Maids Moreton, Middle Claydon, Newton Purcell, Oxfordshire, North Marston, Padbury, Shalstone, Steeple Claydon, Tingewick, Thornborough, Water Stratford, Winslow",
    "MK19": "Beachampton, Calverton, Castlethorpe Cosgrove, Deanshanger, Hanslope, Haversham, Little Linford, Long Street, Old Stratford, Passenham, Upper Weald, Wicken",
    "MK40": "Biddenham, Castle, De Parys, Great Denham, Harpur, Queens Park",
    "MK41": "Brickhill, Clapham, De Parys, Goldington, Harpur, Newnham, Putnoe, Renhold, Salph End",
    "MK42": "Cauldwell, Elstow, Harrowden, Kempston, Kingsbrook, Shortstown, Wixams",
    "MK43": "Bourne End, Brogborough, Box End, Bromham, Cranfield, Carlton, Felmersham, Harrold, Husborne Crawley, Keeley Green, Kempston Rural, Lidlington, Lower Shelton, Marston Moretaine, Newton Blossomville Oakley, Odell, Pavenham, Radwell, Ridgmont, Stagsden, Stevington, Stewartby, Turvey, Upper Shelton, West End, Wood End, Wootton, Wootton Green",
    "MK44": "Begwary, Bletsoe, Blunham, Bolnhurst, Cardington, Chawston, Colesden, Colmworth, Cople, Duck's Cross, Great Barford, Keysoe, Keysoe Row, Kingsbrook, Knotting, Knotting Green, Little Staughton, Melchbourne, Milton Ernest, Mogerhanger, Newton Bromswold, Pertenhall, Ravensden, Riseley, Roxton, Sharnbrook, Souldrop, Swineshead, Thurleigh, Top End, Wilden, Willington, Wyboston, Yielden",
    "MK45": "Ampthill, Barton-le-Clay, Clophill, Cotton End, Flitton, Flitwick, Gravenhurst, Greenfield, Haynes, Haynes Church End, Herring's Green, Houghton Conquest, How End, Kempston Hardwick, Maulden, Millbrook, Pulloxhill, Sharpenhoe, Silsoe, Steppingley, Westoning, Wilstead",
    "MK46": "Clifton Reynes, Cold Brayfield, Emberton, Lavendon, Olney, Ravenstone, Warrington, Weston Underwood",
    "MK77": "Morgan Stanley Processing Centre",
    "MK98": "Non-geographic",
    "ML1": "Motherwell, Carfin, Cleland, Hareshaw, Holytown, New Stevenston, Newarthill, Newhouse",
    "ML2": "Wishaw, Bonkle, Bogside, Garrion Bridge, Morningside, Overtown, Waterloo",
    "ML3": "Hamilton, Ferniegair, Limekilnburn, Quarter",
    "ML4": "Bellshill, Orbiston, Mossend",
    "ML5": "Coatbridge, Annathill, Glenboig",
    "ML6": "Airdrie, Calderbank, Caldercruix, Chapelhall, Forrestfield, Gartness, Glenmavis, Greengairs, Plains, Riggend, Stand, Wattston",
    "ML7": "Shotts, Allanton, Eastfield, Harthill, Hartwood, Salsburgh",
    "ML8": "Carluke, Law",
    "ML9": "Netherburn, Stonehouse, Ashgill",
    "ML10": "Strathaven, Caldermill, Chapelton, Drumclog, Glassford, Sandford, Gilmourton, West Dykes Farm",
    "ML11": "Lesmahagow, Blackwood, Carluke, Kirkmuirhill",
    "ML12": "Biggar, Broughton, Symington, Wanlockhead",
    "N1": "Barnsbury, Canonbury, Kings Cross, Islington, Pentonville, De Beauvoir Town, Hoxton",
    "N1C": "Kings Cross",
    "N1P": "Non-geographic",
    "N2": "East Finchley, Fortis Green, Hampstead Garden Suburb",
    "N3": "Finchley, Church End, Finchley Central",
    "N4": "Finsbury Park, Manor House, Harringay, Stroud Green",
    "N5": "Highbury, Highbury Fields",
    "N6": "Highgate, Hampstead Heath",
    "N7": "Holloway, Barnsbury, Islington, Tufnell Park",
    "N8": "Hornsey, Crouch End, Harringay",
    "N9": "Lower Edmonton, Edmonton",
    "N10": "Muswell Hill",
    "N11": "New Southgate, Friern Barnet, Bounds Green, Arnos Grove",
    "N12": "North Finchley, Woodside Park",
    "N13": "Palmers Green",
    "N14": "Southgate, Oakwood, Arnos Grove",
    "N15": "West Green, Seven Sisters, Duckett's Green",
    "N16": "Stoke Newington, Stamford Hill, Shacklewell, Dalston, Newington Green",
    "N17": "Tottenham, South Tottenham",
    "N18": "Upper Edmonton, Edmonton",
    "N19": "Upper Holloway, Archway, Tufnell Park, Hornsey",
    "N20": "Whetstone, Totteridge, Oakleigh Park",
    "N21": "Winchmore Hill, Bush Hill, Grange Park",
    "N22": "Wood Green, Bounds Green, Bowes Park",
    "N81": "Non-geographic",
    "NE1": "Newcastle city centre",
    "NE2": "Jesmond, Spital Tongues",
    "NE3": "Gosforth, Fawdon, Kingston Park",
    "NE4": "Fenham, West Gate, Wingrove",
    "NE5": "Westerhope, Newcastle West",
    "NE6": "Walker, Byker, Heaton",
    "NE7": "High Heaton, Benton",
    "NE8": "Gateshead, Bensham",
    "NE9": "Low Fell, Springwell",
    "NE10": "Felling, Leam Lane, Pelaw",
    "NE11": "Dunston, Metro Centre, Team Valley, Kibblesworth",
    "NE12": "Killingworth",
    "NE13": "Airport, Wideopen, Dinnington",
    "NE15": "Lemington, Throckley, Newburn",
    "NE16": "Whickham, Sunniside, Burnopfield",
    "NE17": "Chopwell, Western Chopwell Wood",
    "NE18": "Stamfordham",
    "NE19": "Otterburn, Catcleugh",
    "NE20": "Ponteland",
    "NE21": "Blaydon, Winlaton",
    "NE22": "Bedlington, Hartford Bridge",
    "NE23": "Cramlington",
    "NE24": "Blyth, Newsham",
    "NE25": "Monkseaton, New Hartley, Holywell, Seaton Delaval",
    "NE26": "Whitley Bay, Seaton Sluice",
    "NE27": "Shiremoor, West Allotment, Backworth, Holystone, Murton Village",
    "NE28": "Battle Hill, Willington, Wallsend, North Tyne Tunnel",
    "NE29": "North Shields, Royal Quays, Billy Mill, New York",
    "NE30": "Marden, Tynemouth, Cullercoats",
    "NE31": "Hebburn",
    "NE32": "Jarrow, Fellgate, South Tyne Tunnel",
    "NE33": "South Shields Town Centre, Deans, High Shields",
    "NE34": "Harton, Horsley Hill, Marsden, Simonside, Brockley Whins",
    "NE35": "Boldon Colliery",
    "NE36": "East & West Boldon",
    "NE37": "Usworth, Sulgrave, Albany",
    "NE38": "Washington Town Centre, Oxclose, Fatfield",
    "NE39": "Rowlands Gill, High Spen, Hamsterley Mill, Eastern Chopwell Wood",
    "NE40": "Ryton, Crawcrook, Greenside",
    "NE41": "Wylam",
    "NE42": "West Wylam, Prudhoe, Ovingham, Ovington",
    "NE43": "Stocksfield, Painshawfield, Newton",
    "NE44": "Riding Mill, Broomhaugh",
    "NE45": "Corbridge, Dilston, Aydon, Halton",
    "NE46": "Hexham, Anick, Oakwood, Acomb",
    "NE47": "Allendale Town, Allenheads, Haydon Bridge, Bardon Mill",
    "NE48": "Bellingham, Kielder, Wark",
    "NE49": "Haltwhistle, Melkridge",
    "NE61": "Morpeth, Tranwell, Clifton Hepscott",
    "NE62": "Scotland Gate, Guidepost, Stakeford, West Sleekburn, Bomarsund",
    "NE63": "Ashington",
    "NE64": "Newbiggin-by-the-Sea",
    "NE65": "Rothbury, Longhorsley, Felton, Amble",
    "NE66": "Alnwick, Alnmouth, Embleton, Powburn",
    "NE67": "Benthall",
    "NE68": "Seahouses, North Sunderland",
    "NE69": "Bamburgh",
    "NE70": "Belford",
    "NE71": "Wooler",
    "NE82": "Non-geographic",
    "NE83": "Non-geographic",
    "NE85": "Non-geographic",
    "NE88": "Non-geographic",
    "NE89": "Washington",
    "NE92": "Non-geographic",
    "NE98": "Non-geographic",
    "NE99": "Non-geographic",
    "NG1": "Nottingham City Centre",
    "NG2": "Nottingham City Centre, Sneinton, The Meadows, West Bridgford",
    "NG3": "Carlton, St Ann's, Mapperley",
    "NG4": "Carlton, Gedling Village, Netherfield",
    "NG5": "Sherwood, Arnold, Bestwood, Carrington, Top Valley, Rise Park",
    "NG6": "Bestwood Village, Bulwell, Old Basford",
    "NG7": "New Basford, Forest Fields, Hyson Green, Radford, Lenton",
    "NG8": "Aspley, Wollaton, Whitemoor, Bilborough",
    "NG9": "Beeston, Stapleford, Lenton Abbey, Chilwell, Trowell",
    "NG10": "Long Eaton, Sawley, Sandiacre",
    "NG11": "Clifton, Ruddington, Gotham",
    "NG12": "Cotgrave, Radcliffe on Trent, Keyworth",
    "NG13": "Bingham, Whatton, Bottesford, Aslockton",
    "NG14": "Calverton, Lowdham",
    "NG15": "Hucknall, Ravenshead, Newstead",
    "NG16": "Kimberley, Eastwood, Nuthall, Langley Mill, Pinxton, Selston, Awsworth, Ironville, Jacksdale, Underwood, Brinsley, Watnall",
    "NG17": "Sutton-in-Ashfield, Kirkby-in-Ashfield, Stanton Hill, Skegby",
    "NG18": "Mansfield",
    "NG19": "Mansfield Woodhouse",
    "NG20": "Shirebrook, Market Warsop",
    "NG21": "Rainworth, Edwinstowe, Clipstone",
    "NG22": "Newark-on-Trent",
    "NG23": "Newark-on-Trent",
    "NG24": "Newark-on-Trent",
    "NG25": "Southwell",
    "NG31": "Grantham",
    "NG32": "Grantham, Croxton Kerrial, Sedgebrook",
    "NG33": "Grantham, Castle Bytham, Corby Glen",
    "NG34": "Sleaford",
    "NG70": "Special Business Reply Service",
    "NG80": "Experian",
    "NG90": "Boots the Chemists",
    "NN1": "Northampton",
    "NN2": "Kingsthorpe",
    "NN3": "Boothville, Moulton, Weston Favell",
    "NN4": "Brackmills, East Hunsbury, West Hunsbury, Great Houghton, Hardingstone, Wootton, Grange Park",
    "NN5": "Duston, New Duston",
    "NN6": "Brixworth, Cold Ashby, Earls Barton, Ecton, Guilsborough, Long Buckby Spratton, Sywell, West Haddon",
    "NN7": "Alderton, Blisworth, Bugbrooke, Castle Ashby, Cogenhoe, Dodford, Flore, Gayton, Grafton Regis, Hackleton, Harpole, Harlestone, Hartwell, Horton, Milton Malsor, Nether Heyford, Piddington, Preston Deanery, Quinton, Roade, Rothersthorpe, Stoke Bruerne, Weedon Bec, Yardley Gobion, Yardley Hastings, Weedon Bec",
    "NN8": "Wellingborough, Wilby",
    "NN9": "Wellingborough, Chelveston, Finedon, Great Harrowden, Irthlingborough, Little Harrowden, Raunds",
    "NN10": "Higham Ferrers, Rushden, Wymington",
    "NN11": "Braunston, Daventry, Greens Norton, Hinton, Moreton Pinkney, Welton",
    "NN12": "Abthorpe, Caswell, Greens Norton, Silverstone, Towcester, Weston",
    "NN13": "Brackley, Croughton, Farthinghoe, Hinton-in-the-Hedges",
    "NN14": "Broughton, Desborough, Geddington, Isham, Pytchley, Rothwell, Thorpe Malsor, Thrapston",
    "NN15": "Burton Latimer, Barton Seagrave",
    "NN16": "Kettering",
    "NN17": "Bulwick, Corby",
    "NN18": "Great Oakley, Little Oakley",
    "NN29": "Bozeat, Great Doddington, Irchester, Podington, Wollaston",
    "NN99": "Northampton town centre",
    "NPT": "Newport",
    "NP1": "Newbridge, Risca, Rogerstone",
    "NP2": "Blackwood, Ebbw Vale",
    "NP3": "Ebbw Vale, Arbertillery",
    "NP4": "Pontypool, Blaenavon, Little Mill, Glascoed",
    "NP5": "Monmouth, Usk",
    "NP6": "Chepstow",
    "NP7": "Abergavenny",
    "NP8": "Crickhowell",
    "NP9": "Newport",
    "NP10": "Western Newport, including Bassaleg, Duffryn, Rogerstone",
    "NP11": "North-western Newport, including: Abercarn, Crumlin, Cwmfelinfach, Risca, Ynysddu",
    "NP12": "Blackwood, Pontllanfraith, Wyllie",
    "NP13": "Abertillery",
    "NP15": "Usk, Raglan",
    "NP16": "Chepstow, Sedbury, Beachley",
    "NP18": "Eastern Newport, including Caerleon, Langstone, Llanwern, Nash, Llandegveth Underwood",
    "NP19": "Eastern and East Central Newport, Beechwood, Maindee, St. Julians, Ringland",
    "NP20": "Western and West Central Newport, Pill, Maesglas, Crindau, Malpas, Bettws",
    "NP22": "Tredegar, Rhymney",
    "NP23": "Ebbw Vale, Brynmawr",
    "NP24": "New Tredegar",
    "NP25": "Monmouth, Redbrook, Welsh Newton",
    "NP26": "Caldicot, Magor, Newport",
    "NP44": "Cwmbran",
    "NR1": "Thorpe Hamlet, Norwich city centre",
    "NR2": "Eaton",
    "NR3": "Norwich",
    "NR4": "Norwich",
    "NR5": "Norwich",
    "NR6": "Norwich",
    "NR7": "Norwich",
    "NR8": "Norwich",
    "NR9": "Norwich",
    "NR10": "Pettywell, Reepham, Hevingham, Stratton Strawless",
    "NR11": "Alby with Thwaite, Aldborough, Aylmerton, Aylsham, Banningham, Blickling, East Beckham, Little Barningham, Hanworth, North Barningham, Roughton, Felbrigg, Gimingham, Ingworth, Matlask, Mundesley, Southrepps, Wickmere",
    "NR12": "Bacton, Brumstead, Coltishall, East Ruston, Hickling, Ingham, Lessingham, Sloley, Stalham, Tunstead, Wroxham",
    "NR13": "Acle, Brundall, Reedham, Rackheath, Salhouse",
    "NR14": "Loddon, Poringland, Trowse",
    "NR15": "Long Stratton",
    "NR16": "Larling",
    "NR17": "Little Ellingham, Great Ellingham",
    "NR18": "Wymondham",
    "NR19": "Dereham",
    "NR20": "Bawdeswell, Bylaugh, Elsing, Foxley, Foulsham, Gressenhall, Guestwick, Whissonsett, Nethergate, North Elmham, Swanton Morley, Themelthorpe",
    "NR21": "Barsham, Binham, Fakenham, Fulmodeston, South Raynham, East Raynham, West Raynham, Hempton, Helhoughton, Hindringham, Gunthorpe, Tatterford, Toftrees",
    "NR22": "Walsingham",
    "NR23": "Quarles",
    "NR24": "Stody, Briningham, Brinton, Sharrington, Plumstead, Swanton Novers",
    "NR25": "Kelling, Baconsthorpe, Blakeney, Bodham, Cley next the Sea, Edgfield, Hempstead, High Kelling, HunworthLangham, Letheringsett, Glandford, Weybourne, West Beckham, Salthouse",
    "NR26": "Beeston Regis, West Runton, Upper Sheringham",
    "NR27": "Cromer, East Runton, Frogshall, Trimingham, Northrepps, Overstrand",
    "NR28": "Antingham, Crostwight, Honing, Knapton, Paston, Ridlington",
    "NR29": "Great Yarmouth",
    "NR30": "Caister-on-Sea",
    "NR31": "Gorleston, Bradwell",
    "NR32": "North Lowestoft",
    "NR33": "South Lowestoft",
    "NR34": "Beccles",
    "NR35": "Bungay, Topcroft, Flixton",
    "NR99": "Non-geographic",
    "NW1": "Euston, Camden Town, Somers Town, Primrose Hill, Gospel Oak, Lisson Grove",
    "NW1W": "Non-geographic, Euston",
    "NW2": "Cricklewood, Willesden, Neasden, Dollis Hill, Childs Hill",
    "NW3": "Hampstead, Chalk Farm, Primrose Hill, Swiss Cottage, Belsize Park, Gospel Oak, Frognal, Childs Hill",
    "NW4": "Brent Cross, Hendon",
    "NW5": "Kentish Town, Tufnell Park, Dartmouth Park, Gospel Oak",
    "NW6": "Kilburn, Brondesbury, West Hampstead, Queen's Park",
    "NW7": "Mill Hill, Arkley",
    "NW8": "St John's Wood, Primrose Hill, Lisson Grove",
    "NW9": "The Hyde, Colindale, Kingsbury, Queensbury, West Hendon",
    "NW10": "Willesden, Acton, Harlesden, Kensal Green, Neasden, Old Oak Common, Park Royal, Stonebridge, Brent Park",
    "NW11": "Golders Green, Hampstead Garden Suburb",
    "NW26": "Non-geographic",
    "OL1": "Chadderton, Higginshaw, Oldham",
    "OL2": "Heyside, Royton, Shaw",
    "OL3": "Delph, Denshaw, Diggle, Dobcross, Greenfield, Uppermill",
    "OL4": "Austerlands, Grasscroft, Grotton, Lees, Lydgate, Oldham, Scouthead, Springhead, Waterhead",
    "OL5": "Mossley, Mossley Cross",
    "OL6": "Ashton-under-Lyne",
    "OL7": "Ashton-under-Lyne",
    "OL8": "Bardsley, Oldham",
    "OL9": "Chadderton, Oldham, Westwood",
    "OL10": "Heywood",
    "OL11": "Ashworth, Rochdale, Castleton",
    "OL12": "Buckley, Facit, Great Howarth, Healy, Hurstead, Rochdale, Shawforth, Wardle, Whitworth",
    "OL13": "Bacup, Britannia, Stacksteads",
    "OL14": "Cornholme, Todmorden, Eastwood, Walsden",
    "OL15": "Littleborough, Shore, Smithybridge, Summit",
    "OL16": "Burnedge, Firgrove, Hurstead, Milnrow, Rochdale, Smallbridge, Thornham",
    "OL95": "Non-geographic",
    "OX1": "Central and South Oxford, Kennington",
    "OX2": "North and West Oxford, Botley, North Hinksey, Summertown, Wytham",
    "OX3": "North East Oxford, Headington, Marston, Elsfield",
    "OX4": "East Oxford, Cowley, Blackbird Leys, Sandford-on-Thames, Iffley",
    "OX5": "Kidlington",
    "OX6": "Bicester",
    "OX7": "Charlbury, Chadlington",
    "OX8": "Witney, Burford, Eynsham",
    "OX9": "Postcombe, Thame, Towersey, North Weston",
    "OX10": "Wallingford, Berinsfield, Cholsey, Dorchester",
    "OX11": "Didcot, Blewbury, Harwell",
    "OX12": "Wantage, Grove",
    "OX13": "Longworth, Sunningwell, Frilford, Cothill, Appleton",
    "OX14": "Abingdon, Appleford-on-Thames, Burcot, Clifton Hampden, Culham, Drayton, Little Wittenham, Long Wittenham, Radley, Milton, Sutton Courtenay",
    "OX15": "Bloxham, Drayton, Wroxton, North Newington, South Newington, Swalcliffe, Sibford Gower, Sibford Ferris, Barford St Michael, Barford St John, Deddington, Hempton, Broughton, Hornton, Horley, Balscote, Shenington, Alkerton, Wigginton, Milton, Hook Norton, Milcombe, Shutford, Epwell, Tadmarton, Swerford, Bodicote, Brailes, Edge Hill, Ratley, Upton",
    "OX16": "Banbury",
    "OX17": "Adderbury, Aynho, Arlescote, Chacombe, Charlton, Chipping Warden, Claydon, Cropredy, Culworth, Edgcote, Farnborough, Great Bourton, Hanwell, King's Sutton, Little Bourton, Lower Thorpe, Marston St Lawrence, Middleton Cheney, Mollington, Nethercote, Newbottle, Overthorpe, Shotteswell, Stuchbury, Sulgrave, Thenford, Thorpe Mandeville, Twyford, Wardington, Warkworth, Warmington, Williamscott",
    "OX18": "Alvescot, Asthall, Aston, Bampton, Barrington, Black Bourton, Bradwell, Brize Norton, Burford, Carterton, Chimney, Clanfield, Cote, Fulbrook, Grafton, Holwell, Lew, Little Clanfield, Radcot, Shilton, Signet, Swinbrook, Taynton, Upper Windrush, Upton, Weald, Westwell, Widford, Windrush",
    "OX20": "Woodstock, Bladon, Glympton, Kiddington, Woodleys, Wootton",
    "OX25": "Ambrosden, Arncott, Blackthorn, Caulcott, Duns Tew, Little Chesterton, Lower Heyford, Merton, Middle Aston, Middleton Stoney, North Aston, Piddington, Rousham, Somerton, Steeple Aston, Steeple Barton, Upper Heyford, Wendlebury, Weston-on-the-Green",
    "OX26": "Bicester, Chesterton, Launton",
    "OX27": "Ardley, Bainton, Baynards Green, Bucknell, Caversfield, Charndon, Fewcott, Fringford, Fritwell, Godington, Hardwick, Hethe, Marsh Gibbon, Newton Morrell, Poundon, Souldern, Stoke Lyne, Stratton Audley, Tusmore",
    "OX28": "Witney",
    "OX29": "Barnard Gate, Brighthampton, Church Hanborough, Combe, Crawley, Curbridge, Ducklington, Eynsham, Freeland, Hailey, Hardwick, High Cogges, Long Hanborough, New Yatt, Newbridge, North Leigh, East End (North Leigh), Old Shifford, Shifford, Standlake, Stanton Harcourt, Stonesfield, Sutton, Yelford",
    "OX33": "Wheatley, Forest Hill, Holton, Horspath, Horton-cum-Studley, Littleworth, Milton Common, Stanton St. John, Waterperry, Waterstock, Woodperry.",
    "OX39": "Chinnor",
    "OX44": "Chalgrove, Garsington, Toot Baldon",
    "OX49": "Watlington",
    "PA1": "Paisley (Central, East and Northeast), Ralston",
    "PA2": "Paisley (South)",
    "PA3": "Paisley (Northwest), Linwood",
    "PA4": "Renfrew, Inchinnan",
    "PA5": "Johnstone, Brookfield, Elderslie",
    "PA6": "Johnstone, Houston",
    "PA7": "Bishopton",
    "PA8": "Erskine",
    "PA9": "Johnstone, Howwood",
    "PA10": "Johnstone",
    "PA11": "Bridge Of Weir",
    "PA12": "Lochwinnoch, Newton of Belltrees",
    "PA13": "Kilmacolm",
    "PA14": "Port Glasgow, Langbank",
    "PA15": "Greenock",
    "PA16": "Greenock",
    "PA17": "Skelmorlie",
    "PA18": "Wemyss Bay",
    "PA19": "Gourock",
    "PA20": "Isle Of Bute",
    "PA21": "Tighnabruaich",
    "PA22": "Colintraive, Glendaruel",
    "PA23": "Dunoon",
    "PA24": "Cairndow",
    "PA25": "Cairndow",
    "PA26": "Cairndow",
    "PA27": "Cairndow",
    "PA28": "Campbeltown",
    "PA29": "Tarbert",
    "PA30": "Lochgilphead",
    "PA31": "Lochgilphead",
    "PA32": "Inveraray",
    "PA33": "Dalmally",
    "PA34": "Oban",
    "PA35": "Taynuilt",
    "PA36": "Bridge Of Orchy",
    "PA37": "Oban",
    "PA38": "Appin",
    "PA39": "Glencoe",
    "PA40": "Kinlochleven",
    "PA41": "Isle Of Gigha",
    "PA42": "Isle Of Islay",
    "PA43": "Isle Of Islay",
    "PA44": "Isle Of Islay",
    "PA45": "Isle Of Islay",
    "PA46": "Isle Of Islay",
    "PA47": "Isle Of Islay",
    "PA48": "Isle Of Islay",
    "PA49": "Isle Of Islay",
    "PA60": "Isle Of Jura",
    "PA61": "Isle Of Colonsay",
    "PA62": "Isle Of Mull",
    "PA63": "Isle Of Mull",
    "PA64": "Isle Of Mull",
    "PA65": "Isle Of Mull",
    "PA66": "Isle Of Mull",
    "PA67": "Isle Of Mull",
    "PA68": "Isle Of Mull",
    "PA69": "Isle Of Mull",
    "PA70": "Isle Of Mull",
    "PA71": "Isle Of Mull",
    "PA72": "Isle Of Mull",
    "PA73": "Ulva Ferry, Isle Of Mull",
    "PA74": "Isle Of Mull",
    "PA75": "Isle Of Mull",
    "PA76": "Isle Of Iona",
    "PA77": "Isle Of Tiree",
    "PA78": "Isle Of Coll",
    "PA80": "Morvern",
    "PA81": "Isle Of South Uist",
    "PA82": "Isle Of North Uist",
    "PA83": "Isle Of Harris",
    "PA84": "Isle of Scalpay",
    "PA85": "Isle of Lewis",
    "PA86": "Isle of Lewis",
    "PA87": "Isle of Lewis",
    "PA88": "Isle of North Uist",
    "PA98": "Glasgow Airport",
    "PE1": "Peterborough, Eastfield, Eastgate, Dogsthorpe, Fengate, Newark, Parnwell",
    "PE2": "Alwalton, Fletton, The Ortons, Stanground, Woodston",
    "PE3": "Peterborough, Bretton, Longthorpe, Netherton, Westwood, Ravensthorpe",
    "PE4": "Gunthorpe, Paston, Walton, Werrington",
    "PE5": "Ailsworth, Castor, Sutton",
    "PE6": "Baston, The Deepings, Crowland, Upton, Langtoft",
    "PE7": "Coates, Hampton, Folksworth, Yaxley, Whittlesey",
    "PE8": "Oundle, Achurch, Apethorpe, Armston, Ashton, Barnwell, Blatherwycke, Cotterstock, Elton, Fotheringhay, Hemington, Kings Cliffe, Lower Benefield, Luddington, Nassington, Polebrook, Sibson, Southwick, Stibbington, Tansor, Thornhaugh, Thurning, Upper Benefield, Wadenhoe, Wansford, Warmington, Water Newton, Wigsthorpe, Wittering, Woodnewton, Yarwell",
    "PE9": "Stamford, Ashton, Aunby, Bainton, Barholm, Barnack, Braceborough, Careby, Carlby, Collyweston, Duddington, Essendine, Easton-on-the-Hill, Great Casterton, Greatford, Ketton, Little Casterton, Newstead, Pickworth, Pilsgate, Ryhall, Southorpe, Tallington, Tickencote, Tinwell, Tixover, Uffington, Ufford, Wilsthorpe, Wothorpe",
    "PE10": "Bourne, Bulby, Cawthorpe, Dowsby, Dunsby, Dyke, Edenham, Grimsthorpe, Haconby, Hanthorpe, Keisby, Kirkby Underwood, Lound, Manthorpe, Morton, Rippingale, Scottlethorpe, Stainfield, Thurlby, Toft, Twenty, Witham on the Hill",
    "PE11": "Spalding, Deeping St. Nicholas, Donington, Gosberton, Hop Pole, Pinchbeck, Pode Hole, Quadring, Quadring Fen, Surfleet, Tongue End",
    "PE12": "Spalding, Cowbit, Gedney, Holbeach, Fleet, Holbeach, Holbeach Drove, Long Sutton, Little Sutton, Moulton, Sutton Bridge, Weston, Weston Hills, Whaplode",
    "PE13": "Wisbech, Guyhirn, Murrow, Parson Drove, Tydd St. Giles, Wisbech St. Mary",
    "PE14": "Wisbech, Elm, Emneth, Emneth Hungate, Marshland St. James, Outwell, Terrington St. John, Upwell, Walpole Highway, Walsoken, Welney, Tipps End, West Walton",
    "PE15": "March, Benwick, Doddington, Manea, Wimblington",
    "PE16": "Chatteris, Swingbrow",
    "PE17": "Warboys, Sawtry, St Ives",
    "PE18": "Huntingdon, Kimbolton, Spaldwick, Southoe",
    "PE19": "St Neots, Abbotsley, Buckden, Croxton, Diddington, Duloe, Eaton Ford, Eaton Socon, Eltisley, Eynesbury,",
    "PE20": "Algarkirk, Amber Hill, Bicker, Brothertoft, Fosdyke, Frampton, Kirton, Sutterton, Swineshead, Wigtoft",
    "PE21": "Fishtoft, Wyberton",
    "PE22": "Benington, Butterwick, Carrington, Eastville, Freiston, Friskney, Frithville, Langrick, Leverton, Mareham-le-Fen, Moorby, New Bolingbroke, Old Leake, Revesby, Stickney, Wrangle",
    "PE23": "Asgarby, Aswardby, Bag Enderby, Dalby, East Kirkby, Firsby, Halton Holegate, Harrington, Keal Cotes, Langton, Mavis Enderby, Monksthorpe, Partney, Sausthorpe, Somersby",
    "PE24": "Addlethorpe, Anderby, Anderby Creek, Ashington End, Bratoft, Burgh Le Marsh, Chapel St Leonards, Croft, Hogsthorpe, Orby, Wainfleet",
    "PE25": "Skegness, Croft, Ingoldmells, Roman Bank",
    "PE26": "Huntingdon, Ramsey, Bury, Ramsey Mereside, Upwood",
    "PE27": "St Ives, Holywell, Needingworth",
    "PE28": "Abbots Ripton, Alconbury, Alconbury Weston, Barham, Bluntisham, Brampton, Broughton, Buckworth, Bythorn, Catworth, Colne, Coppingford, Covington, Earith, Easton, Ellington, Fenstanton, Glatton, Grafham, Great Gidding, Great Stukeley, Hamerton, Hartford, Hemingford Abbots, Hemingford Grey, Hilton, Houghton, Keyston, Kimbolton, Kings Ripton, Leighton Bromswold, Little Gidding, Little Stukeley, Lower Dean, Molesworth, Old Hurst, Old Weston, Perry, Pidley, Sawtry, Somersham, Spaldwick, Stow Longa, Tilbrook, Upper Dean, Warboys, Wennington, Winwick, Wistow, Woodhurst, Woodwalton, Woolley, Wyton",
    "PE29": "Huntingdon, Godmanchester, Hartford, Hinchingbrooke",
    "PE30": "King's Lynn, North Wootton, South Wootton",
    "PE31": "Wolferton, Snettisham, Heacham, Brancaster, Burnham Thorpe",
    "PE32": "East Winch, Leziate, Middleton, Mileham, Narborough, East Lexham",
    "PE33": "Barton Bendish, Fincham",
    "PE34": "Clenchwarton, Islington, Stow Bardolph, Terrington St Clement, Tilney All Saints, Tilney St. Lawrence, Walpole Cross Keys, West Lynn, Wimbotsham",
    "PE35": "Sandringham",
    "PE36": "Hunstanton, Holme, Ringstead, Sedgeford, Thornham",
    "PE37": "Swaffham, Beachamwell, Cockley Cley, Necton, North Pickenham, South Pickenham",
    "PE38": "Downham Market",
    "PE99": "Non-geographic",
    "PH1": "Perth",
    "PH2": "Perth",
    "PH3": "Aberuthven",
    "PH4": "Blackford",
    "PH5": "Crieff",
    "PH6": "Crieff",
    "PH7": "Crieff",
    "PH8": "Dunkeld",
    "PH9": "Pitlochry",
    "PH10": "Blairgowrie",
    "PH11": "Alyth",
    "PH12": "Meigle, Newtyle",
    "PH13": "Coupar Angus",
    "PH14": "Perth",
    "PH15": "Aberfeldy",
    "PH16": "Pitlochry",
    "PH17": "Pitlochry",
    "PH18": "Pitlochry",
    "PH19": "Dalwhinnie",
    "PH20": "Newtonmore, Kinloch Laggan, Laggan",
    "PH21": "Kingussie, Insh, Kincraig",
    "PH22": "Aviemore",
    "PH23": "Carrbridge, Bogroy",
    "PH24": "Boat of Garten",
    "PH25": "Nethy Bridge",
    "PH26": "Grantown-on-Spey, Advie, Cromdale, Dulnain Bridge",
    "PH30": "Corrour",
    "PH31": "Roy Bridge, Murlaggan, Tulloch",
    "PH32": "Fort Augustus, Auchterawe",
    "PH33": "Fort William",
    "PH34": "Spean Bridge",
    "PH35": "Invergarry",
    "PH36": "Acharacle",
    "PH37": "Glenfinnan, Polloch",
    "PH38": "Lochailort, Glenuig, Kinlochmoidart",
    "PH39": "Arisaig",
    "PH40": "Morar",
    "PH41": "Knoydart",
    "PH42": "Isle Of Eigg",
    "PH43": "Isle Of Rum",
    "PH44": "Isle Of Canna",
    "PH49": "Ballachulish",
    "PH50": "Kinlochleven",
    "PL1": "Plymouth, Devonport, The Hoe, Millbridge, Stoke, Stonehouse",
    "PL2": "Beacon Park, Ford, Keyham, North Prospect, Pennycross, Home Park",
    "PL3": "Efford, Hartley, Laira, Mannamead, Milehouse, Peverell, Higher Compton",
    "PL4": "Barbican, Lipson, Mount Gould, Mutley, Prince Rock, St. Judes",
    "PL5": "Crownhill, Ernesettle, Honicknowle, Whitleigh, St. Budeaux, Tamerton Foliot",
    "PL6": "Derriford, Eggbuckland, Estover, Leigham, Roborough, Southway",
    "PL7": "Plympton, Sparkwell",
    "PL8": "Brixton, Newton Ferrers, Noss Mayo, Yealmpton",
    "PL9": "Plymstock, Heybrook Bay, Mount Batten, Wembury",
    "PL10": "Cawsand, Cremyll, Fort Picklecombe, Freathy, Kingsand, Millbrook",
    "PL11": "Torpoint, Antony, Crafthole, Downderry, Seaton, Sheviock, St John",
    "PL12": "Saltash, Hatt, Landrake, St Germans, Tideford, Trerulefoot",
    "PL13": "Looe, Lansallos, Polperro",
    "PL14": "Liskeard, Dobwalls, Doublebois, Minions, St Cleer, St Ive, St Neot",
    "PL15": "Launceston, Bolventor, Lezant, South Petherwin, Treneglos",
    "PL16": "Lifton, Broadwoodwidger, Marystow",
    "PL17": "Callington, Ashton, Bray Shop, Kelly Bray, South Hill",
    "PL18": "Calstock, Gunnislake, Albaston",
    "PL19": "Tavistock, Bradstone, Mary Tavy, Morwellham, Peter Tavy, Whitchurch",
    "PL20": "Yelverton, Bellever, Crapstone, Horrabridge, Postbridge, Princetown, Two Bridges",
    "PL21": "Ivybridge, Brownston, Cornwood, Ermington, Modbury, Ugborough",
    "PL22": "Lostwithiel, Boconnoc, Lanlivery, Lerryn, St Veep",
    "PL23": "Fowey, Bodinnick, Golant, Polruan",
    "PL24": "Par, Polkerris, St Blazey, Tywardreath",
    "PL25": "St Austell, Carlyon Bay, Charlestown, Trewoon",
    "PL26": "Gorran Haven, Foxhole, Mevagissey, St Dennis, St Ewe, Sticker",
    "PL27": "Wadebridge, Little Petherick, Polzeath, Rock, St Eval, St Minver, Trebetherick",
    "PL28": "Padstow, Crugmeer, Porthcothan, St Merryn, Trevone, Treyarnon",
    "PL29": "Port Isaac, Port Gaverne, Port Quin, St Endellion, Trelights",
    "PL30": "Blisland, Lanivet, Luxulyan, Nanstallon, St Kew, St Mabyn, Temple, Withiel",
    "PL31": "Bodmin, Dunmere",
    "PL32": "Camelford, Davidstow, Lanteglos-by-Camelford, Otterham, Tresinney",
    "PL33": "Delabole, Trebarwith, Westdowns",
    "PL34": "Tintagel, Bossiney, Trewarmett",
    "PL35": "Boscastle, Lesnewth, Trevalga",
    "PL95": "Non-geographic",
    "PO1": "Portsmouth, Fratton",
    "PO2": "Portsmouth, Hilsea, North End",
    "PO3": "Portsmouth, Hilsea",
    "PO4": "Southsea",
    "PO5": "Southsea",
    "PO6": "Cosham",
    "PO7": "Waterlooville, Denmead, Purbrook",
    "PO8": "Clanfield, Cowplain, Horndean",
    "PO9": "Havant, Rowland's Castle",
    "PO10": "Emsworth",
    "PO11": "Hayling Island",
    "PO12": "Gosport, Alverstoke, Hardway",
    "PO13": "Gosport, Lee-on-the-Solent",
    "PO14": "Fareham, Hill Head, Stubbington, Titchfield",
    "PO15": "Fareham, Titchfield, Whiteley",
    "PO16": "Portchester",
    "PO17": "Wickham",
    "PO18": "Chichester",
    "PO19": "Chichester",
    "PO20": "Chichester",
    "PO21": "Bognor Regis",
    "PO22": "Bognor Regis",
    "PO24": "Non-geographic",
    "PO30": "Newport",
    "PO31": "Cowes, Gurnard",
    "PO32": "East Cowes, Whippingham",
    "PO33": "Ryde",
    "PO34": "Seaview",
    "PO35": "Bembridge, Whitecliff Bay",
    "PO36": "Sandown",
    "PO37": "Shanklin",
    "PO38": "Ventnor",
    "PO39": "Totland Bay, Alum Bay",
    "PO40": "Freshwater",
    "PO41": "Yarmouth",
    "PR0": "Non-geographic",
    "PR1": "City Centre, Avenham, Broadgate, Deepdale, Fishwick, St. Matthew's, Penwortham",
    "PR2": "Ashton On Ribble, Brookfield, Cadley, Fulwood, Grimsargh, Haighton, Ingol, Larches, Lea, Ribbleton, Riversway, Sharoe Green, Tanterton",
    "PR3": "Barnacre-with-Bonds, Barton, Bilsborrow, Bonds, Bowgreave, Broughton, Calder Vale, Chipping, Garstang, Goosnargh, Great Eccleston, Little Eccleston, Longridge, Myerscough, Oakenclough, Pilling, Ribchester, St Michael's On Wyre, Scorton, Whittingham",
    "PR4": "Becconsall, Catforth, Clifton, Cottam, Eaves, Elswick, Freckleton, Hesketh Bank, Hutton, Inskip, Kirkham, Longton, Much Hoole, New Longton, Newton, Tarleton, Thistleton, Walmer Bridge, Warton, Wesham, Woodplumpton, Wrea Green",
    "PR5": "Bamber Bridge, Coupe Green, Gregson Lane, Higher Walton, Hoghton, Lostock Hall, Riley Green, Samlesbury, Walton-le-Dale, Walton Park, Walton Summit",
    "PR6": "Abbey Village, Adlington, Anderton, Anglezarke, Astley Village, Brindle, Brinscall, Clayton-le-Woods, Cuerden, Heapey, Heath Charnock, Wheelton, White Coppice, Whittle-le-Woods, Withnell",
    "PR7": "Adlington, Buckshaw Village, Charnock Richard, Coppull, Eccleston, Euxton, Heath Charnock, Heskin",
    "PR8": "Ainsdale, Birkdale, Blowick",
    "PR9": "Banks, Churchtown, Crossens, Marshside",
    "PR11": "Non-geographic",
    "PR25": "Leyland, Clayton-le-Woods, Farington",
    "PR26": "Leyland, Bretherton, Croston, Farington Moss, Moss Side, Ulnes Walton",
    "RG1": "Reading",
    "RG2": "Whitley",
    "RG3": "Tilehurst, Burghfield",
    "RG4": "Caversham",
    "RG5": "Woodley",
    "RG6": "Earley",
    "RG7": "Aldermaston, Bradfield, Burghfield Common, Silchester, Mortimer, Theale",
    "RG8": "Goring, Streatley",
    "RG9": "Hambleden, Rotherfield Peppard, Turville, Turville Heath, Skirmett, Rockwell End, Mill End, Fawley, Fingest, Frieth, Greenlands, Nettlebed, Shiplake",
    "RG10": "Charvil, Hurst, Ruscombe, Twyford, Wargrave",
    "RG11": "Wokingham, Winnersh, Crowthorne",
    "RG12": "Bracknell, Bullbrook, Wick Hill",
    "RG13": "Newbury, Thatcham",
    "RG14": "Newbury",
    "RG15": "Newbury, Kintbury, Headley, Kingsclere, Woolton Hill",
    "RG16": "Chieveley, Compton, Lambourn",
    "RG17": "Hungerford",
    "RG18": "Hampstead Norreys, Hermitage, Yattendon",
    "RG19": "Thatcham",
    "RG20": "Chieveley, Compton",
    "RG21": "Basingstoke Town Centre, Eastrop, Black Dam",
    "RG22": "South Ham, Brighton Hill, Kempshott, Buckskin",
    "RG23": "Winklebury, Rooksdown, Oakley",
    "RG24": "Popley, Chineham, Sherborne St John",
    "RG25": "Cliddesden, Dummer, Overton",
    "RG26": "Baughurst, Bramley, Brimpton Common, Pamber End, Pamber Heath, Tadley",
    "RG27": "Hook, Sherfield-on-Loddon, Stratfield Turgis, Turgis Green",
    "RG28": "Whitchurch",
    "RG29": "Hook",
    "RG30": "Tilehurst, Purley-on-Thames, Southcote",
    "RG31": "Calcot Row, Tilehurst",
    "RG40": "Wokingham, Finchampstead, Barkham",
    "RG41": "Winnersh, Woosehill, Emmbrook, Sindlesham",
    "RG42": "Binfield, Newell Green, Priestwood, Warfield, Winkfield Row",
    "RG45": "Crowthorne, Ravenswood",
    "RH1": "Redhill, Bletchingley, Nutfield, Merstham, South Nutfield",
    "RH2": "Reigate",
    "RH3": "Betchworth, Brockham, Buckland, Strood Green",
    "RH4": "Dorking",
    "RH5": "Capel, Westhumble, Mickleham, Ockley",
    "RH6": "Horley, Gatwick Airport",
    "RH7": "Lingfield, Dormansland",
    "RH8": "Oxted",
    "RH9": "Godstone, South Godstone",
    "RH10": "Copthorne, Crawley Down, Domewood, Furnace Green, Northgate, Maidenbower, Pound Hill, Rowfant, Southgate, Three Bridges, Tilgate",
    "RH11": "Bewbush, Broadfield, Gossops Green, Ifield, Langley Green, Lowfield Heath, Pease Pottage, Southgate West, West Green",
    "RH12": "Horsham, Broadbridge Heath, Rusper, Rudgwick, Warnham",
    "RH13": "Barns Green, Cowfold, Mannings Heath, Partridge Green, Slinfold, Southwater, West Grinstead",
    "RH14": "Billingshurst, Ifold, Kirdford, Loxwood, Plaistow, Wisborough Green",
    "RH15": "Burgess Hill, Ditchling Common",
    "RH16": "Haywards Heath, Lindfield",
    "RH17": "Ansty, Ardingly, Balcombe, Bolney, Chelwood Gate, Cuckfield, Danehill, Handcross, Hickstead, Horsted Keynes, Wivelsfield, Wivelsfield Green",
    "RH18": "Forest Row",
    "RH19": "East Grinstead, Ashurst Wood, Dormans Park, Felbridge, Felcourt, Sharpthorne, West Hoathly",
    "RH20": "Pulborough, Ashington, Storrington, West Chiltington, West Chiltington Common",
    "RH77": "Non-geographic",
    "RM1": "Romford",
    "RM2": "Gidea Park, Heath Park",
    "RM3": "Harold Wood, Harold Hill, Noak Hill",
    "RM4": "Havering-atte-Bower, Abridge, Stapleford Abbotts, Noak Hill, Navestock, Stapleford Tawney, Stapleford Aerodrome",
    "RM5": "Collier Row",
    "RM6": "Chadwell Heath, Little Heath",
    "RM7": "Rush Green, Mawneys, Romford",
    "RM8": "Dagenham, Becontree Heath",
    "RM9": "Dagenham, Becontree",
    "RM10": "Dagenham",
    "RM11": "Hornchurch, Emerson Park, Ardleigh Green",
    "RM12": "Hornchurch, Elm Park",
    "RM13": "Rainham, South Hornchurch, Wennington",
    "RM14": "Upminster, Cranham, North Ockendon, Bulphan",
    "RM15": "South Ockendon, Aveley",
    "RM16": "Chafford Hundred, Chadwell St Mary, North Stifford, Orsett",
    "RM17": "Grays, Badgers Dene",
    "RM18": "Tilbury, East Tilbury, West Tilbury",
    "RM19": "Purfleet",
    "RM20": "West Thurrock, South Stifford",
    "RM50": "Non-geographic",
    "S1": "Sheffield City Centre",
    "S2": "Arbourthorne, Heeley, Highfield, Manor, Norfolk Park, Wybourn, Park Hill",
    "S3": "Broomhall, Burngreave, Neepsend, Netherthorpe",
    "S4": "Grimesthorpe, Pitsmoor",
    "S5": "Firth Park, Shirecliffe, Shiregreen, Southey Green, Parson Cross, Longley, Fir Vale, Wadsley Bridge",
    "S6": "Bradfield, Hillsborough, Malin Bridge, Stannington, Upperthorpe, Walkley, Fox Hill",
    "S7": "Abbeydale, Nether Edge, Millhouses, Carter Knowle",
    "S8": "Batemoor, Beauchief, Greenhill, Jordanthorpe, Lowedges, Meersbrook, Norton, Norton Lees, Woodseats",
    "S9": "Attercliffe, Brightside, Darnall, Meadowhall, Tinsley, Wincobank",
    "S10": "Broomhill, Broomhall, Crookes, Crookesmoor, Fulwood, Ranmoor, Crosspool",
    "S11": "Ecclesall, Endcliffe Park, Ecclesall Road, Greystones, Hunter's Bar, Millhouses, Sharrow Vale, Nether Edge",
    "S12": "Birley, Gleadless, Gleadless Townend, Hackenthorpe, Intake, Ridgeway",
    "S13": "Handsworth, Richmond, Woodhouse",
    "S14": "Gleadless Valley",
    "S17": "Dore, Totley, Bradway",
    "S18": "Coal Aston, Dronfield, Dronfield Woodhouse, Holmesfield, Unstone",
    "S19": "Hackenthorpe, Beighton, Mosborough",
    "S20": "Westfield, Waterthope, Sothall, Donetsk Way, Owlthorpe, Beighton, Crystal Peaks, Halfway, Mosborough",
    "S21": "Eckington, Killamarsh, Renishaw, Spinkhill",
    "S25": "Anston, Dinnington, Laughton-en-le-Morthen",
    "S26": "Aston, Aughton, Harthill, Kiveton Park, Swallownest, Todwick, Wales",
    "S30": "Penistone, Stocksbridge, Chapeltown, Hope Valley",
    "S31": "Aughton, Dinnington, Todwick, Wales, Killamarsh, Eddington",
    "S32": "Calver, Eyam, Grindleford, Hathersage, Stoney Middleton",
    "S33": "Bamford, Bradwell, Castleton, Edale, Hope, Yorkshire Bridge",
    "S35": "Chapeltown, Ecclesfield, Grenoside, High Green, Oughtibridge, Thurgoland, Wharncliffe Side, Wortley",
    "S36": "Penistone, Thurlstone, Millhouse Green, Oxspring, Stocksbridge, Deepcar",
    "S40": "Central and West Chesterfield, Brampton",
    "S41": "Hasland, North Chesterfield, Old Whittington",
    "S42": "Holymoorside, Grassmoor, North Wingfield, Tupton, Wingerworth",
    "S43": "Barlborough, Clowne, Inkersall Green, Staveley",
    "S44": "Bolsover, Calow, Sutton-cum-Duckmanton, Scarcliffe",
    "S45": "Ashover, Clay Cross, Pilsley",
    "S49": "Bulk users",
    "S60": "Brinsworth, Catcliffe, Central Rotherham, Masbrough, Moorgate, Treeton, Whiston",
    "S61": "Greasbrough, Kimberworth, Rockingham, Thorpe Hesley",
    "S62": "Rawmarsh, Wentworth",
    "S63": "Bolton-on-Dearne, Goldthorpe, Thurnscoe, Wath-on-Dearne, West Melton",
    "S64": "Kilnhurst, Mexborough, Swinton",
    "S65": "Herringthorpe, Ravenfield, Thrybergh",
    "S66": "Bramley Hellaby, Maltby, Thurcroft, Wickersley, Braithwell",
    "S69": "Non-geographic",
    "S70": "Central Barnsley, Birdwell, Stairfoot, Worsborough",
    "S71": "Ardsley, Athersley, Monk Bretton, Royston",
    "S72": "Brierley, Cudworth, Grimethorpe, Shafton, South Hiendley",
    "S73": "Brampton, Darfield, Wombwell, Hemingfield",
    "S74": "Elsecar, Hoyland, Jump",
    "S75": "Barugh Green, Cawthorne, Darton, Dodworth, Gawber, Mapplewell, Pogmoor, Staincross, Wilthorpe",
    "S80": "Creswell, South Worksop, Whitwell",
    "S81": "Blyth, Carlton-in-Lindrick, Langold, North Worksop, Shireoaks, Woodsetts",
    "S94": "Non-geographic",
    "S95": "Non-geographic",
    "S96": "Bulk users",
    "S97": "Bulk users",
    "S98": "Bulk users",
    "S99": "Non-geographic",
    "SA1": "Bonymaen, Copper Quarter, Crymlyn Burrows, Danygraig, Greenhill, Hafod, Jersey Marine, Landore, Maritime Quarter, Mayhill, Mount Pleasant, Pentrechwyth, Port Tennant, St. Thomas, City Centre, Townhill, Waun Wen, Winch Wen, Winch Wen Industrial Estate",
    "SA2": "Brynmill, Cockett, Derwen Fawr, Dunvant, Fairwood, Glanmor, Hendrefoilan, Hendrefoilan Student Village, Ilston, Killay, Sketty, Sketty Park, Singleton Park (University Campus), Tycoch, Uplands, Upper Killay",
    "SA3": "Bishopston, Blackpill, Caswell, Clyne, Langland, Mumbles, Oystermouth, Southgate, South Gower, West Cross",
    "SA4": "Blue Anchor, Gorseinon, Gowerton, Hendy (Carmarthenshire), Loughor, North Gower, Penllergaer, Penclawdd, Pontarddulais, Three Crosses",
    "SA5": "Blaenymaes, Cwmdu, Cwmdu Industrial Estate, Felindre, Fforestfach, Gendros, Llangyfelach, Llewitha, Manselton, Penlan, Portmead, Tirdeunaw, Waunarlwydd",
    "SA6": "Clase, Cwmrhydyceirw, Clydach, Morriston, Plasmarl, Plasmarl Industrial Estate, Swansea Enterprise Park, Ynystawe, Ynysforgan",
    "SA7": "Birchgrove, Glais, Llansamlet, Swansea Enterprise Park, Talycoppa, Trallwn",
    "SA8": "Alltwen, Cwmtawe, Pontardawe, Rhos, Rhyd-y-fro, Trebanos, Ynysmeudwy",
    "SA9": "Abercraf, Cwmllynfell, Cwmtwrch, Lower Cwmtwrch, Rhiwfawr, Upper Cwmtwrch, Ystalyfera, Ystradgynlais",
    "SA10": "Aberdulais, Bryncoch, Cadoxton, Caewern, Coed Darcy, Crynant, Jersey Marine, Llandarcy, Neath Abbey, Onllwyn, Seven Sisters, Skewen",
    "SA11": "Blaengwrach, Briton Ferry, Cimla, Glynneath, Neath town centre, Resolven, Tonmawr, Tonna",
    "SA12": "Aberafan, Aberavon, Baglan, Cwmafan, Port Talbot town centre, Sandfields",
    "SA13": "Bryn, Cymmer, Margam, Taibach",
    "SA14": "Bynea, Dafen, Llanelli town centre",
    "SA15": "Felinfoel, Pontyates",
    "SA16": "Burry Port, Pembrey",
    "SA17": "Ferryside, Kidwelly, Llansaint, Mynyddygarreg, Carway",
    "SA18": "Ammanford town centre, Betws, Gwaun-Cae-Gurwen, Lower Brynamman",
    "SA19": "Llandeilo, Llangadog, Llanwrda",
    "SA20": "Llandovery",
    "SA31": "Carmarthen town centre",
    "SA32": "Nantgaredig, Dryslwyn",
    "SA33": "Bancyfelin, Pendine, Blaenycoed",
    "SA34": "Whitland",
    "SA35": "Llanfyrnach",
    "SA36": "Glogue, Hermon",
    "SA37": "Boncath",
    "SA38": "Newcastle Emlyn",
    "SA39": "Pencader",
    "SA40": "Llanybydder",
    "SA41": "Crymych",
    "SA42": "Newport",
    "SA43": "Cardigan, New Quay",
    "SA44": "Llandysul",
    "SA45": "New Quay",
    "SA46": "Aberaeron",
    "SA47": "Llanarth",
    "SA48": "Lampeter",
    "SA61": "Haverfordwest town centre, Merlin's Bridge",
    "SA62": "Haverfordwest",
    "SA63": "Clarbeston Road",
    "SA64": "Goodwick",
    "SA65": "Fishguard",
    "SA66": "Clynderwen",
    "SA67": "Narberth",
    "SA68": "Kilgetty",
    "SA69": "Saundersfoot",
    "SA70": "Tenby",
    "SA71": "Pembroke",
    "SA72": "Pembroke Dock",
    "SA73": "Milford Haven",
    "SA80": "Swansea",
    "SA99": "Non-geographic",
    "SE1": "Bankside, South Bank, Southwark, Bermondsey, Vauxhall",
    "SE1P": "Non-geographic",
    "SE2": "Abbey Wood, West Heath, Crossness, Thamesmead",
    "SE3": "Blackheath, Kidbrooke, Westcombe Park",
    "SE4": "Brockley, Crofton Park, Western Ladywell, Honor Oak",
    "SE5": "Camberwell, Denmark Hill, Southampton Way Estate, Peckham",
    "SE6": "Bellingham, Catford, Hither Green",
    "SE7": "Charlton",
    "SE8": "Deptford, Evelyn",
    "SE9": "Eltham, Mottingham, New Eltham, Falconwood, Chinbrook, Longlands, Kidbrooke",
    "SE10": "Greenwich, Maze Hill, Greenwich Peninsula",
    "SE11": "Kennington, Vauxhall",
    "SE12": "Lee, Grove Park, Chinbrook, Hither Green, Eltham, Horn Park, Blackheath",
    "SE13": "Lewisham, Hither Green, Ladywell",
    "SE14": "New Cross, New Cross Gate",
    "SE15": "Nunhead, Peckham",
    "SE16": "Rotherhithe, Bermondsey, Surrey Quays",
    "SE17": "Walworth, Newington",
    "SE18": "Plumstead, Woolwich",
    "SE19": "Upper Norwood, Crystal Palace, Crown Point, Norwood",
    "SE20": "Anerley, Crystal Palace, Penge, Beckenham",
    "SE21": "Dulwich, Dulwich Village, West Dulwich, Tulse Hill, Sydenham Hill",
    "SE22": "East Dulwich, Peckham Rye, Loughborough Junction, Herne Hill",
    "SE23": "Forest Hill, Honor Oak, Crofton Park",
    "SE24": "Herne Hill, Tulse Hill",
    "SE25": "South Norwood, Selhurst",
    "SE26": "Sydenham, Crystal Palace",
    "SE27": "West Norwood, Gipsy Hill, Norwood",
    "SE28": "Thamesmead",
    "SE99": "Non-geographic",
    "SG1": "North Stevenage",
    "SG2": "South Stevenage, Walkern, Ardeley",
    "SG3": "Knebworth, Woolmer Green, Datchworth",
    "SG4": "Breachwood Green, Kimpton, King's Walden, Graveley, Little Wymondley, Great Wymondley",
    "SG5": "Apsley End, Higham Gobion, Offley, Little Offley, Pegsdon, Stotfold, Shillington, Pirton",
    "SG6": "Letchworth",
    "SG7": "Baldock",
    "SG8": "Royston",
    "SG9": "Buntingford, Westmill",
    "SG10": "Much Hadham",
    "SG11": "Albury, Braughing, Bury Green, Colliers End, Haultwick, High Cross, Little Hadham, Nasty, Patmore Heath, Puckeridge, Standon, Standon Green End",
    "SG12": "Ware",
    "SG13": "Hertford, Newgate Street Village, Bayford, Brickendon",
    "SG14": "Hertford",
    "SG15": "Arlesey",
    "SG16": "Henlow, Henlow Camp, Lower Stondon, Upper Stondon",
    "SG17": "Shefford",
    "SG18": "Biggleswade, Budna, Hatch, Langford, Lower Caldecote, Northill, Upper Caldecote",
    "SG19": "Potton, Sandy",
    "SK1": "Stockport",
    "SK2": "Stockport",
    "SK3": "Stockport, Davenport, Edgeley, Adswood, Bridgehall",
    "SK4": "Stockport, Four Heatons",
    "SK5": "Stockport, Brinnington, Reddish",
    "SK6": "Bredbury, Romiley, Woodley, Marple",
    "SK7": "Bramhall, Hazel Grove, Woodford",
    "SK8": "Cheadle, Cheadle Hulme, Gatley, Heald Green",
    "SK9": "Alderley Edge, Wilmslow, Handforth, Styal",
    "SK10": "Macclesfield, Bollington, Pott Shrigley, Prestbury, Rainow",
    "SK11": "Macclesfield",
    "SK12": "Disley, Poynton",
    "SK13": "Glossop, Hadfield",
    "SK14": "Hyde, Broadbottom, Gee Cross, Hollingworth, Mottram",
    "SK15": "Stalybridge, Carrbrook, Heyrod, Matley, Millbrook",
    "SK16": "Dukinfield",
    "SK17": "Buxton, Tideswell, Hartington, Longnor, Chelmorton",
    "SK22": "Birch Vale, Hayfield, Little Hayfield, New Mills, Rowarth",
    "SK23": "Buxworth, Chapel-en-le-Frith, Chinley, Combs, Furness Vale, Kettleshulme, Whaley Bridge",
    "SL0": "Iver, Iver Heath, Richings Park, Thorney",
    "SL1": "Burnham, Littleworth, Salt Hill, Slough, Upton, Cippenham",
    "SL2": "Farnham Common, Farnham Royal, Hedgerley, Stoke Poges",
    "SL3": "Colnbrook, Datchet, Fulmer, George Green, Horton, Langley, Poyle",
    "SL4": "Boveney, Clewer, Cranbourne, Dorney, Eton, Eton Wick, Fifield, Oakley Green, Old Windsor, Spital, Windsor, Berkshire, Winkfield, Woodside",
    "SL5": "Ascot, Burleigh, Cheapside, North Ascot, South Ascot, Sunningdale, Sunninghill",
    "SL6": "Bray, Bray Wick, Burchett's Green, Cookham, Cookham Dean, Cookham Rise, Furze Platt, Hitcham, Holyport, Hurley, Hurley Bottom, Littlewick Green, Maidenhead, Paley Street, Pinkneys Green, Stud Green, Taplow, Touchen End, White Waltham, Woodlands Park",
    "SL7": "Bisham, Little Marlow, Lower Woodend, Buckinghamshire, Marlow, Marlow Bottom, Medmenham",
    "SL8": "Bourne End, Well End, Buckinghamshire",
    "SL9": "Chalfont Common, Chalfont St Peter, Gerrards Cross, Horn Hill",
    "SL60": "Non-geographic",
    "SL95": "Non-geographic",
    "SM1": "Sutton, Rose Hill, The Wrythe, Carshalton, Benhilton, Erskine Village",
    "SM2": "Belmont, South Sutton, East Ewell",
    "SM3": "North Cheam",
    "SM4": "Morden, Morden Park, Lower Morden, St. Helier",
    "SM5": "Carshalton, Carshalton Beeches, Carshalton on the Hill, The Wrythe, Carshalton Village, Eastern St. Helier, Middleton Circle",
    "SM6": "Wallington, Beddington, Hackbridge, Roundshaw, South Beddington",
    "SM7": "Banstead",
    "SN1": "Swindon Centre",
    "SN2": "Swindon",
    "SN3": "Swindon, Stratton",
    "SN4": "Wroughton, Chiseldon, Wootton Bassett",
    "SN5": "West Swindon",
    "SN6": "Cricklade, Ashton Keynes, Highworth, Shrivenham, Watchfield",
    "SN7": "Faringdon",
    "SN8": "Marlborough, Burbage",
    "SN9": "Pewsey",
    "SN10": "Devizes",
    "SN11": "Calne",
    "SN12": "Melksham",
    "SN13": "Corsham",
    "SN14": "Chippenham",
    "SN15": "Chippenham",
    "SN16": "Malmesbury",
    "SN17": "Non-geographic",
    "SN25": "Swindon",
    "SN26": "Blunsdon",
    "SN38": "Swindon",
    "SN42": "Non-geographic",
    "SN86": "Non-geographic",
    "SN99": "Swindon",
    "SO1": "Southampton city centre",
    "SO2": "Portswood, Bitterne",
    "SO3": "Bishop's Waltham, Botley, Warsash",
    "SO4": "Southampton, Cadnam, Lyndhurst, Borckenhurst, Lymington",
    "SO5": "Chandler's Ford, Eastleigh, Romsey",
    "SO9": "Southampton city centre",
    "SO14": "City Centre, St. Mary's, Newtown, Nicholstown, Ocean Village, Chapel, Eastern Docks, Bevois Valley",
    "SO15": "Shirley, Freemantle, Banister Park, Millbrook",
    "SO16": "Bassett, Redbridge, Rownhams, Nursling, Chilworth",
    "SO17": "Highfield, Portswood, St Denys, Swaythling",
    "SO18": "Bitterne, Bitterne Park, Chartwell Green, Townhill Park, Southampton Airport",
    "SO19": "Sholing, Thornhill",
    "SO20": "Stockbridge",
    "SO21": "Compton, Colden Common, Hursley, Micheldever, Owslebury, Shawford, South Wonston, Sutton Scotney, Sparsholt, Twyford",
    "SO22": "Badger Farm, Fulflood, Hursley, Littleton and Harestock, Olivers Battery, Pitt, St Cross (W), Stanmore, Weeke",
    "SO23": "City Centre, Abbotts Barton, Bar End, Highcliffe, Hyde, St Cross (E), Winnall",
    "SO24": "New Alresford, Old Alresford, Cheriton, Tichborne",
    "SO25": "Non-geographic",
    "SO30": "Botley, Hedge End, West End",
    "SO31": "Warsash, Hamble-le-Rice , Locks Heath",
    "SO32": "Bishops Waltham, Corhampton, Droxford, Durley, Exton, Meonstoke, Shedfield, Soberton, Swanmore, Upham, Warnford, Wickham",
    "SO40": "Totton, Lyndhurst, Cadnam, Marchwood",
    "SO41": "Milford-on-Sea, Pennington, Boldre, Hordle, Sway",
    "SO42": "Beaulieu",
    "SO43": "Lyndhurst",
    "SO45": "Hythe, Fawley, Blackfield, Calshot, Hardley",
    "SO50": "Eastleigh Town Centre",
    "SO51": "Romsey, Ampfield, Lockerley, Mottisfont, Wellow",
    "SO52": "North Baddesley",
    "SO53": "Chandler's Ford",
    "SO97": "Non-geographic",
    "SP1": "Salisbury",
    "SP2": "Salisbury",
    "SP3": "Salisbury",
    "SP4": "Salisbury",
    "SP5": "Salisbury",
    "SP6": "Fordingbridge",
    "SP7": "Shaftesbury",
    "SP8": "Gillingham",
    "SP9": "Tidworth",
    "SP10": "Andover",
    "SP11": "Ludgershall, Weyhill, Thruxton, Upper Clatford, Longparish, Enham Alamein, St Mary Bourne, Hurstbourne Tarrant",
    "SR1": "Sunderland City Centre, East End, Hendon",
    "SR2": "Ashbrooke, Ryhope, Grangetown, Hendon, Hillview, Thornhill",
    "SR3": "Chapelgarth, Doxford Park, Farringdon, Elstob Farm, Essen Way, Gilley Law, Hall Farm, Herrington, Humbledon Hill, Mill Hill, Plains Farm, Ryhope, Silksworth, Springwell, Thorney Close, Tunstall",
    "SR4": "Ayres Quay, Barnes, Chester Road, Deptford, Ford Estate, Grindon, Hastings Hill, Hylton Lane Estate, High Barnes, Millfield, Pallion, Pennywell, South Hylton",
    "SR5": "Carley Hill, Castletown, Downhill, Fulwell, Hylton Castle, Hylton Red House, Marley Pots, Monkwearmouth, Sheepfolds, Southwick, Town End Farm, Witherwack",
    "SR6": "Cleadon, Fulwell, Monkwearmouth, North Haven, Roker, St Peter's Riverside, Seaburn, Seaburn Dene, South Bents, Whitburn",
    "SR7": "Cold Hesledon, Dalton-le-Dale, Dawdon, Deneside, Greenhill, Murton, Northlea, Parkside, Seaham, West Lea",
    "SR8": "Easington, Easington Colliery, Horden, Little Thorpe, Peterlee, Shotton, Shotton Colliery",
    "SR9": "Post office boxes",
    "SR43": "Sunderland",
    "SR88": "Peterlee",
    "SS0": "Southend-on-Sea, Westcliff-on-Sea",
    "SS1": "Southend-on-Sea, Thorpe Bay",
    "SS2": "Prittlewell, Southend-on-Sea",
    "SS3": "Shoeburyness",
    "SS4": "Rochford",
    "SS5": "Hockley",
    "SS6": "Rayleigh",
    "SS7": "Hadleigh, South Benfleet, Thundersley",
    "SS8": "Canvey Island",
    "SS9": "Eastwood, Leigh-on-Sea",
    "SS11": "Wickford",
    "SS12": "Wickford",
    "SS13": "Basildon",
    "SS14": "Basildon",
    "SS15": "Laindon",
    "SS16": "Langdon Hills",
    "SS17": "Corringham, Stanford-le-Hope",
    "SS22": "Non-geographic",
    "SS99": "Non-geographic",
    "ST1": "Hanley, Cobridge, Sneyd Green",
    "ST2": "Bentilee, Abbey Hulton, Bucknall",
    "ST3": "Longton, Meir, Blurton, Weston Coyney",
    "ST4": "Stoke, Fenton",
    "ST5": "Newcastle-under-Lyme",
    "ST6": "Tunstall, Burslem, Smallthorne, Brown Edge",
    "ST7": "Kidsgrove, Talke, Talke Pits, Alsager, Mow Cop",
    "ST8": "Biddulph",
    "ST9": "Werrington, Endon",
    "ST10": "Cheadle, Church Leigh, Tean",
    "ST11": "Cheddleton, Blythe Bridge",
    "ST12": "Barlaston",
    "ST13": "Leek",
    "ST14": "Uttoxeter, Stramshall",
    "ST15": "Stone",
    "ST16": "Stafford",
    "ST17": "Stafford",
    "ST18": "Stafford",
    "ST19": "Penkridge",
    "ST20": "Stafford, Woodseaves",
    "ST21": "Stafford, Eccleshall, Rodbaston",
    "ST55": "Non-geographic",
    "SW1A": "Whitehall, Buckingham Palace",
    "SW1E": "Buckingham Gate, Victoria Station",
    "SW1H": "Buckingham Gate, Victoria St, Birdcage Walk",
    "SW1P": "Victoria Station, the Houses of Parliament, Vauxhall Bridge, Pimlico",
    "SW1V": "Vauxhall Bridge, Chelsea Bridge, Victoria Station, Pimlico",
    "SW1W": "Belgravia",
    "SW1X": "Belgravia, Knightsbridge",
    "SW1Y": "St. James's",
    "SW2": "Brixton, Brixton Hill, Streatham Hill, Tulse Hill, Clapham Park, Balham",
    "SW3": "Chelsea, Brompton, Knightsbridge",
    "SW4": "Clapham, Stockwell",
    "SW5": "Earl's Court",
    "SW6": "Fulham, Parson's Green",
    "SW7": "South Kensington, Knightsbridge",
    "SW8": "South Lambeth, Vauxhall, Battersea, Clapham, Stockwell",
    "SW9": "Stockwell, Brixton, Clapham",
    "SW10": "West Brompton, Chelsea",
    "SW11": "Battersea, Clapham Junction",
    "SW12": "Balham, Clapham South, Hyde Farm",
    "SW13": "Barnes, Castelnau",
    "SW14": "Mortlake, East Sheen",
    "SW15": "Putney, Roehampton University",
    "SW16": "Streatham, Norbury, Thornton Heath, Streatham Park, Furzedown, Streatham Vale, Pollards Hill",
    "SW17": "Tooting, Mitcham",
    "SW18": "Wandsworth, Southfields, Earlsfield",
    "SW19": "Wimbledon, Colliers Wood, Southfields",
    "SW20": "Merton Park, Raynes Park",
    "SW95": "Non-geographic",
    "SW99": "Non-geographic",
    "SY1": "Shrewsbury Town Centre, North Shrewsbury",
    "SY2": "East Shrewsbury",
    "SY3": "South Shrewsbury, West Shrewsbury, Bayston Hill",
    "SY4": "Shawbury, Wem, Atcham, Ruyton-XI-Towns",
    "SY5": "Dorrington, Donnington, Pontesbury, Westbury",
    "SY6": "Church Stretton, Cardington",
    "SY7": "Clun Valley",
    "SY8": "Ludlow",
    "SY9": "Bishops Castle",
    "SY10": "Oswestry",
    "SY11": "Oswestry",
    "SY12": "Ellesmere",
    "SY13": "Whitchurch",
    "SY14": "Malpas",
    "SY15": "Montgomery",
    "SY16": "Newtown",
    "SY17": "Caersws, Llandinam",
    "SY18": "Llanidloes",
    "SY19": "Llanbrynmair",
    "SY20": "Machynlleth",
    "SY21": "Welshpool",
    "SY22": "Llanfechain, Llanfyllin, Llansantffraid, Llanymynech, Meifod",
    "SY23": "Aberystwyth, Llanon, Llanrhystud",
    "SY24": "Bow Street, Pen-y-garn, Rhydypennau, Llandre",
    "SY25": "Tregaron, Ystrad Meurig",
    "SY99": "Non-geographic",
    "TA1": "Taunton",
    "TA2": "Taunton",
    "TA3": "North Curry",
    "TA4": "Bicknoller, Bishops Lydeard, Crowcombe, West Bagborough, Williton",
    "TA5": "Cannington, Nether Stowey, Over Stowey, Spaxton, Fiddington",
    "TA6": "Bridgwater, North Petherton, Wembdon",
    "TA7": "Puriton, Polden Hills, Westonzoyland, Middlezoy, Shapwick, Catcott, Ashcott, Chedzoy",
    "TA8": "Burnham on Sea, Berrow, Brean",
    "TA9": "Highbridge, West Huntspill, Brent Knoll",
    "TA10": "Langport",
    "TA11": "Somerton",
    "TA12": "Martock",
    "TA13": "South Petherton",
    "TA14": "Stoke-sub-hamdon",
    "TA15": "Montacute",
    "TA16": "Merriott",
    "TA17": "Hinton Saint George",
    "TA18": "Crewkerne",
    "TA19": "Ilminster",
    "TA20": "Chard",
    "TA21": "Wellington",
    "TA22": "Dulverton",
    "TA23": "Watchet",
    "TA24": "Minehead",
    "TD1": "Galashiels, Blainslie, Clovenfords, Fountainhall, Stow, Tweedbank",
    "TD2": "Lauder, Oxton",
    "TD3": "Gordon",
    "TD4": "Earlston",
    "TD5": "Kelso",
    "TD6": "Melrose",
    "TD7": "Selkirk",
    "TD8": "Jedburgh, Ancrum, Camptown, Crailing, Jed Valley, Nisbet, Oxnam",
    "TD9": "Hawick, Newcastleton, Bedrule, Bonchester Bridge, Chesters, Denholm, Kershopefoot, Newmill-on-Teviot, Roberton, Wilton Dean",
    "TD10": "Greenlaw",
    "TD11": "Duns",
    "TD12": "Cornhill-on-Tweed, Coldstream, Mindrum",
    "TD13": "Cockburnspath",
    "TD14": "Eyemouth",
    "TD15": "Berwick-upon-Tweed",
    "TF1": "Wellington",
    "TF2": "Priorslee",
    "TF3": "Randlay, Hollinswood",
    "TF4": "Dawley",
    "TF5": "Telford",
    "TF6": "Wrockwardine, Telford",
    "TF7": "Madeley, Sutton Hill",
    "TF8": "Telford",
    "TF9": "Market Drayton",
    "TF10": "Newport",
    "TF11": "Shifnal",
    "TF12": "Broseley",
    "TF13": "Much Wenlock",
    "TN1": "Royal Tunbridge Wells",
    "TN2": "Royal Tunbridge Wells, Pembury",
    "TN3": "Langton Green",
    "TN4": "Rusthall",
    "TN5": "Wadhurst, Ticehurst, Tidebrook, Stonegate",
    "TN6": "Black Hill, Boars Head, Burnt Oak, Castle Hill, Friars Gate, Mark Cross, Rotherfield, St Johns, Stone Cross, Town Row",
    "TN7": "Hartfield",
    "TN8": "Edenbridge",
    "TN9": "Tonbridge",
    "TN10": "Tonbridge",
    "TN11": "Penshurst",
    "TN12": "Paddock Wood, Staplehurst",
    "TN13": "Riverhead",
    "TN14": "Cudham",
    "TN15": "Ightham, Wrotham",
    "TN16": "Biggin Hill, Tatsfield",
    "TN17": "Cranbrook, Goudhurst, Benenden, Frittenden",
    "TN18": "Hawkhurst, Sandhurst",
    "TN19": "Etchingham",
    "TN20": "Mayfield",
    "TN21": "Heathfield",
    "TN22": "Uckfield",
    "TN23": "Ashford",
    "TN24": "Ashford",
    "TN25": "Challock, Wye, Stowting",
    "TN26": "Bethersden, Woodchurch, Shadoxhurst",
    "TN27": "Headcorn, Biddenden",
    "TN28": "New Romney, Greatstone-on-Sea, Littlestone-on-Sea",
    "TN29": "Lydd",
    "TN30": "Tenterden",
    "TN31": "Rye",
    "TN32": "Robertsbridge",
    "TN33": "Battle",
    "TN34": "Hastings town centre",
    "TN35": "Hastings, Pett, Guestling, Westfield",
    "TN36": "Winchelsea, Icklesham",
    "TN37": "St Leonards",
    "TN38": "St Leonards, Silverhill, Wishing Tree, North Hastings, Bulverhythe",
    "TN39": "Bexhill-on-Sea, Cooden",
    "TN40": "Bexhill-on-Sea",
    "TQ1": "Torquay (centre), St Marychurch",
    "TQ2": "Torquay (west, north)",
    "TQ3": "Paignton (north), Preston",
    "TQ4": "Paignton (centre), Goodrington",
    "TQ5": "Brixham",
    "TQ6": "Dartmouth",
    "TQ7": "Kingsbridge",
    "TQ8": "Salcombe",
    "TQ9": "Totnes",
    "TQ10": "South Brent",
    "TQ11": "Buckfastleigh",
    "TQ12": "Newton Abbot, Kingsteignton",
    "TQ13": "Ashburton, Bovey Tracey, Chudleigh, Moretonhampstead, Widecombe in the Moor",
    "TQ14": "Teignmouth",
    "TR1": "Truro",
    "TR2": "Truro, Gerrans",
    "TR3": "Truro",
    "TR4": "Goonhavern, Trispen",
    "TR5": "St Agnes, Mithian",
    "TR6": "Perranporth, Bolingey, Perrancoombe",
    "TR7": "Newquay",
    "TR8": "Mitchell",
    "TR9": "Saint Columb",
    "TR10": "Penryn",
    "TR11": "Falmouth, Flushing, Mylor Bridge, Constantine, Mawnan Smith",
    "TR12": "Helston",
    "TR13": "Helston",
    "TR14": "Camborne",
    "TR15": "Redruth",
    "TR16": "Lanner, Carharrack, St Day",
    "TR17": "Marazion",
    "TR18": "Penzance",
    "TR19": "St Buryan",
    "TR20": "Penzance",
    "TR21": "St Mary's",
    "TR22": "St Agnes",
    "TR23": "Bryher",
    "TR24": "Tresco",
    "TR25": "St Martin's",
    "TR26": "Saint Ives",
    "TR27": "Hayle",
    "TR93": "Penzance",
    "TS1": "Middlesborough Town Centre, Gresham, University, Abingdon",
    "TS2": "Middlehaven, Port Clarence",
    "TS3": "Brambles Farm, Thorntree, Park End, North Ormesby, Berwick Hills",
    "TS4": "Grove Hill, Longlands",
    "TS5": "Acklam, Linthorpe",
    "TS6": "Eston, Grangetown, Normanby, Teesville, South Bank",
    "TS7": "Marton, Nunthorpe, Ormesby",
    "TS8": "Coulby Newham, Marton, Hemlington, Stainton, Thornton, Maltby",
    "TS9": "Great Ayton, Stokesley",
    "TS10": "Redcar",
    "TS11": "Marske-by-the-Sea, New Marske",
    "TS12": "Skelton-in-Cleveland",
    "TS13": "Saltburn-by-the-Sea",
    "TS14": "Guisborough",
    "TS15": "Yarm, Kirklevington",
    "TS16": "Eaglescliffe, Egglescliffe, Aislaby",
    "TS17": "Thornaby, Ingleby Barwick, Stainton",
    "TS18": "Stockton-on-Tees, Hartburn, Preston-on-Tees, Grangefield, Oxbridge, Portrack",
    "TS19": "Newtown, Fairfield, Hardwick, Roseworth, Bishopsgarth, Elm Tree Farm",
    "TS20": "Norton, Mount Pleasant",
    "TS21": "Stillington, Bishopton, Redmarshall, Thorpe Thewles, Carlton, Sedgefield, Long Newton",
    "TS22": "Billingham (West), Wolviston",
    "TS23": "Billingham (East)",
    "TS24": "Hartlepool",
    "TS25": "Seaton Carew, Fens Estate, Owton Manor",
    "TS26": "Hartlepool",
    "TS27": "Blackhall Rocks, Blackhall Colliery, Castle Eden, Hesleden, High Hesleden, Hutton Henry",
    "TS28": "Wingate, Station Town",
    "TS29": "Trimdon Station",
    "TS90": "Non-geographic",
    "TW1": "Twickenham, St. Margarets, Strawberry Hill",
    "TW2": "Whitton, Strawberry Hill",
    "TW3": "Hounslow, Maswell Park, Lampton",
    "TW4": "Hounslow West, Hounslow Heath, Whitton",
    "TW5": "Heston",
    "TW6": "Heathrow",
    "TW7": "Isleworth, Osterley",
    "TW8": "Brentford",
    "TW9": "North Sheen, Kew",
    "TW10": "Ham, Petersham",
    "TW11": "Teddington",
    "TW12": "Hampton",
    "TW13": "Feltham, Hanworth",
    "TW14": "Hatton",
    "TW15": "Ashford",
    "TW16": "Sunbury-on-thames",
    "TW17": "Shepperton",
    "TW18": "Staines, Egham Hythe",
    "TW19": "Stanwell, Wraysbury",
    "TW20": "Egham, Englefield Green",
    "UB1": "Southall",
    "UB2": "Southall",
    "UB3": "Hayes, Harlington",
    "UB4": "Yeading, Hayes",
    "UB5": "Northolt",
    "UB6": "Greenford, Perivale",
    "UB7": "West Drayton, Harmondsworth, Sipson, Yiewsley, Longford",
    "UB8": "Uxbridge, Cowley, Hillingdon",
    "UB9": "Denham, Harefield",
    "UB10": "Hillingdon, Ickenham",
    "UB11": "Stockley Park",
    "UB18": "Non-geographic",
    "W1A": "Non-geographic",
    "W1B": "Portland Street, Regent Street",
    "W1C": "Oxford Street (west)",
    "W1D": "Soho (south east), Chinatown, Soho Square",
    "W1E": "Non-geographic",
    "W1F": "Soho (north west)",
    "W1G": "Harley Street",
    "W1H": "Marylebone",
    "W1J": "Mayfair (south), Piccadilly, Royal Academy",
    "W1K": "Mayfair (north), Grosvenor Square",
    "W1M": "Marylebone",
    "W1N": "Regents Park, Oxford Circus",
    "W1P": "Goodge Street, Warren Street",
    "W1R": "Oxford Street, Regent Street",
    "W1S": "Hanover Square, Savile Row",
    "W1T": "Fitzrovia, Tottenham Court Road",
    "W1U": "Marylebone",
    "W1V": "Soho, Piccadilly",
    "W1W": "Great Portland Street, Fitzrovia",
    "W1X": "Mayfair",
    "W1Y": "Mayfair",
    "W2": "Paddington, Bayswater, Hyde Park, Westbourne Green, Little Venice, Westbourne Green, Notting Hill",
    "W3": "Acton, East Acton, Park Royal, West Acton",
    "W4": "Chiswick, Gunnersbury, Turnham Green, Bedford Park",
    "W5": "Ealing, Park Royal",
    "W6": "Hammersmith, Ravenscourt Park",
    "W7": "Hanwell, Boston Manor",
    "W8": "Kensington, Holland Park",
    "W9": "Maida Vale, Little Venice",
    "W10": "North Kensington, Queens Park",
    "W11": "Notting Hill, Holland Park",
    "W12": "Shepherds Bush, White City",
    "W13": "West Ealing, Northfields",
    "W14": "West Kensington, Kensington Olympia, Holland Park",
    "WA1": "Warrington, Town Centre, Woolston, Paddington, Bruche",
    "WA2": "Warrington, Orford, Longford, Dallam, Padgate, Fearnhead",
    "WA3": "Lowton, Golborne, Birchwood, Rixton, Glazebrook, Culcheth",
    "WA4": "Warrington, Latchford, Stockton Heath, Appleton, Grappenhall, Daresbury, Moore, Walton",
    "WA5": "Warrington, Burtonwood, Westbrook, Penketh, Great Sankey, White Cross.",
    "WA6": "Frodsham, Helsby",
    "WA7": "Runcorn",
    "WA8": "Widnes, Cronton",
    "WA9": "Clock Face, Sutton, Thatto Heath",
    "WA10": "Eccleston, St. Helens, West Park",
    "WA11": "Crank, Haydock, Moss Bank, Rainford",
    "WA12": "Newton-le-Willows, Earlestown",
    "WA13": "Lymm, Statham, Warburton",
    "WA14": "Altrincham, Bowdon, Broadheath, Dunham Town, Timperley, Dunham Massey, Little Bollington",
    "WA15": "Altrincham, Ashley, Hale, Hale Barns, Timperley, Ringway",
    "WA16": "Knutsford",
    "WA55": "Non-geographic",
    "WA88": "Non-geographic, Widnes",
    "WC1A": "New Oxford Street",
    "WC1B": "Bloomsbury, British Museum",
    "WC1E": "University College London",
    "WC1H": "St Pancras",
    "WC1N": "Great Ormond Street Hospital",
    "WC1R": "Gray's Inn",
    "WC1V": "High Holborn",
    "WC1X": "Kings Cross, Finsbury (west)",
    "WC2A": "Lincoln's Inn Fields, Royal Courts of Justice",
    "WC2B": "Drury Lane, Aldwych",
    "WC2E": "Covent Garden",
    "WC2H": "Leicester Square",
    "WC2N": "Charing Cross",
    "WC2R": "Somerset House",
    "WC99": "Covent Garden",
    "WD1": "Watford",
    "WD2": "Watford, Leavesden, Bushey",
    "WD3": "Batchworth Heath, Chandlers Cross, Chenies, Chorleywood, Croxley Green, Heronsgate, Loudwater, Maple Cross, Mill End, Rickmansworth, Sarratt, West Hyde",
    "WD4": "Bucks Hill, Chipperfield, Hunton Bridge, Kings Langley",
    "WD5": "Bedmond, Abbots Langley",
    "WD6": "Borehamwood, Elstree, Well End",
    "WD7": "Shenley, Radlett",
    "WD17": "Watford, Cassiobury, Nascot Wood",
    "WD18": "West Watford",
    "WD19": "Oxhey, South Oxhey, Carpenders Park",
    "WD23": "Bushey, Bushey Heath",
    "WD24": "North Watford",
    "WD25": "Garston, Aldenham, Leavesden, Letchmore Heath",
    "WD99": "Non-geographic",
    "WF1": "Alverthorpe, Eastmoor, Kirkthorpe, Outwood, Wakefield City Centre",
    "WF2": "Kirkhamgate, Kettlethorpe, Lupset, Newmillerdam, Sandal, Thornes, Walton (Wakefield)",
    "WF3": "East Ardsley, Lofthouse, Lofthouse Gate, Robin Hood, Stanley, Tingley, West Ardsley",
    "WF4": "Crigglestone, Crofton, Durkar, Flockton, Havercroft, Horbury, Netherton, New Crofton, Ryhill, West Bretton, Woolley",
    "WF5": "Gawthorpe, Ossett",
    "WF6": "Altofts, Normanton",
    "WF7": "Ackworth Moor Top, Featherstone, Purston Jaglin, Streethouse",
    "WF8": "Darrington, Kirk Smeaton, Pontefract (Monkhill), Thorpe Audlin",
    "WF9": "Badsworth, Fitzwilliam, Hemsworth, Kinsley, South Elmsall, South Kirkby, Upton",
    "WF10": "Airedale, Allerton Bywater, Castleford, Glasshoughton",
    "WF11": "Brotherton, Byram, Criddling Stubbs, Kellingley, Knottingley",
    "WF12": "Chickenley, Dewsbury, Savile Town, Shaw Cross, Thornhill, Thornhill Lees",
    "WF13": "Dewsbury Moor, Ravensthorpe, Staincliffe",
    "WF14": "Hopton, Mirfield",
    "WF15": "Roberttown, Liversedge",
    "WF16": "Heckmondwike",
    "WF17": "Birstall, Batley, Staincliffe",
    "WF90": "Non-geographic",
    "WN1": "Haigh, Ince, Swinley, Wigan",
    "WN2": "Abram, Aspull, Bamfurlong, Bickershaw, Haigh, Hindley, Hindley Green, Ince, Platt Bridge",
    "WN3": "Goose Green, Ince, Winstanley, Worsley Mesnes",
    "WN4": "Ashton-in-Makerfield, Garswood",
    "WN5": "Billinge, Newtown, Higher End, Orrell, Pemberton, Winstanley, Worsley Hall, Marsh Green.",
    "WN6": "Appley Bridge, Shevington, Standish, Standish Lower Ground, Wrightington",
    "WN7": "Hope Carr, Landside, Leigh, Low Common, Bedford, Westleigh, Pennington, Higher Folds",
    "WN8": "Chapel House, Dalton, Holland Moor, Newburgh, Parbold, Roby Mill, Skelmersdale, Southway, Upholland",
    "WR1": "Worcester",
    "WR2": "Worcester",
    "WR3": "Worcester",
    "WR4": "Worcester",
    "WR5": "Worcester",
    "WR6": "Worcester",
    "WR7": "Worcester",
    "WR8": "Worcester",
    "WR9": "Droitwich",
    "WR10": "Pershore",
    "WR11": "Evesham",
    "WR12": "Broadway",
    "WR13": "Malvern",
    "WR14": "Malvern",
    "WR15": "Tenbury Wells",
    "WR78": "Worcester",
    "WR99": "Worcester",
    "WS1": "Walsall town centre, Caldmore",
    "WS2": "Pleck, Bentley, Leamore",
    "WS3": "Bloxwich, Coal Pool, Pelsall",
    "WS4": "Rushall",
    "WS5": "Bescot, Tamebridge, Yew Tree",
    "WS6": "Cheslyn Hay, Great Wyrley",
    "WS7": "Burntwood",
    "WS8": "Walsall",
    "WS9": "Aldridge, Brownhills",
    "WS10": "Wednesbury",
    "WS11": "Cannock",
    "WS12": "Heath Hayes, Hednesford, Huntington, Wimblebury",
    "WS13": "Lichfield",
    "WS14": "Lichfield",
    "WS15": "Armitage, Brereton, Rugeley",
    "WV1": "Wolverhampton City Centre, Horseley Fields, East Park",
    "WV2": "All Saints , Blakenhall, Parkfields",
    "WV3": "Finchfield, Compton, Castlecroft",
    "WV4": "Penn, Warstones, Merry Hill, Parkfields",
    "WV5": "Wombourne, Wall Heath",
    "WV6": "Whitmore Reans, Perton, Pattingham, Tettenhall",
    "WV7": "Albrighton",
    "WV8": "Codsall, Pendeford, Rakegate, Bilbrook",
    "WV9": "Pendeford",
    "WV10": "Low Hill, Bushbury, Heath Town, Fordhouses, Fallings Park, Featherstone, Wednesfield, Brinsford",
    "WV11": "Wednesfield",
    "WV12": "Short Heath, Lodge Farm",
    "WV13": "Shepwell Green, Willenhall Town",
    "WV14": "Bradley, Bilston Town",
    "WV15": "Bridgnorth Lower",
    "WV16": "Bridgnorth Upper",
    "WV98": "Non-geographic",
    "WV99": "Non-geographic",
    "YO1": "York City Centre",
    "YO2": "York",
    "YO3": "York",
    "YO4": "Pocklington, Market Weighton, Escrick, Stamford Bridge",
    "YO5": "Boroughbridge, Tockwith",
    "YO6": "Helmsley, Easingwold",
    "YO7": "Thirsk",
    "YO8": "Barlby, Brayton, Bubwith, Cawood, Camblesforth, Drax, Thorpe Willoughby",
    "YO10": "Fishergate, Fulford, Heslington, Osbaldwick, Tang Hall",
    "YO11": "Cayton",
    "YO12": "Seamer",
    "YO13": "Scarborough",
    "YO14": "Filey",
    "YO15": "Bridlington, Bempton, Buckton, Carnaby, Flamborough, Fraisthorpe, Sewerby, Wilsthorpe",
    "YO16": "Bridlington, Bempton, Bessingby, Boynton, Buckton, Carnaby, Easton, Grindale, Sewerby",
    "YO17": "Norton",
    "YO18": "Thornton-le-Dale",
    "YO19": "Dunnington, Escrick, Wheldrake, Murton, Riccall, Stillingfleet, Warthill",
    "YO21": "Whitby, Westerdale",
    "YO22": "Robin Hood's Bay",
    "YO23": "South Bank, Bishopthorpe, Copmanthorpe, Rufforth",
    "YO24": "Acomb, Dringhouses, Woodthorpe",
    "YO25": "Driffield",
    "YO26": "Acomb, Leeman Road Area, Upper Poppleton, Nether Poppleton, Green Hammerton",
    "YO30": "Bootham, Clifton, Skelton, Linton-on-Ouse",
    "YO31": "Heworth, Huntington (South), The Groves, Layerthorpe",
    "YO32": "Haxby, Huntington (North), Wigginton, New Earswick, Stockton-on-the-Forest, Strensall",
    "YO41": "Elvington, Full Sutton, Stamford Bridge, Sutton upon Derwent, Wilberfoss",
    "YO42": "Pocklington, Barmby Moor, Melbourne, Seaton Ross",
    "YO43": "Market Weighton, Holme-on-Spalding-Moor",
    "YO51": "Boroughbridge",
    "YO60": "Skewsby",
    "YO61": "Easingwold",
    "YO62": "Thorpe Hall, Helmsley, Kirkbymoorside",
    "YO90": "Rougier St, Wellington Row",
    "YO91": "Lawrence Court, PO Boxes",
    "YO95": "Pocklington",
    "ZE1": "Lerwick, Scalloway",
    "ZE2": "Shetland",
    "ZE3": "Bush"
}
