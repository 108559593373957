import React from "react"
import TpStars from "./TpStars"



const Comment = (props) => {
    return(
        <div className="comment">
            <TpStars className="stars" trustScore={props.trustScore}/>
            {props.children}
            <div className="comment-author">{props.author}</div>
        </div>
    )
}

Comment.Title = ({children}) => <div className="comment-title">“{children}”</div>

Comment.Description = ({children}) => <div className="comment-description">{children}</div>


export default Comment