import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

import TpStars from './TpStars'
import Comment from "./Comment"

const Trustpilot = ({className, ...props}) => {

    return(
        <section className={`trustpilot-section ${className}`}>
            <div className="rated">
                <TpStars className="stars" trustScore={5}/>
                <div className="rated-text">Rated <b>excellent</b> by Edyn customers on</div>
                <a href="https://www.trustpilot.com/review/edyn.care" target="_blank" rel="noopener"><StaticImage className="tp-icon" src="../../../static/assets/marketing-site/images/trustpilot-icon-dark.png" objectFit="contain"/></a>
            </div>
            <div className="comments-carrousel">
                <Comment trustScore={5} author="Kathy">
                    <Comment.Title>Amazing care & support for all the family</Comment.Title>
                    <Comment.Description>We just wanted to say a huge 'Thank you' to everyone at Edyn Care for the level of care that you provided for Mum, which enabled her to stay in her own home for much longer than we ever thought possible.</Comment.Description>
                </Comment>
                <Comment trustScore={5} author="Karen">
                    <Comment.Title>A big thank you to all the staff</Comment.Title>
                    <Comment.Description>
                        A big thank you to all the staff at edyncare who were able to arrange a suitable carer in a short period of time as required.
                        <br />
                        <br/>
                        Tamas was ultimately professional, calm, well organised ...... and an excellent chef !
                        <br />
                        <br />
                        We were very impressed with the way he was able to support not only our Mother but also my sister and myself in an understanding and supportive manner.
                    </Comment.Description>
                </Comment>
                <Comment 
                    trustScore={5}
                    author="Katy"
                >
                    <Comment.Title>Edyn stands out from other agencies</Comment.Title>
                    <Comment.Description>
                        What makes Edyn stand out from other agencies we have used for live-in care is the fact that they care - the human touch is there.
                        <br />
                        <br />
                        Our communications with them have always centred around a basic desire to do the best for my husband as an individual, not as a number.
                    </Comment.Description>
                </Comment>
            </div>

        </section>
    )
}



export default Trustpilot